import React from "react";
import { useSelector } from "react-redux";
import "./Customer.scss";
import MyPlans from "./MyPlans/MyPlans";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import InvoiceHistory from "./InvoiceHistory/InvoiceHistory";
import Users from "../Customer/Users/Users";
import api from "../../veracityapi";

const Customer = () => {
	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};
	const state = useSelector(state => state.toggle.toggle);
	const primaryUser = localStorage.getItem("primaryUser") === "true";	
	const [showAllPackages, setShowAllPackages] = React.useState(true); 
	const customerPackages = useSelector(state => state?.websiteDetails?.data?.customerPackages);
	const currentPackage = customerPackages?.find((val) => val.current === 1);


	if(showAllPackages === true && currentPackage?.custom === 1) {
		setShowAllPackages(false);
	};
	// need to change line 39
	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	return (
		<div>
			<div className={state ? "content-active" : "content"}>
				<div className="customer-main-content" >
					<div className="company-name">Company Information</div>
					{showAllPackages ? <MyPlans primaryUser={primaryUser} /> : <></>}
					<Users primaryUser={primaryUser} />
					{(primaryUser && showAllPackages) &&
						<>
							<PaymentMethods />
							<InvoiceHistory />
						</>}
				</div>
			</div>
		</div>
	);

};

export default Customer;