import moment from "moment";
import 'moment-timezone';

const getDateStringToTimeString = (dateStr) => {
	return moment.utc(dateStr, "Do MMM YY");
}
export const getStartTimeStampFromString = (timeRange) => {
	const timeZone = localStorage.getItem('timezone');
	const timeToday = moment().tz(timeZone).startOf('day').unix();
	const timeWeek = moment().tz(timeZone).subtract(7, "d").startOf("day").unix();
	const timeTwoWeek = moment().tz(timeZone).subtract(14, "d").startOf("day").unix();
	const timeFourWeek = moment().tz(timeZone).subtract(28, "d").startOf("day").unix();
	const thisMonth = moment().tz(timeZone).startOf("month").unix();
	const lastMonth = moment().tz(timeZone).subtract(1, "M").startOf("month").unix();
	const lastThirtyMinutes = moment().tz(timeZone).subtract(30, "minute").unix();

	switch (timeRange) {
		case 'Active Now':
			return lastThirtyMinutes;
		case 'Today':
			return timeToday
		case 'Prev 7 Days':
			return timeWeek;
		case 'Prev 14 Days':
			return timeTwoWeek;
		case 'Prev 28 Days':
			return timeFourWeek;
		case 'This Month':
			return thisMonth;
		case 'Last Month':
			return lastMonth;
		default:
			if (timeRange) return (getDateStringToTimeString(timeRange.split('-')[0]).utc(true).tz(timeZone).unix());
	};
};

export const getEndTimeStampFromString = (timeRange) => {
	const timeZone = localStorage.getItem('timezone');
	const endOfToday = moment().tz(timeZone).endOf('day').unix();
	const EndOfYesterday = moment().tz(timeZone).subtract(1, "d").endOf("day").unix();
	const thisMonth = moment().tz(timeZone).endOf("month").unix();
	const lastMonth = moment().tz(timeZone).subtract(1, "M").endOf("month").unix();
	const presentTime = moment().tz(timeZone).subtract(1, "minute").unix();

	switch (timeRange) {
		case 'Active Now':
			return presentTime;
		case 'Today':
			return endOfToday;
		case 'Prev 7 Days':
			return EndOfYesterday;
		case 'Prev 14 Days':
			return EndOfYesterday;
		case 'Prev 28 Days':
			return EndOfYesterday;
		case 'This Month':
			if (EndOfYesterday < thisMonth) {
				return EndOfYesterday;
			} else {
				return thisMonth;
			}
		case 'Last Month':
			return lastMonth;
		default:
			if (timeRange) return (getDateStringToTimeString(timeRange.split('-')[1])).tz(timeZone).endOf("day").unix();
	};
};


export const getComparisonTimeStampFromString = (timeRange) => {
	const timeZone = localStorage.getItem('timezone');

	const timeWeek = moment().tz(timeZone).subtract(14, "d").startOf("day").unix();
	const timeTwoWeek = moment().tz(timeZone).subtract(28, "d").startOf("day").unix();
	const timeFourWeek = moment().tz(timeZone).subtract(56, "d").startOf("day").unix();
	const thisMonth = moment().tz(timeZone).subtract(1, "M").startOf("month").unix();
	const lastMonth = moment().tz(timeZone).subtract(2, "M").startOf("month").unix();

	switch (timeRange) {
		case 'Prev 7 Days':
			return timeWeek;
		case 'Prev 14 Days':
			return timeTwoWeek;
		case 'Prev 28 Days':
			return timeFourWeek;
		case 'This Month':
			return thisMonth;
		case 'Last Month':
			return lastMonth;
		default:
			if (timeRange) return moment(getDateStringToTimeString(timeRange.split('-')[0])).tz(timeZone).subtract(7, "d").startOf("day").unix();;
	};
};


export const utcTimeStringToLocalTimeString = (utcTimeString, inFormat = "", outFormat = "YYYY-MM-DD HH:mm:ss") => {
	const timeZone = localStorage.getItem('timezone');
	return (moment.utc(utcTimeString, inFormat).tz(timeZone)?.format(outFormat));
};

export const nowMinusMinutes = (minutes = 0) => {
	const timeZone = localStorage.getItem('timezone');
	return (moment().tz(timeZone).startOf('minute').subtract(minutes, 'minutes').unix());
};