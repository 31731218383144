import React from "react";
import { useParams } from 'react-router-dom'
import "./Current.scss";
import CurrentTile from "./Components/CurrentTile";
import api from "../../../veracityapi";
const Current = () => {
	const props = useParams();

	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);
	return (
		<div className="analytics-container">
			<CurrentTile {...props}/>
		</div>
	);
};

export default Current;