import React from "react";
import { GraphsToolTipIcon } from "../../SVG/GraphsToolTIpIcon";
import './GraphsToolTip.scss';
 
const GraphsToolTip = (props) => {
    return (
        <>
            <div className="tooltip">
                <GraphsToolTipIcon/>
                <span className="tooltiptext">{props.message}</span>
            </div>
        </>
    )
};

export default GraphsToolTip;