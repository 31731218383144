import React from "react";
import DateDropDown from "../../../../SharedComponents/DateDropDown/DateDropDown";
import EvaluationPanel from "../../PerformancePanels/EvaluationPanel/EvaluationPanel";
import HumanActivity from "../../PerformancePanels/HumanActivityPanel/HumanActivity";
import Dragger from "../../../../SharedComponents/Dragger/Dragger";
import "./PerformanceTile.scss";
import HumanPanelData from "../../PerformancePanels/HumanActivityPanel/HumanActivityPanelController";
import EvaluationPanelController from "../../PerformancePanels/EvaluationPanel/EvaluationPanelController";
import { useSelector, useDispatch } from "react-redux";

const AnalyticsTile = (props) => {
	const [graph, setGraph] = React.useState(null);
	const dateDropDownValues = ['Prev 7 Days', 'Prev 14 Days', 'Prev 28 Days', 'This Month', 'Last Month'];
	const humanActivityData = useSelector(state => state?.humanActivity?.data);
	const dispatch = useDispatch();
	const parentElm = React.useRef(null);
	const hoverTarget = React.useRef(null);
	const [value, setValue] = React.useState({
		graph: null,
		timeRange: null
	});

	const graphSelect = () => {
		switch (props.graph) {
			case 'Human Activity':
				return <HumanActivity loading={props.loading} setLoading={props.setLoading} />
			case 'Known Bots':
				return <EvaluationPanel loading={props.loading} setLoading={props.setLoading} />
			default:
				return <></>
		};
	};

	React.useEffect(() => {
		if (value.graph !== "Human Activity") {
			setValue({
				graph: "Human Activity",
				timeRange: humanActivityData?.timeRange ?? 'Prev 7 Days'
			});
		} else {
			if (value.timeRange !== humanActivityData?.timeRange) {
				props.setLoading(true);
				Promise.all(
					[
						EvaluationPanelController({ timeRange: value?.timeRange }, dispatch),
						HumanPanelData({ timeRange: value?.timeRange }, dispatch)
					]
				).then(() => {
					props.setLoading(false);
				}).catch((e) => {
					props.setLoading(false);
				});
			}
		};
	}, [value]);

	React.useEffect(() => {
		setGraph(graphSelect());
	}, [value, props.loading]);

	return (
		<div
			className="graph-tile"
			style={{ width: '95%' }}
			ref={parentElm}
		>
			<div className="graph-tile-header">
				<h2>{props.graph}</h2>
				<div className="button-box">
					<div className="date-dropdown-container">
						{props.graph === "Human Activity" && <DateDropDown setValue={setValue} value={value} dateDropDownValues={dateDropDownValues} />}
					</div>
					{props.DisplayDashboard === true ? <Dragger parent={parentElm.current} /> : ""}
				</div>
			</div>
			<div className="graph-body">
				{graph}
			</div>
		</div>
	);
};

export default AnalyticsTile;