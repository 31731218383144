import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { useLocation } from "react-router";
import CustomTooltip from "SharedComponents/CustomTooltip/CustomTooltip";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import TrafficMessage from "SharedComponents/TrafficMessage/TrafficMessage";
import { MaxIcon } from "SVG/MaxIcon";
import GraphModal from "SharedComponents/GraphModal/GraphModal";
import moment from "moment";

const ReferralSources = (props) => {
	const graphRef = React.useRef(null);
	const { pathname } = useLocation();
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [maxIcon, setMaxIcon] = React.useState(false);
	const handleMaximizeClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const formatData = (data) => {
		const formattedData = [];
		data.forEach((val) => {
			let array = {
				data: [...val.data],
				id: val.id[0] !== "h" ? val.id : val.id[4] === "s" ? val.id.replace("https://", "").replace(/\/$/, "") : val.id.replace("http://", "").replace(/\/$/, " ")
			}
			formattedData.push(array)
		})


		return formattedData
	};
	let formattedData;
	if (props.data !== undefined) {
		 formattedData = formatData(Object.values(props.data));
	};


	const MyResponsiveLineGraph = React.useMemo(() => {
		let sum = 0;
		for (let keys in props.data) {
			for (let value in props.data[keys].data) {
				sum += props.data[keys].data[value].y
			}
		};
		if (props.data.length !== 0 && sum !== 0) {
			return (
				<ResponsiveLine
					data={formattedData}
					margin={{ top: 10, right: 40, bottom: 75, left: 40 }}
					xScale={{ type: 'point' }}
					yScale={{
						type: 'linear',
						min: 'auto',
						max: 'auto',
						stacked: false,
						reverse: false
					}}
					curve="monotoneX"
					colors={{ scheme: "nivo" }}
					yFormat=" >-.2f"
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 25,
						legendPosition: 'middle',
						legendOffset: 36,
						format: (value) => {
							Object.values(props.data)[0] = !Object.values(props.data);
							return (Object.values(props.data) && pathname !== "/today") ? moment(value).format("Do MMM") : value[3] === "0" ? value : ""
						}
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legendOffset: -40,
						legendPosition: 'middle',
						format: (value) => value % 2 === 0 ? value : ""
					}}
					pointSize={10}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor' }}
					pointLabelYOffset={-12}
					useMesh={true}
					enableSlices="x"
					sliceTooltip={(e) => <CustomTooltip data={e} graphRef={graphRef} />}
				/>
			)
		} else {
			return (
				<TrafficMessage message="There has been no traffic in the selected time period." />
			)
		};

	}, [props.data])

	return (
		<>
			<div className="graph-header" onMouseEnter={() => setMaxIcon(true)} onMouseLeave={() => setMaxIcon(false)}>
				<div className="graph-heading">Referral Sources</div>
				<GraphsToolTip message="This summary shows the referrers that are sending bots to your website. This also includes direct arrivals." />
				{maxIcon &&
					<div className="max-icon" onClick={handleMaximizeClick} title="Expand Graph">
						<MaxIcon />
					</div>
				}
			</div>
			<div style={{ height: '100%', width: '100%' }} ref={graphRef} onMouseEnter={() => setMaxIcon(true)} onMouseLeave={() => setMaxIcon(false)}>
				{MyResponsiveLineGraph}
			</div>
			{isModalOpen &&
				<GraphModal
					isOpen={isModalOpen}
					onClose={handleMaximizeClick}
					graph={MyResponsiveLineGraph}
					title="Referral Sources"
				/>
			}
		</>
	)
};

export default ReferralSources;