import React from "react";
import "./Help-ManageUsers.scss";

const HelpManageUsers = () => {
	return (
		<div className="managing-users-section">
			<h3>Browser Cache Issues</h3>
			<p>
				Veracity is constantly being improved. Occasionally these updates can result in your browser becoming confused about whether or not you are signed in. Learn <a href="https://go.veracitytrustnetwork.com/resource/browser-cache-issues/" target="_blank" rel="noreferrer">here</a> how to resolve this.
			</p>
		</div>
	);
};

export default HelpManageUsers;