
import React from "react";
import './CO2LineGraph.scss';
import { ResponsiveLine } from "@nivo/line";
// import { utcTimeStringToLocalTimeString } from "../../../../../SharedComponents/DateManager";
import Loader from "../../../../../SharedComponents/Loader/Loader";
import moment from "moment";


const CO2LineGraph = (props) => {
	const timezone= localStorage.getItem("timezone");

	function formatData(data) {
	
		let botsarray = [];
		for (let date in data) {
		
			let bots = (data[date].bot) * 1.5;
			let utcDate = moment(date);
			let unixTime=utcDate.tz(timezone).unix();
			botsarray.push({
				x: unixTime,
				y: bots
			});
		}

		return botsarray
	};

	const formattedData = formatData(props.finishedSessionsData.breakDownData);

	function toolTipContent({ point }) {
		const formattedDate = moment(point.data.x).format('Do MMM');
		const yValue = point.data.y + "g";
		const color = props.websiteStatus === "detected" ? "#e63946" : "#6EBA7A";

		return (
			<div className="toolTipContent">
				<b>{formattedDate}</b>
				<div>
					{props.websiteStatus === "detected" ? "CO2 Wasted: " : "CO2 Saved: "}
					<b className="botsValues" style={{ color }}>{yValue}</b>
				</div>
			</div>
		);
	}

	function getTickSize (){
		let values = formattedData?.length ?? 0;	
		if(values > 20){
			return 15
		} else {
			return values
		}
	}

	const SessionBreakdownGraph = React.useMemo(() => {
		let SessionBreakdownData = {
			data: [
				{ id:"CO2 Saved", data: formattedData },
			]
		}
		let colours = [
			props.websiteStatus === "detected" ? "#e63946" : "#6EBA7A"
		];
		return (
			<ResponsiveLine
				data={SessionBreakdownData.data}
				isInteractive={true}
				margin={{ top: 13, right: 38, bottom: 90, left: 35 }}
				xScale={{
					type: "time",
					precision: "day",
					format: "%s"
				}}
				// xScale={{type: "point"}}
				yScale={{
					type: 'linear',
					min: '0',
					max: 'auto',
					stacked: false,
					reverse: false
				}}

				curve="monotoneX"
				tooltip={toolTipContent}
				colors={colours}
				yFormat=" >-.2f"
				axisTop={null}
				axisRight={null}
				axisBottom={{
					orient: 'bottom',
					tickValues: getTickSize(),
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 45,
					legend: '',
					legendOffset: 36,
					legendPosition: 'middle',
					format: (value) => {
						return moment(value).format('Do MMM')
					}
				}}
				axisLeft={{
					orient: 'left',
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: '',
					legendOffset: -40,
					legendPosition: 'middle',
					format: (value) => value % 2 === 0 ? value : ""
				}}

				pointSize={10}
				pointColor={{ theme: 'background' }}
				pointBorderWidth={2}
				pointBorderColor={{ from: 'serieColor' }}
				pointLabelYOffset={-12}
				useMesh={true}
			/>
		);
	}, [formattedData]);

	return (
		<div className="linegraph-container CO2">
			{props.loading ? <Loader /> : SessionBreakdownGraph}
		</div>

	);
};

export default CO2LineGraph;
