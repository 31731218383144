import React from "react";


const TrafficMessage = (props) => {
	return (
		<div className="traffic-message">
			<h1 style={{ textAlign: "center", fontSize: "1vw"}}>{props.message}</h1>
		</div>
	);
};

export default TrafficMessage;