const SideBarArrowIcon = (props) => {
	return (
		<svg style={{position:"absolute"}} width="20" height="21"  viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="10" cy="10.5" r="9.5" fill={props.fill} stroke="#F4F4F4" />
			<path d="M11 14.5L7 10.5L11 6.50003" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	)
};

export default SideBarArrowIcon;
