import React from "react";
import "./ValueCard.scss";
import Loader from "../../../SharedComponents/Loader/Loader";
import NoContent from "../../../SharedComponents/NoContent/NoContent";

const ValueCard = ({ loading, image, title, subTitle, changeValue, className = '',children }) => {
    return (
        <div className={`value-card ${className}`}>
            {loading ? <Loader /> : <>
                {title ? <>
                    <div className="flex-column">
                        <div className="title-value">{title}</div>
                        {subTitle ? <div className="sub-title">{subTitle}</div> : ''}
                        {changeValue ? <div className="change-denoter-label">{changeValue}</div> : ''}
                    </div>
                    {image ? <img src={image} className="image" alt="status"></img> : ''}
                    {children}
                </>
                    : <NoContent/>}
            </>}
        </div>
    )
}
export default ValueCard;
