import React from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../../veracityapi";
import { websiteData } from "../../../../Redux/actions";
import "./ConfirmRemove.scss";


const ConfirmRemove = (props) => {
	const dispatch = useDispatch();
	const customerGUID = localStorage.getItem("selected_customer");
	const fullWebsiteDetails = useSelector(state => state.websiteDetails.data);
	const safeFullWebsiteDetails = JSON.parse(JSON.stringify(fullWebsiteDetails));
	const [modalState, setModalState] = React.useState("confirm");
	const handleCloseModal = () => {
		props.setRemoveUserModal(false);
		// document.body.style.background = "#f4f4f4";
	};
	const isInvite = typeof props.user.primary_user === "undefined";

	const handleRemoveUser = () => {
		setModalState("waiting");
		if (!isInvite) {
			api.DELETE(`/customer/${customerGUID}/user/${props.user.guid}`)
				.then(() => {
					safeFullWebsiteDetails.users.items = safeFullWebsiteDetails.users.items.filter((userObj) => userObj.guid !== props.user.guid);
					dispatch(websiteData({ ...safeFullWebsiteDetails }));
					setModalState('success');
				})
				.catch(() => {
					setModalState('failed');
				});
		} else {
			api.DELETE(`/invite/${props.user.token}`)
				.then(() => {
					safeFullWebsiteDetails.invites.item = safeFullWebsiteDetails.invites.item.filter((inviteObj) => inviteObj.token !== props.user.token);
					dispatch(websiteData({ ...safeFullWebsiteDetails }));
					setModalState('success');
				})
				.catch(() => {
					setModalState('failed');
				});
		};
	};

	return (
		<div className="remove-user-modal">
			{(modalState === "confirm") &&
				<div className="remove-user-modal-content">
					<div className="remove-close-modal" onClick={handleCloseModal}><h3>X</h3></div>
					<div className="modal-header">
						<h3 className="modal-name">{isInvite ? "Cancel Invite": "Remove User"}</h3>
					</div>
					<span>
						{!isInvite ? `This will remove the user ${props.user.first_name} ${props.user.last_name ?? ""} from your account.` : `This will cancel the invite for ${props.user.email}.`}
					</span>
					<button className="remove-user-confirm-button" onClick={handleRemoveUser}>{isInvite ? "Confirm Cancelation" : "Confirm Removal"}</button>
				</div>
			}
			{(modalState === "waiting") &&
				<div className="remove-user-modal-content">
					<div className="remove-close-modal" onClick={handleCloseModal}><h3>X</h3></div>
					<div className="modal-header">
						<h3 className="modal-name">{isInvite ? "Cancel Invite": "Remove User"}</h3>
					</div>
					<span>
						Processing please wait
					</span>
					<button style={{ visibility: 'hidden' }} className="remove-user-confirm-button">Confirm</button>
				</div>
			}
			{(modalState === "success") &&
				<div className="remove-user-modal-content">
					<div className="remove-close-modal" onClick={handleCloseModal}><h3>X</h3></div>
					<div className="modal-header">
					<h3 className="modal-name">{isInvite ? "Cancel Invite": "Remove User"}</h3>
					</div>
					<span>
						{!isInvite ? `The user ${props.user.first_name} ${props.user.last_name ?? ""} has been removed from your account.` : `The invite for ${props.user.email} has been canceled.`}
					</span>
					<button className="remove-user-close-button" onClick={handleCloseModal} >Close</button>
				</div>
			}
			{(modalState === "failed") &&
				<div className="remove-user-modal-content">
					<div className="remove-close-modal" onClick={handleCloseModal}><h3>X</h3></div>
					<div className="modal-header">
					<h3 className="modal-name">{isInvite ? "Cancel Invite": "Remove User"}</h3>
					</div>
					<span>
						Action failed, please try again or contact support.
					</span>
					<button className="remove-user-close-button" onClick={handleCloseModal}>Close</button>
				</div>
			}
		</div>
	);
};

export default ConfirmRemove;