import React, { useMemo } from "react";
import { ResponsiveLine } from '@nivo/line';
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import moment from "moment";
import Loader from "SharedComponents/Loader/Loader";
import { riskLevels, colors } from "components/AFP/Constants";
import { useSelector } from "react-redux";

const RiskMeasurement = ({ websiteRiskData, loading, tickValues, title, rangeName }) => {

    const styles = {
        backgroundColor: '#ffffff',
        padding: '6px 9px',
        borderRadius: '2px',
        minWidth: '160px',
        boxShadow: '0 3px 5px rgba(0, 0, 0, .25)',
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        gap: "8px",
    };


    const formatData = () => {
        try {
            const result = [];
            for (let dates in websiteRiskData.item) {
                result.push(
                    {
                        x: dates,
                        y: websiteRiskData.item[dates]?.websiteRiskLevel ?? 0,
                        riskLevel: riskLevels[websiteRiskData.item[dates]?.websiteRiskLevel] ?? "Low",
                        color: colors[websiteRiskData.item[dates]?.websiteRiskLevel] ?? "grey"
                    }
                )
            };
            if (result.length > 8) result.pop()
            const formattedData = [{ id: "Risk Level", data: result }];
            return formattedData
        } catch (error) {
            console.log("ERROR", error)
        }
    };





    const getRiskLevelName = (level) => riskLevels[level] || '';

    const ToolTip = ({ data }) => {

        return (
            <div style={styles}>
                <div style={{ fontWeight: "bolder" }} > {moment(data.slice.points[0].data.xFormatted).format("Do MMM")}</div>
                <div>Risk Level: <span style={{ color: data.slice.points[0].data.color, fontWeight: "bold" }}>{data.slice.points[0].data.riskLevel}</span></div>
            </div>
        )
    };

    const formattedData = formatData();

    const RiskMeasurementLinegraph = useMemo(() => {
        return (
            <ResponsiveLine
                data={formattedData}
                margin={{ top: 25, right: 40, bottom: 120, left: 65 }}
                xScale={{
                    format: '%Y-%m-%d',
                    precision: 'hour',
                    type: 'time',
                    useUTC: false
                }}
                yScale={{
                    type: 'linear',
                    min: '0',
                    max: '4',
                    stacked: true,
                    reverse: false
                }}
                curve="monotoneX"
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    legendOffset: -12,
                    tickSize: tickValues,
                    tickValues: "every 1 day",
                    format: (value) => {
                        return moment(value).format('Do MMM')
                    },
                    tickRotation: "45"
                }}
                enableSlices="x"
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    truncateTickAt: 0,
                    format: value => getRiskLevelName(value)
                }}
                sliceTooltip={(e) => <ToolTip data={e} />}
                colors={({ id, data }) => colors[data[data.length - 1].y]}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="data.yFormatted"
                pointLabelYOffset={-12}
                enableTouchCrosshair={true}
                useMesh={true}
            />
        )
    }, [websiteRiskData, formattedData]);

    const getRiskStatusValues = () => {
        let riskIncrease;
        const riskStatus = formattedData[0]?.data[formattedData[0]?.data.length - 1]?.riskLevel;
        const backgroundColor = formattedData[0]?.data[formattedData[0]?.data.length - 1]?.color;

        if (formattedData[0]?.data[0]?.y == formattedData[0]?.data[formattedData[0]?.data.length - 1]?.y) {
            riskIncrease = 0
        } else if (formattedData[0]?.data[0]?.y < formattedData[0]?.data[formattedData[0]?.data.length - 1]?.y) {
            riskIncrease = true
        } else {
            riskIncrease = false
        }

        return { riskStatus, backgroundColor, riskIncrease }
    };
    const riskStatusValues = getRiskStatusValues()

    const websiteStatus = useSelector((state) => state.websiteDetails?.data?.websiteDetails?.status);

    return (
        <div className="risk-measurement-container">
            <div className="graph-header" >
                <div className="graph-heading">{title}</div>
                <GraphsToolTip message={websiteStatus === "protect" ? "Veracity's Threat Measurement gives you an overall view of the threat level Veracity is protecting you from." : "Veracity's Threat Measurement gives you an overall view of the current threat to your site caused by malicious bots."} />
            </div>
            {
                loading ? <Loader /> :
                    <>
                        <div className="risk-status">
                            <span style={{ background: riskStatusValues.backgroundColor }}>
                                {riskStatusValues?.riskStatus}
                            </span>
                            <span style={{ color: riskStatusValues.backgroundColor }}>
                                {riskStatusValues?.riskIncrease === 0 ? "Threat has not changed" : riskStatusValues?.riskIncrease ? "↑ Increased" : "↓ Decreased"} {rangeName ?? "in the last 7 days"}
                            </span>
                        </div>
                        <div style={{ height: '100%', width: '100%' }} >
                            {RiskMeasurementLinegraph}
                        </div>
                    </>
            }

        </div>
    )
};


export default RiskMeasurement;