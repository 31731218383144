import axios from 'axios';
import { store } from './Redux/store';

function VeracityAPI(options) {

	let authToken = localStorage.getItem("authToken") ?? "";
	let refreshToken = localStorage.getItem("refreshToken") ?? "";

	let isRefreshing = false;
	let lastRefresh = null;
	let errorState = false;
	let trycount = 0;
	let schema = "https";
	let useHostname = "api.platform.veracitytrustnetwork.com";
	if (document.location.hostname === "qa.platform.veracitytrustnetwork.com") {
		useHostname = "qa-api.platform.veracitytrustnetwork.com";
	};

	if (document.location.hostname === "localhost") {
		//useHostname = "localhost:3001";
		schema = "https";
		useHostname = "dev-api.platform.veracitytrustnetwork.com";
	};

	const config = Object.assign({
		schema,
		hostname: useHostname,
		apikey: "SERVERADMINKEY",
		applicationKey: "5Fj43Y9Up8fKZzNDuuSG59c48PEACY4u"
	}, options);

	const instance = axios.create({
		baseURL: `${config.schema}://${config.hostname}`,
		timeout: 60000,
		headers: {
			"VeracityID": config.apikey
		},
		validateStatus: (status) => (status === 200),
	});

	const errorStates = [403, 500, 502, 504];
	const excludedFromRetry = [
		"/user/login"
	];


	this.tryRefreshToken = () => {
		if (!isRefreshing) {
			isRefreshing = true;
			return new Promise((resolve, reject) => {
				this.PUT("/user/refresh", {
					refresh_token: refreshToken,
				}, {
					validateStatus: (status) => (status === 200)
				}, "overrideNotLoggedIn").then((resp) => {
					authToken = resp.item.token;
					refreshToken = resp.item.refresh;
					localStorage.setItem("authToken", authToken);
					localStorage.setItem("refreshToken", refreshToken);
					lastRefresh = resp;
					isRefreshing = false;
					resolve(resp);
				}).catch(reject);
			});
		} else {
			return new Promise((resolve, reject) => {
				let interval = setInterval(() => {
					if (!isRefreshing) {
						clearInterval(interval);
						resolve(lastRefresh);
					}
				}, 200);
			});
		}
	};

	this.tryAgain = (endpoint) => {
		if (!isRefreshing) {
			errorState = endpoint;
			return new Promise((resolve, reject) => {
				let timeout = setTimeout(() => {
					clearInterval(interval);
					reject("Timeout occurred");
				}, 350);

				let interval = setInterval(() => {
					if (!isRefreshing && !errorState) {
						clearInterval(interval);
						clearTimeout(timeout);
						resolve();
					}
				}, 350);
			});
		} else {
			return new Promise((resolve, reject) => {
				if (endpoint === errorState) {
					if (trycount >= 2) {
						trycount = 0;
						errorState = false;
						this.logout("ERRORR")
						reject(errorState);
					}
					trycount++;
				};

				let timeout = setTimeout(() => {
					clearInterval(interval);
					errorState = false
					reject("Timeout occurred");
				}, 350);

				let interval = setInterval(() => {
					if (!isRefreshing && !errorState) {
						clearInterval(interval);
						clearTimeout(timeout);
						errorState = endpoint
						resolve();
					}
				}, 350);
			});
		}
	};

	// Convert base64 string to Uint8Array
	this.ToUint8Array = (base64) => {
		const binaryString = atob(base64);
		const len = binaryString.length;
		const bytes = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes;
	}

	this.Uint8ArrayToBase64 = (uint8Array) => {
		let binaryString = '';
		for (let i = 0; i < uint8Array.length; i++) {
			binaryString += String.fromCharCode(uint8Array[i]);
		}
		return btoa(binaryString);
	}

	this.doLogin = (email, password, mfa) => {
		localStorage.removeItem("logout_reason");
		// Encrypts password
		const sodium = window.sodium;
		const publicKey = this.ToUint8Array("Of4AejM3xsLrpQpN2PHvLpJj5q683m2JzsKiRJTDMD8=")
		const encryptedPassword = sodium.crypto_box_seal(password, publicKey);
		const encodedPassword = this.Uint8ArrayToBase64(encryptedPassword);

		return new Promise((resolve, reject) => {
			this.PUT("/user/login", {
				email,
				password: encodedPassword,
				mfa
			}, {}, "overrideNotLoggedIn").then((resp) => {

				authToken = resp.item.token ?? "";
				refreshToken = resp.item.refresh ?? "";
				localStorage.setItem("authToken", authToken);
				localStorage.setItem("refreshToken", refreshToken);
				resolve(resp);
			}).catch((err) => {
				localStorage.removeItem("authToken");
				localStorage.removeItem("refreshToken");
				reject(err);
			});
		});
	};

	this.isUserLoggedIn = () => {
		return !(authToken === "");
	};

	this.setWebsiteGUID = (guid) => {
		localStorage.setItem("selected_site", guid);
	};

	this.getWebsiteGUID = () => {
		let guid = localStorage.getItem("selected_site");
		return guid === "null" ? null : guid;
	};

	this.setCustomerGUID = (guid) => {
		localStorage.setItem("selected_customer", guid);
	};

	this.getCustomerGUID = () => {
		let guid = localStorage.getItem("selected_customer");
		return guid === "null" ? null : guid;
	};
	this.clearStore = () => {
		store.dispatch({ type: 'CLEAR_STORE' });
	};
	this.logout = (reason) => {
		localStorage.clear();
		if (typeof reason === "string") {
			localStorage.setItem("logout_reason", reason);
		}
		document.location = "/";
	};

	this.handleError = (error) => {
		console.log("Error", error)
	};

	this.getAFPWebsiteId = () => {
		let websiteId = localStorage.getItem("bcn-website-id");
		return websiteId === "null" ? null : websiteId;
	}
	const afpEndpoints = ['2.0/', '3.0/'];
	const getUrl = (endpoint, querystringObj) => {
		let queryObj = Object.assign({
			oa_clientKey: config.applicationKey
		}, querystringObj);

		if (afpEndpoints.some(e => endpoint.includes(e))) {

			console.log(endpoint);
			queryObj['url'] = endpoint;
			endpoint = `/afp/${this.getAFPWebsiteId()}`
		}


		return `${endpoint}?${new URLSearchParams(queryObj).toString()}`
	};

	this.HEAD = (endpoint, querystringObj) => {
		if (authToken === "") {
			throw new Error("Invalid: User is not logged in");
		}

		const queryUrl = getUrl(endpoint, querystringObj);

		const headers = {};

		return new Promise((resolve, reject) => {
			let retryCount = 0;
			let reloadTries = 0;
			let retryTimer = 300;

			const makeRequest = () => {
				authToken = localStorage.getItem("authToken")
				if (authToken !== "") {
					headers['Authorization'] = authToken;
				}
				instance.head(queryUrl, {
					headers
				}, {
					validateStatus: (status) => status === 200
				}).then(resp => {
					resolve(resp.headers);
				}).catch(err => {
					if (err.response.status === 401) {
						this.tryRefreshToken().then(() => {
							this.HEAD(endpoint, querystringObj).then(resolve).catch(reject);
						}).catch(() => { this.logout("refresh_error"); });
					} else if (errorStates.includes(err.response?.status)) {
						if (retryCount < 3) {
							setTimeout(() => {
								return makeRequest();
							}, retryTimer);
							retryTimer = retryTimer * 2;
							retryCount++;
						} else {
							reloadOrLogout(reloadTries);
							retryCount = 0;
						}
					} else {
						reject(err)
					}
				});
			}
			makeRequest();
		});
	};

	this.GET = (endpoint, querystringObj) => {
		if (authToken === "") {
			throw new Error("Invalid: User is not logged in");
		}

		const queryUrl = getUrl(endpoint, querystringObj);

		const headers = {};

		return new Promise((resolve, reject) => {
			let retryCount = 0;
			let reloadTries = 0;
			let retryTimer = 300;

			const makeRequest = () => {
				authToken = localStorage.getItem("authToken")
				if (authToken !== "") {
					headers['Authorization'] = authToken;
				} // Incase it hads refreshed
				instance.get(queryUrl, {
					headers
				}, {
					validateStatus: (status) => status === 200
				}).then(resp => {
					resolve(resp.data);
				}).catch(err => {
					if (err.response?.status === 401) {
						this.tryRefreshToken().then(() => {
							this.GET(endpoint, querystringObj).then(resolve).catch(reject);
						}).catch(() => { this.logout("refresh_error"); });
					} else if (errorStates.includes(err.response?.status) || typeof err.response?.status === "undefined") {
						if (retryCount < 3) {
							setTimeout(() => {
								return makeRequest();
							}, retryTimer);
							retryTimer = retryTimer * 2;
							retryCount++;
						} else {
							reloadOrLogout(reloadTries);
							retryCount = 0;
						}
					} else {
						reject(err)
					}
				});
			};

			makeRequest();
		});
	};

	this.PATCH = (endpoint, querystringObj) => {
		if (authToken === "") {
			throw new Error("Invalid: User is not logged in");
		}

		const queryUrl = getUrl(endpoint, querystringObj);

		const headers = {};

		return new Promise((resolve, reject) => {
			let retryCount = 0;
			let reloadTries = 0;
			let retryTimer = 300;
			const makeRequest = () => {

				authToken = localStorage.getItem("authToken")
				if (authToken !== "") {
					headers['Authorization'] = authToken;
				};

				instance.patch(queryUrl, {
					headers
				}, {
					validateStatus: (status) => status === 200
				}).then(resp => {
					resolve(resp.data);
				}).catch(err => {
					if (err.response.status === 401) {
						this.tryRefreshToken().then(() => {
							this.PATCH(endpoint, querystringObj).then(resolve).catch(reject);
						}).catch(() => { this.logout("refresh_error"); });
					} else if (errorStates.includes(err.response?.status)) {
						if (retryCount < 3) {
							setTimeout(() => {
								return makeRequest();
							}, retryTimer);
							retryTimer = retryTimer * 2;
							retryCount++;
						} else {
							reloadOrLogout(reloadTries);
							retryCount = 0;
						}
					} else {
						reject(err)
					}
				});
			};
			makeRequest();

		});
	};

	this.POST = (endpoint, payload, querystringObj) => {
		if (authToken === "") {
			throw new Error("Invalid: User is not logged in");
		}

		const queryUrl = getUrl(endpoint, querystringObj);

		const headers = {};

		return new Promise((resolve, reject) => {
			let retryCount = 0;
			let reloadTries = 0;
			let retryTimer = 300;
			const makeRequest = () => {

				if (authToken !== "") {
					headers['Authorization'] = authToken;
				}

				instance.post(queryUrl, payload, {
					headers,
					// validateStatus: (status) => status === 200
				}).then(resp => {
					resolve(resp.data);
				}).catch(err => {
					if (err.response?.status === 401) {
						this.tryRefreshToken().then(() => {
							this.POST(endpoint, payload, querystringObj).then(resolve).catch(reject);
						}).catch(() => { this.logout("refresh_error"); });
					} else if (queryUrl.includes('/mfa')) {
						reject(err.response);
					} else if (errorStates.includes(err.response?.status)) {
						if (retryCount < 3) {
							setTimeout(() => {
								return makeRequest();
							}, retryTimer);
							retryTimer = retryTimer * 2;
							retryCount++;
						} else {
							reloadOrLogout(reloadTries);
							retryCount = 0;
						}
					} else {
						reject(err)
					};
				});
			};
			makeRequest();
		});
	};

	this.PUT = (endpoint, payload, querystringObj, overrideReason) => {
		if (authToken === "" && overrideReason !== "overrideNotLoggedIn") {
			throw new Error("Invalid: User is not logged in");
		}

		const queryUrl = getUrl(endpoint, querystringObj);

		const headers = {};

		return new Promise((resolve, reject) => {
			let retryCount = 0;
			let reloadTries = 0;
			let retryTimer = 300;
			const makeRequest = () => {
				if (authToken !== "") {
					headers['Authorization'] = authToken;
				}
				instance.put(queryUrl, payload, {
					headers
				}).then(resp => {
					resolve(resp.data);
				}).catch(err => {
					if (err.response?.status === 401) {
						this.tryRefreshToken().then(() => {
							this.PUT(endpoint, payload, querystringObj).then(resolve).catch(reject);
						}).catch(() => { this.logout("refresh_error"); });
					} else if (errorStates.includes(err.response?.status) && !excludedFromRetry.includes(endpoint)) {
						if (retryCount < 3) {
							setTimeout(() => {
								return makeRequest();
							}, retryTimer);
							retryTimer = retryTimer * 2;
							retryCount++;
						} else {
							reloadOrLogout(reloadTries);
							retryCount = 0;
						}
					} else {
						reject(err)
					};
				});
			};
			makeRequest();
		});
	};

	this.DELETE = (endpoint, querystringObj) => {
		if (authToken === "") {
			throw new Error("Invalid: User is not logged in");
		}

		const queryUrl = getUrl(endpoint, querystringObj);

		const headers = {};

		return new Promise((resolve, reject) => {
			let retryCount = 0;
			let reloadTries = 0;
			let retryTimer = 300;
			const makeRequest = () => {
				if (authToken !== "") {
					headers['Authorization'] = authToken;
				}
				instance.delete(queryUrl, {
					headers
				}).then(resp => {
					resolve(resp.data);
				}).catch(err => {
					if (err.response.status === 401) {
						this.tryRefreshToken().then(() => {
							this.DELETE(endpoint, querystringObj).then(resolve).catch(reject);
						}).catch(() => { this.logout("refresh_error"); });
					} else if (err.response.status === 404) {
						resolve(err);
					} else if (errorStates.includes(err.response?.status)) {
						if (retryCount < 3) {
							setTimeout(() => {
								return makeRequest();
							}, retryTimer);
							retryTimer = retryTimer * 2;
							retryCount++;
						} else {
							reloadOrLogout(reloadTries);
							retryCount = 0;
						}
					} else {
						reject(err)
					};
				});
			};
			makeRequest();
		});
	};

	function reloadOrLogout(reloadTries) {
		if (reloadTries < 3) {
			window.location.reload();
			reloadTries++
		} else {
			api.logout("403 error")
		}

	};

	this.paged = (endpoint, limit, page, querystringObj) => {
		if (authToken === "") {
			throw new Error("Invalid: User is not logged in");

		}

		if (typeof querystringObj === "undefined") {
			querystringObj = {};
		}

		limit = typeof limit === "undefined" ? 10 : limit;
		page = typeof page === "undefined" ? 1 : page;

		querystringObj = Object?.assign(querystringObj,
			{
				limit: parseInt(limit),
				page: parseInt(page)
			});

		const headers = {};
		if (authToken !== "") {
			headers['Authorization'] = authToken;
		}

		return this.GET(endpoint, querystringObj);
	};
};

const api = new VeracityAPI();

export default api;