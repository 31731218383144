import React from "react";
import "./EmailNotification.scss";
import { useSelector } from "react-redux";
import api from "../../../veracityapi";
import ManageCustomersData from "../../../SharedComponents/ManageCustomerData/ManageCustomersData";
import { useDispatch } from "react-redux";

const EmailNotification = () => {
	const emailSettings = useSelector(state => state?.websiteDetails?.data?.emailSettings||[]);
	const userGUID = localStorage.getItem("userGUID");
	const dispatch = useDispatch();
	const [toggle, setToggle] = React.useState({
		"weeklyProtectionStatus": `${emailSettings.weeklyProtectionStatusEmails}`,
		"javascriptVulnerabilities": `${emailSettings.JSVulnerabilityEmails}`,
		"unusualBotActivity":`${emailSettings.unusualBotEmails}`,
		"upsellingEmails":emailSettings.upsellingEmails
	});

	const handleEmailToggles = (e) => {
		const { name } = e.target;
		setToggle(pre => ({
			...pre,
			[name]: pre[name] === "1" ? "0" : "1"
		}));
		
	};

	function handleUpdateEmailSettings() {
		api.PUT(`/user/${userGUID}/emailsettings`, { "emailSettings": toggle })
			.then(res =>  ManageCustomersData({}, dispatch))
			.catch((e) => console.log(e))
	};
	React.useEffect(() => {
		handleUpdateEmailSettings();
	}, [toggle]);

	return (
		<div className="email-notification-content">
			<h3>Email notification</h3>
			<div className="email-toggles">
				<div className={`email-toggle-button ${toggle.weeklyProtectionStatus === "1" ? 'active' : 'inactive'} `}>
					<input type="checkbox"
						// value={toggle.weeklyProtectionStatusEmails}
						id="protectionswitch"
						onClick={handleEmailToggles}
						name="weeklyProtectionStatus"
						checked={toggle.weeklyProtectionStatus}
					/>
					<label htmlFor="protectionswitch" className="slider"></label>
				</div>
				<div>Weekly protection status updates</div>
			</div>
			<div className="email-toggles">
				<div className={`email-toggle-button ${toggle.javascriptVulnerabilities === "1" ? 'active' : 'inactive'}`}>
					<input
						type="checkbox"
						id="javascriptswitch"
						onClick={handleEmailToggles}
						name="javascriptVulnerabilities"
						// value={toggle.JSVulnerabilityEmails}
						checked={toggle.javascriptVulnerabilities}
					/>
					<label htmlFor="javascriptswitch" className="slider"></label>
				</div>
				<div>JavaScript vulnerabilities</div>
			</div>
			<div className="email-toggles">
				<div className={`email-toggle-button ${toggle.unusualBotActivity === "1" ? 'active' : 'inactive'}`}>
					<input type="checkbox"
						id="botsActivitySwitch"
						onClick={handleEmailToggles}
						name="unusualBotActivity"
						// value={toggle.unusualBotEmails}
						checked={toggle.unusualBotActivity}
					/>
					<label htmlFor="botsActivitySwitch" className="slider"></label>
				</div>
				<div>Unusual bot activity</div>
			</div>
			<div className="email-toggles">
				<div className={`email-toggle-button ${toggle.upsellingEmails==="1"  ? 'active' : 'inactive'}`}>
					<input
					 type="checkbox"
						id="visitlimitswitch"
						onClick={handleEmailToggles}
						name="upsellingEmails"
						// value={toggle.upsellingEmails}
						checked={toggle.upsellingEmails}
					/>
					<label htmlFor="visitlimitswitch" className="slider"></label>
				</div>
				<div>Close to visits limit</div>
			</div>
		</div>
	);
};

export default EmailNotification;
