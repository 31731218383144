import React from "react";
import "./TodayTile.scss";
import { useSelector } from "react-redux";
import api from "../../../veracityapi";
const PendingPackage = (props) => {
	const customerGUID = localStorage.getItem("selected_customer");
	const package_guid = props.pendingPackage;
	const [loading,setLoading]=React.useState(false);
	const customerPackages = useSelector(state => state.websiteDetails.data.customerPackages);
	const currentPackage = customerPackages.filter((val) => val.current === 1);

	const updatePackage = () => {
		setLoading(true)
		api.GET(`/stripe/${customerGUID}/checkout/${package_guid}`)
			.then(res => {
				setLoading(false)
				const Url = res.item.stripe_redirect_url;
				window.location.href = Url;
			}).catch((e) => console.log(e))
	};
	// const downGradePackage = () => {
	// 	api.GET(`/stripe/${customerGUID}/cancel/`)
	// 		.then(res => {
	// 			// setLoading(false)
	// 			const Url = res.item.stripe_redirect_url;
	// 			window.location.href = Url;
	// 		}).catch((e) => console.log)

	// };

	
	

	const PendingPackage = () => {

		return (
			<>
				<div className="close-package-modal" onClick={props.handlePackageModal}>X</div>
				<div className="modal-title-parent">				
					<h2 className="modal-title">Welcome to Veracity</h2>
				</div>
				<div>
					<p style={{ marginBottom:"0" }}>Thank you for choosing to upgrade your package. <br/><br/>Please continue to configure billing.</p>
				</div>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<button className="downgrade-btn" onClick={props.handlePackageModal}> Downgrade to Free</button>
					<button className="upgrade-btn" onClick={updatePackage}>{loading?"Processing...":"Continue"}</button>
				</div>
			</>

		)
	};
	return (
		<>
			{props.packageModal &&
				<div className="package-modal-section">
					<div className="package-modal-content">
						<PendingPackage />
					</div>
				</div>
			}
		</>
	)
};

export default PendingPackage;