import React from "react";
import "./Help-GettingStarted.scss";

const HelpGettingStarted = () => {
	return (
		<div className="getting-started-section">
			<h3>How to Add Your Veracity WTP Tag</h3>
			<p>
				The Veracity code should be placed on every page you wish to track. We recommend adding the tag to your website footer, just before the closing /body tag. Get instructions <a href="https://go.veracitytrustnetwork.com/resource/veracity-tag/ " target="_blank" rel="noreferrer">here</a> on how to do this.
			</p>
		</div>
	);
};

export default HelpGettingStarted;