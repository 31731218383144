import React from "react";
import { useSelector } from "react-redux";
import "./Today.scss";
import TodayTile from "./TodayComponents/TodayTile";
import PackageModal from "./TodayComponents/PackageModal";
import PendingPackage from "./TodayComponents/PendingPackage";
import api from "../../veracityapi"

const Today = () => {
	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};

	const customerPackages = useSelector(state => state?.websiteDetails?.data?.customerPackages);
	const currentPackage = customerPackages?.find((val) => val.current === 1);
	const [packageModal, setPackageModal] = React.useState(true);
	const checkoutId = localStorage.getItem("CheckoutID");
	const lastTraffic =  useSelector((state) => state?.websiteDetails?.data?.websiteDetails);
	const pendingPackage = localStorage.getItem("pending_package");
	const primaryUser=localStorage.getItem("primaryUser");

	
	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	const handlePackageModal = () => {
		setPackageModal(false);
		localStorage.setItem("CheckoutID", undefined)
		localStorage.setItem("pending_package", null)
	};
	const Iframe = () => {
		if(currentPackage?.custom === 1) {
			return (
				<iframe style={
					{
						width: "100%",
						height: "100vh"
					}
				}
					src="https://go.veracitytrustnetwork.com/no-wtp-data-direct/"
					title="Finish Setup" />
			);
		}
		return (
			<iframe style={
				{
					width: "100%",
					height: "90vh",
					border: "none"
				}
			}
				src="https://go.veracitytrustnetwork.com/no-wtp-data-new/"
				title="Finish Setup" />
		);
	}

	return (
		<div className="today-container">
			<div>
				{
					lastTraffic?.last_traffic == null ?
						<>
							<Iframe /> {
								checkoutId !== "undefined" &&
								<PackageModal packageModal={packageModal}
									handlePackageModal={handlePackageModal}
								/>
							}
							{
								(pendingPackage !== "null" && primaryUser==="true") &&
								<PendingPackage
									packageModal={packageModal}
									handlePackageModal={handlePackageModal}
									pendingPackage={pendingPackage}
								/>
							}
						</> :
						<>
							<TodayTile graph={'Current Bot Activity'} />
							<TodayTile graph={'Bot Threat'} />
							<TodayTile graph="Bot Details"/>
							<TodayTile graph={"Today's Finished Bot Analysis"} />
							{
								checkoutId !== "undefined" &&
								<PackageModal packageModal={packageModal}
									handlePackageModal={handlePackageModal}
								/>
							}
							{
								(pendingPackage !== "null" && primaryUser==="true")&&
								<PendingPackage packageModal={packageModal}
									handlePackageModal={handlePackageModal}
									pendingPackage={pendingPackage}
								/>
							}
						</>
				}
			</div>
		</div>
	)
};

export default Today;
