import React from "react";
import "./TodayFinishedSessions.scss";
import { utcTimeStringToLocalTimeString } from "../../../../SharedComponents/DateManager";
import TodayFinishedSessionsPanelData from "./TodayFinishedSessionsPanelController";
import { useSelector, useDispatch } from "react-redux";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import CountriesLineGraph from "../../../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyGraphs/CountriesLineGraph";
import ReferralSources from "../../../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyGraphs/ReferralSources";
import TodayBrowserLineGraph from "./TodayFinishedSessionsComponents/TodayBrowserLineGraph";
import TodayReferralLineGraph from "./TodayFinishedSessionsComponents/TodayReferralLineGraph";
import GeographyGraph from "../../../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyGraphs/GeographyGraph";
import CountriesTable from "../../../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyGraphs/CountriesTable";
import TrafficMessage from "../../../../SharedComponents/TrafficMessage/TrafficMessage";
let updateTime = 60000;

const TodayFinisihedSessions = (props) => {
	const sideToggle = useSelector(state => state.toggle.toggle);
	const dispatch = useDispatch();
	const finishedSessions = useSelector(state => state.todayFinishedSessions.data);
	const [update, setUpdate] = React.useState(false);

	React.useEffect(() => {
		setTimeout(() => {
			setUpdate(!update)
		}, updateTime)
	}, [update]);

	React.useEffect(() => {
		TodayFinishedSessionsPanelData({}, dispatch);
	}, [update]);

	const [zoomTooltip, setZoomTooltip] = React.useState(false);

	const calculateValues = (data = {}, countryArray = []) => {
		const calculatedData = {};
		for (const date in data) {

			const dateData = data[date] ?? {};

			const addValues = countryArray?.map((country) => {
				const sum = (dateData?.bot?.[country] || 0);

				if (!calculatedData[country]) {
					calculatedData[country] = [];
				}
				calculatedData[country].push({
					x: utcTimeStringToLocalTimeString(date, "YYYY-MM-DD, HH:mm", "HH:mm"),
					y: sum,
				});
			});

		};
		return calculatedData
	};

	const formatData = (data = {}) => {
		const countries = Object.values(data)?.map((values) => {
			const allcountries = []
			for (let countries in values) {
				allcountries.push(Object.keys(values[countries]));
			}
			return allcountries

		});
		const countryArray = Array.from(new Set([...countries.flat(2)]));

		const temp = calculateValues(data, countryArray)

		const formattedData = countryArray.map((val) => {
			return {
				id: val,
				data: temp[val]
			}
		})
		return formattedData
	};

	const countriesFormattedData = formatData(finishedSessions.finishedSessionsByCountry);
	const referrerFormattedData = formatData(finishedSessions.finishedSessionsReferrer);
	const sessionsBrowsersData = formatData(finishedSessions.todaySessionsBrowsers);
	const sessionOSData = formatData(finishedSessions.todaySessionsOS);

	const Controls = () => {
		const { zoomIn, zoomOut } = useControls();
		return (
			<>
				<div className="today-zoom-controls-parent">
					<button className="zoom-controls zoom-in-btn" onClick={() => zoomIn()}></button>
					<button className="zoom-controls zoom-out-btn" onClick={() => zoomOut()}></button>
				</div>
			</>
		);
	};

	let dataLength = Object.keys(finishedSessions?.finishedBlockedBots.data.bot);

	return (
		<div className="finishedSessions-graphs-section">
			<div className="today-country-lineGraph">
				<CountriesLineGraph data={countriesFormattedData} />
			</div>
			<div className="today-referral-linegraph">
				<ReferralSources data={referrerFormattedData} />
			</div>
			<div className="today-browser-lineGraph" >
				<TodayBrowserLineGraph data={sessionsBrowsersData} />
			</div>
			<div className="today-OS-lineGraph">
				<TodayReferralLineGraph data={sessionOSData} />
			</div>
			<div className="today-Geography-data" onMouseEnter={() => setZoomTooltip(true)} onMouseLeave={() => setZoomTooltip(false)}>
				{dataLength.length > 0 ?
					<TransformWrapper wheel={{ activationKeys: ["Control"] }}>
						{/* <div className="today-zoom-tooltip-parent"> */}
						<h4 className={zoomTooltip ? "today-zoom-tooltip" : "today-hide-toolTip"}>Ctrl + Scroll To Zoom</h4>
						{/* </div> */}
						<TransformComponent style={{ width: "100%" }}>
							<div className={sideToggle ? "toggle-active-geoGraph" : "toggle-deactive-geoGraph"}>
								<GeographyGraph data={finishedSessions?.finishedBlockedBots.data} />
							</div>
						</TransformComponent>
						<Controls />
					</TransformWrapper> :
					<TrafficMessage message="There are currently no bots on the website." />}
			</div>
			<div className="today-country-table">
				<CountriesTable data={finishedSessions?.finishedBlockedBots.data} />
			</div>

		</div>
	)
};

export default TodayFinisihedSessions;