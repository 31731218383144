import React from "react";
import TopBar from "../../SharedComponents/TopBar/TopBar";
import SideBar from "../../SharedComponents/SideBar/SideBar";
import { useSelector } from "react-redux";
const Generic = ({title}) => {
    const sidetoggle = useSelector(state => (state.toggle.toggle));
	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	return (
		<div className="analytics-container">
			<TopBar />
			<SideBar />
			<div>
                <h1>
                    AFP TODO
                    {' '}{title}
                </h1>
			</div>
		</div>
	);
}
export default Generic;
