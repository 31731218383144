import React from "react";
import { ResponsiveLineCanvas } from "@nivo/line";
import GraphsToolTip from "../../../../../SharedComponents/GraphsToolTip/GraphsToolTip";
import { utcTimeStringToLocalTimeString } from "../../../../../SharedComponents/DateManager";
import TrafficMessage from "../../../../../SharedComponents/TrafficMessage/TrafficMessage";
import { MaxIcon } from "../../../../../SVG/MaxIcon";
import GraphModal from "../../../../../SharedComponents/GraphModal/GraphModal";

const GoodBotsGraphs = (props) => {
	const data = [(props.data)];
	const [modalOpen, setModalOpen] = React.useState(false);
	const handleShowModal = () => {
		setModalOpen(!modalOpen)
	};


	function toolTipContent({ point }) {
		return (
			<div className="toolTipContent">
				<b>{utcTimeStringToLocalTimeString(point.data.x, "", "Do MMM")}</b>
				<div>{props.title}: <b className="humansValues">{point.data.y}</b> </div>
			</div>
		)
	};
	const GoodBotsGraph = React.useMemo(() => {

		let sum = 0;
		for (let keys in data[0].data) {
			sum += data[0].data[keys].y
		}
		if (sum > 0) {
			return (
				<ResponsiveLineCanvas
					data={data}
					margin={{ top: 25, right: 40, bottom: 80, left: 30 }}
					xScale={{ type: 'point' }}
					yScale={{
						type: 'linear',
						min: 0,
						max: 'auto',
						stacked: false,
						reverse: false
					}}
					tooltip={toolTipContent}
					yFormat=">+~f"
					curve="monotoneX"
					axisTop={null}
					axisRight={null}
					axisBottom={{
						orient: 'bottom',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 45,
						legend: '',
						legendOffset: 36,
						legendPosition: 'middle',
						format: (value) => utcTimeStringToLocalTimeString(value, "", "Do MMM")

					}}
					axisLeft={{
						orient: 'left',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: '',
						legendOffset: -40,
						legendPosition: 'middle',
						format: (value) => value % 2 === 0 ? value : ""
					}}
					lineWidth={0}
					enablePoints={false}
					pointSize={10}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor' }}
					pointLabelYOffset={-12}
					// enableArea={true}
					areaBlendMode="darken"
					areaOpacity={1}
					useMesh={true}
				/>
			)
		} else {
			return (
				<TrafficMessage message="There has been no traffic in the selected time period." />
			)
		};

	}, [props.data]);

	return (
		<>

			<div className="graph-header">
				<div className="graph-heading">{props.title} Bots</div>
				<GraphsToolTip message={props.tooltipMessage} />
				{props.showIcon &&
					<div className="max-icon" onClick={handleShowModal}
						title="Expand Graph">
						<MaxIcon />
					</div>
				}
			</div>
			{GoodBotsGraph}
			{modalOpen &&
				<GraphModal
					isOpen={modalOpen}
					onClose={handleShowModal}
					graph={GoodBotsGraph}
					title={`${props.title} Bots`}
				/>
			}
		</>

	)
};
export default GoodBotsGraphs;