import React from "react";
import { useSelector } from "react-redux";
import "./BlockedBotsCo2.scss";
import { store } from "Redux/store";
import { countryToAlpha2 } from "country-to-iso";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import CO2LineGraph from "./BlockedBotsCO2Components/CO2LineGraph";
import Loader from "SharedComponents/Loader/Loader";
import { formatNumber } from "Utils/utils";

const BlockedBotsCo2 = (props) => {
	const data = useSelector(state => state.websiteCO2.data);
	const websiteStatus = store.getState()?.websiteDetails?.data?.websiteDetails.status === "protect" ? "blocked" : "detected"
	const botsString = websiteStatus === "protect" ? "Blocked Bots" : "Detected Bots";
	const finishedSessionsData = useSelector(state => state?.finishedSessions?.data || []);
    const bots = finishedSessionsData?.currentData?.bot;

	let botsTimeText;
	const regionNames = new Intl.DisplayNames(
		['en'], { type: 'region' }
	);

	switch (finishedSessionsData.timeRange) {
		case "Prev 7 Days":
			botsTimeText = "in the last 7 days"
			break;
		case "Prev 14 Days":
			botsTimeText = "in the last 14 days"
			break;
		case "Prev 28 Days":
			botsTimeText = "in the last 28 days"
			break;
		case "this month":
			botsTimeText = "in the last 7 days"
			break;
		case "Last Month":
			botsTimeText = "in the last month"
			break;
		default:
			botsTimeText = "in the selected time frame"
			break;
	}

	let blockedBotCount = data?.blockedCount.totalcount
	let CO2SavedWithMeasurement
	(blockedBotCount * 1.5 < 1000) ? CO2SavedWithMeasurement = `${(blockedBotCount * 1.5).toFixed(1)}g` : CO2SavedWithMeasurement = `${((blockedBotCount * 1.5) / 1000).toFixed(1)}kg`

	let CO2SavingsEquivelentCars = (blockedBotCount * 5.208333333333333) / 1600
	let CO2SavingsEquivelentPhones = blockedBotCount * 66 / 1000

	let CO2SavingsEquivelentRounded = (blockedBotCount * 1.5 > 5000) ? `This is the equivelent to driving an average gasoline-powered car for ${CO2SavingsEquivelentCars.toFixed(1)} miles` : `That's equivalent to charging a smartphone ${CO2SavingsEquivelentPhones.toFixed(1)} times`;

	return (
		<>
			<div className="blocked-bots-content">
				<div className="header-text-one">
					{props.loading ? <Loader /> :
						<>
							<div>
								<div className="protection-header">
									<h2>{websiteStatus === "detected" ? "Bot Detection" : "Protection"}</h2>
									<div><GraphsToolTip message={`This is a summary of the bots we have ${websiteStatus} you against.`} /></div>
								</div>

								<h3 className="bot-blocked-count-text" style={{ color: `${websiteStatus === "detected" ? "#e63946" : "#6EBA7A"}` }}>
									{formatNumber(bots)} bots {websiteStatus}
								</h3>
								<h4 className="bot-blocked-count-sub-text protection-text-purple">{botsTimeText}</h4>

								<p className="bold-protection-text">The most hit page</p>
								<p className="protection-text-purple">{data?.landingPage.landing_page ?? "None"}</p>

								<p className="bold-protection-text">The country that sent the most bots</p>
								<p className="protection-text-purple">{data?.mostBlockedCountry.length !== 0 ? regionNames.of(countryToAlpha2(data?.mostBlockedCountry?.country)) : "None"}</p>

								<p className="bold-protection-text">The referrer with the most bots</p>
								<p className="protection-text-purple">{data?.mostBlockedReferrer.referrer ?? "None"}</p>
							</div>
						</>}
				</div>


				<div className="header-text-two">
					{props.loading ? <Loader /> :
						<>
							<div>
								<div className="protection-header">
									<h2>{websiteStatus === "detected" ? "Bot CO2 Wastage" : "CO2 Savings"}</h2>
									<div style={{ textAlign: "end" }}><GraphsToolTip message={<p><a href="https://veracitytrustnetwork.com/blog/industry-insight/carbon-savings-from-bot-reduction/" style={{ marginTop: 10, color: "white" }} target="_new">Click here </a>to learn more about this panel.</p>} /></div>
								</div>
							</div>
							<h3 className="C02-saving-text-amount-text" style={{ color: `${websiteStatus === "detected" ? "#e63946" : "#6EBA7A"}` }}>{CO2SavedWithMeasurement} {`${websiteStatus === "detected" ? "wasted" : "saved"}`}
								<img src={require(websiteStatus === "detected" ? "../../../images/red_leaf_icon.png" : "../../../images/green_leaf_icon.png")} alt="CO2 leaf" className="CO2-icon" />
							</h3>
							<h4 className="bot-blocked-count-sub-text CO2-desc">{CO2SavingsEquivelentRounded}</h4>

							<h2 className="C02-saving-overtime-text">{`${websiteStatus === "detected" ? "CO2 wasted" : "CO2 savings"}`} {botsTimeText}</h2>
							<CO2LineGraph
								finishedSessionsData={finishedSessionsData}
								botsString={botsString}
								loading={props.loading}
								websiteStatus={websiteStatus}
							/>
							<p className="CO2-source-text"> Source: <span className="CO2-source-text-green"><a href="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references" target="_blank" rel="noreferrer">https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references</a></span></p>
						</>}
				</div>
			</div>
		</>
	)
};

export default BlockedBotsCo2;