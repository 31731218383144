import api from "../../../../API/BeaconAPI";
import { CurrentDataAct } from "../../../../Redux/actions";
import { getStartTimeStampFromString,getEndTimeStampFromString } from "../../../../SharedComponents/DateManager";


// eslint-disable-next-line import/no-anonymous-default-export
export default function ({adType,timeRange='Prev 7 Days',campaignId=-1,linkId = -1}, dispatch) {
	const websiteID = api.getWebsiteId();
	const start = getStartTimeStampFromString(timeRange);
	const end = getEndTimeStampFromString(timeRange);
	if(!adType){
		return false;
	}
	const handleRequest = (url,resolve, reject)=>{
		api.GET(url).then(res => {
			resolve(
				res.response
			);
		}).catch((error) => {
			console.log("ERROR: ", error);
			reject(error);
		});
	}
	const getClicks = new Promise((resolve, reject) => {
		let url = `3.0/graph/${websiteID}/pd/links/${start}:${end}/${adType}`
		if(campaignId > -1){
			url = `3.0/graph/${websiteID}/pd/campaigns/${campaignId}/${start}:${end}/${adType}`;
		}else if(linkId > -1){
			url = `3.0/graph/${websiteID}/pd/links/${linkId}/${start}:${end}/${adType}`
		}
		handleRequest(url,resolve, reject);
	});
	const getBaselineData = new Promise((resolve, reject) => {
      	handleRequest(`3.0/website/${websiteID}/baseline/${adType}`,resolve, reject);
	});
	const getCurrentData = new Promise((resolve, reject) => {
		let url = `3.0/website/${websiteID}/pd/campaigns/${start}:${end}/${adType}`;
		if(campaignId > -1 ){
			url = `3.0/website/${websiteID}/pd/campaigns/${campaignId}/links/${start}:${end}/${adType}`;
		}else if(linkId > -1){
			// resolve(defa.filter(i=>i.link_id === parseInt(linkId)));
			// return;
			url = `3.0/link/${linkId}/${start}:${end}/website/${websiteID}/${adType}`;
		}
		handleRequest(url,resolve, reject);
	});


	const processDataCreateSummary = (data,baseline)=>{
		const summary = {};
		const headers = data[0] && Object.keys(data[0]) || [];
		headers.map(i=>{
			if(typeof data[0][i] == 'number'){
				summary[i] = data.reduce((a,o) =>a+(o[i]??0),0);
			}else if(typeof data[0][i] == 'string'){
				summary[i] = ''
			}
		})
		const baselineEnggP = baseline?.visits !==0 ? parseFloat(baseline?.engaged/ baseline?.visits * 100 || 0).toFixed(1):0;
		const baselineHp = baseline?.clicks !== 0 ? parseFloat((baseline?.visits/baseline?.clicks*100).toFixed(1))  :0;

		const engagementPercentage = (summary?.engaged/ summary?.visits * 100|| 0).toFixed(1)
		summary['bot_percentage'] = summary?.clicks >= 0 ? parseFloat((summary?.bots/summary?.clicks*100).toFixed(1)) :0;
		summary['human_percentage'] = summary?.clicks >= 0 ? parseFloat((summary?.visits/summary?.clicks*100).toFixed(1))  :0;
		summary['change_in_human_percentage'] = baselineHp && parseFloat((parseFloat(summary['human_percentage']) -baselineHp)/baselineHp*100).toFixed(1);
		summary['change_in_engagement_percentage'] = baselineEnggP && parseFloat((engagementPercentage - baselineEnggP )/baselineEnggP *100).toFixed(1);
		summary['human_click_cost'] = summary?.visits >= 0 ? parseFloat((summary?.spend/summary?.visits).toFixed(1))  :0;
		summary['engagement_percentage'] = engagementPercentage;
		return summary;
	}
	return new Promise((resolve, reject) => {
		Promise.all([ getClicks,getCurrentData, getBaselineData]).then( ([clicks,values,baseline]) => {
			let data = {};
			let summary = processDataCreateSummary(values,baseline);
			data[adType] =  {values,summary,clicks,baseline};
			dispatch(CurrentDataAct({data}));
			resolve(true);
		}).catch((error)=>{
			api.handleError(error);
			reject(error);
		});
	});
};