import React from "react";
import "./AppMaintenance.scss";
import { MaintenancePageImage } from "../../../SVG/MaintenancePageImage";
import {Link} from "react-router-dom";
const AppMaintenance = ({ status }) => {

	return (
		<div className="app-maintenance-container">
			<img src={require("../../../components/images/Veracity-compact-white.png")} alt="veracity-logo" />
			<div>
				<h2>Veracity is Currently Unavailable</h2>
				<h3>{status}</h3>
				<div>
					<MaintenancePageImage />
				</div>

				<p>Need urgent support? <Link to="https://go.veracitytrustnetwork.com/contact-support/" target="_blank" style={{textDecoration:"none"}}>Contact us</Link></p>

			</div>
		</div>
	)
};


export default AppMaintenance;
