import React from "react";
import ReactDOM from "react-dom";
import ValueCard from "../../ValueCard/ValueCard";
import ActiveCampaignPanel from "../../ActiveCampaigns/ActiveCampaignPanel";
import ClicksVisitsLineGraph from "../../ClicksVisitsLineGraph/ClickVisitsLineGraph";
import BumpGraph from "../../BumpGraph/BumpGraph";
import "./CurrentTile.scss";
import Tile from "../../../../SharedComponents/Layout/Tile/Tile";
import { useSelector, useDispatch } from "react-redux";
import CurrentData from "../Data/CurrentData";
import Loader from "../../../../SharedComponents/Loader/Loader";
import { formatNumber } from "../../../../Utils/utils";
import { Adnetworks } from "../../Constants";
import GraphsToolTip from "../../../../SharedComponents/GraphsToolTip/GraphsToolTip";
import TrafficFunnel from "../../TrafficFunnel";
import IncreaseIndicator from "../../IncreaseIndicator/IncreaseIndicator";
import { useLocation, Link } from "react-router-dom";
import { MaxIcon } from "../../../../SVG/MaxIcon";
import GraphModal from "../../../../SharedComponents/GraphModal/GraphModal";
import Breadcrumb from "../../Breadcrumb";

const CurrentTile = ({ campaignId, linkId, adType = -1 }) => {
    adType = parseInt(adType);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const currentData = useSelector(state => state.currentData.data) || {};
    const campaignName = useSelector(state => state.currentData.campaignName)
    const [supportLink, setSupportLink] = React.useState("");
    const primaryUser = localStorage.getItem("primaryUser") === "true";
    const adNetworks = useSelector(state => state.currentData.selectedNetworks) || [];
    const [timeRange, setTimeRange] = React.useState({ timeRange: 'Prev 14 Days' });
    const dispatch = useDispatch();
    const filterPathname = campaignId ?? linkId;
    const currenySymbol = localStorage.getItem("bcn-currency-symbol");
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [maxIcon, setMaxIcon] = React.useState(false);
    const [bumpMaxIcon, setBumpMaxIcon] = React.useState(false);
    const [bumpGraphModal, setBumpGraphModal] = React.useState(false);
    const [baseURL, setBaseURL] = React.useState(`/afp/current/campaign`);

    const handleMaximizeClick = () => {
        setIsModalOpen(!isModalOpen);
    };
    const handleBumpGraphMaximizeClick = () => {
        setBumpGraphModal(!bumpGraphModal)
    };


    React.useEffect(() => {
        adNetworks.map(i => {
            if (!currentData[i]?.summary) {
                setLoading(true);
                CurrentData({ adType: i, timeRange: timeRange.timeRange, campaignId, linkId }, dispatch).then(() => {
                    setLoading(false);
                    setError('');
                }).catch((e) => {
                    setLoading(false);
                    getErrorMessage(e)
                });
            }
        })

    }, [adNetworks.length]);

    const getErrorMessage = (error) => {

        if (primaryUser && error?.response?.status === 403) {
            setSupportLink("Website Settings")
            setError("The connection to your Veracity Ad Fraud Protection account has expired. Visit Website Settings to reconnect.")
        } else if (!primaryUser && error?.response?.status === 403) {
            setError("The connection to your Veracity Ad Fraud Protection account has expired. The primary user on your account must reconnect in Website Settings.")
        }
        else {
            setSupportLink("Contact Support")
            setError("Sorry, we’re having trouble loading your Veracity Ad Fraud Account, if this problem persists please contact us for support.")
        }

    };

    const updateTimeRange = (timeRange) => {
        setTimeRange(timeRange);
        setLoading(true);
        setBaseURL(`/afp/current/campaign`);
        if (campaignId) {
            setBaseURL(`/afp/current/link`);
        }
        adNetworks.map(i => {
            if (adType == -1 || adType === i) {
                CurrentData({ adType: i, timeRange: timeRange.timeRange, campaignId, linkId }, dispatch).then(() => {
                    setLoading(false);
                    setError('');
                }).catch((e) => {
                    setLoading(false);
                    setError(`Failed to change time range : ${e}`)
                });
            }
        })
    };


    React.useMemo(() => {
        updateTimeRange(timeRange);
    }, [campaignId, linkId])

    return (
        <Tile error={error} showAdnetworkPicker={!(campaignId || linkId)} adNetworks={adNetworks} supportLink={supportLink} dispatchEvent="UPDATE_CURRENT_ADNETWORK" DropFunc={() => { }} DragFunc={() => { }} timeRange={timeRange} setTimeRange={updateTimeRange} dragged={null} enableDateFilter={true} DisplayDashboard={false} graph={filterPathname ? "Current" : 'Current Period'}>
            {adNetworks.map((i, j) => {
                if ((campaignId || linkId) && i !== adType) return null;
                const hP = currentData[i]?.summary?.human_percentage;
                const selectedNetwork = Adnetworks.find(nt => nt.id === i);
                const cHP = currentData[i]?.summary?.change_in_human_percentage;
                const visits = currentData[i]?.summary?.visits;
                const adLabel = selectedNetwork?.label
                const adIcon = selectedNetwork?.icon;
                const adHeadingBackGroundColor = selectedNetwork?.backgroundColor;
                const effectiveSpend = (currentData[i]?.summary.spend - currentData[i]?.summary.wasted_spend).toFixed(2);
                const savings = (currentData[i]?.summary?.savings || 0).toFixed(2);
                const dataAvailable = currentData[i]?.values.length;

                return (
                    <>
                        {filterPathname ? <Breadcrumb baseURL={'/afp/current'} linkId={linkId} adType={adType} adIcon={adIcon} values={currentData[i].values} /> : <h2 className="adNetworkHeading" style={{ background: adHeadingBackGroundColor }}>{adIcon}{adLabel}</h2>}
                        <div key={j} className="grid">
                            <div className='item-1 graph'>
                                <div className="graph-header">
                                    <div className="graph-heading">Engagement funnel</div>
                                    <GraphsToolTip message="A breakdown of clicks from all traffic through to engagement during the selected period." />
                                </div>{loading ? <Loader /> : <TrafficFunnel data={currentData[i]?.summary} />}
                            </div>
                            <div className="item-3 graph" onMouseEnter={() => setMaxIcon(true)} onMouseLeave={() => setMaxIcon(false)}>
                                <div className="graph-header">
                                    <div className="graph-heading">Total Visits and Clicks</div>
                                    <GraphsToolTip message="A daily view of all clicks and visits during the selected period." />
                                    {maxIcon &&
                                        <div className="max-icon" style={{ marginTop: 7 }} onClick={handleMaximizeClick} title="Expand Graph">
                                            <MaxIcon />
                                        </div>
                                    }
                                </div>
                                {loading ? <Loader /> : <ClicksVisitsLineGraph data={currentData[i]?.clicks} botCentric={false} />}
                                {isModalOpen &&
                                    <GraphModal
                                        isOpen={isModalOpen}
                                        onClose={handleMaximizeClick}
                                        graph={<ClicksVisitsLineGraph data={currentData[i]?.clicks} botCentric={false} />}
                                        title="Total Visits and Clicks"
                                    />
                                }
                            </div>
                            <div className='item-4 graph' onMouseEnter={() => setBumpMaxIcon(true)} onMouseLeave={() => setBumpMaxIcon(false)}>
                                <div className="graph-header">
                                    <div className="graph-heading">Bots vs Humans</div>
                                    <GraphsToolTip message="A daily view of what percentage of your traffic is bots during the baseline period." />
                                    {bumpMaxIcon &&
                                        <div className="max-icon" style={{ marginTop: 7 }} onClick={handleBumpGraphMaximizeClick} title="Expand Graph">
                                            <MaxIcon />
                                        </div>
                                    }
                                </div>
                                {loading ? <Loader /> : <BumpGraph data={{ graph: currentData[i]?.clicks, baseline: currentData[i]?.baseline }} />}
                                {bumpGraphModal &&
                                    <GraphModal
                                        isOpen={bumpGraphModal}
                                        onClose={handleBumpGraphMaximizeClick}
                                        graph={<BumpGraph data={{ graph: currentData[i]?.clicks, baseline: currentData[i]?.baseline }} />}
                                        title="Total Visits and Clicks"
                                    />
                                }
                            </div>
                            {dataAvailable ? <div className="item-5" >

                                <ValueCard className="small" loading={loading} title={hP ? `${hP}%` : null} subTitle={visits ? `Humans: ${formatNumber(visits)}` : null}>
                                    <IncreaseIndicator value={cHP} tooltip="Percentage change in the human traffic compared to baseline value" />
                                </ValueCard>
                                <ValueCard className="small" loading={loading} title={` ${formatNumber(currentData[i]?.summary?.['engagement_percentage'])}%`} subTitle={'Engagement Percentage'}><IncreaseIndicator tooltip={'Percentage change in the engagement percentage compared to baseline value'} value={currentData[i]?.summary?.['change_in_engagement_percentage']} /></ValueCard>
                                <ValueCard className="small" loading={loading} title={`${currenySymbol} ${formatNumber((currentData[i]?.summary.spend).toFixed(2))}`} subTitle={'Spend'}></ValueCard>
                                <ValueCard className="small" loading={loading} title={`${currenySymbol} ${formatNumber(effectiveSpend)}`} subTitle={'Effective Spend'}></ValueCard>
                                {savings > 0 &&
                                    <ValueCard className="small" loading={loading} title={`${currenySymbol} ${formatNumber(savings)}`} subTitle={'Savings'}></ValueCard>
                                }

                            </div> : <></>}
                            {!linkId && <ActiveCampaignPanel loading={loading} className='item-6' adType={i} data={currentData} baseURL={baseURL} />}

                        </div>
                    </>)
            }
            )}
        </Tile>
    )
}


export default CurrentTile;
