import React from "react";
import "./Help-Billing.scss";

const HelpBilling = () => {
	return (
		<div className="billing-section">
			<h3>GDPR Compliance & Cookie Notices Policy</h3>
			<p>
			The Veracity platform tracks all activity carried out on your website by your website visitors, including mouse movement, interaction with page elements and navigation throughout the site.
			</p>
		</div>
	);
};

export default HelpBilling;