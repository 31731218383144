import React from "react";
import "./GoodBots.scss";

const GoodBots = () => {
	return (
		<div className="GoodBots-section">
			<h3>Will ‘good’ bots, like Google, be blocked?</h3>
			<p>
			Legitimate bots have nothing to hide. Which means they arrive with transparent origins and credentials. So you don’t have to worry about your rankings being impacted when you block malicious bots — the good ones get through unhindered.
			</p>
		</div>
	);
};

export default GoodBots;