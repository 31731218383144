import React from "react";
import { useSelector } from "react-redux";
import Modal from "./Modal";
import api from "../../veracityapi";
import "./BotTypesDescription.scss";

const BotTypesDescription = () => {
	const state = useSelector(state => state.toggle.toggle);
	const botRiskClassification = useSelector(state => state.botTypeClassification.data.botTypeClassification)
	const websiteStatus = useSelector((state) => state.websiteDetails?.data?.websiteDetails?.status);

	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	return (
		<>
			<div className={state ? "content-active" : "content"}>
				<div className="bot-classification-section">
					<div className="bot-type-heading">{websiteStatus === "protect" ? "Blocked Bot Types" : "Bot Types"}</div>
					<div className="modals-section">
						{botRiskClassification?.items?.map((val) => (
							<Modal {...val} />
						))}
					</div>
					{/* <p className="question-text">Can't find what you're looking for?</p>
					<div><a href="https://discord.gg/veracity" target="_blank" rel="noreferrer"><button className="contact-support-button">Ask in our support forum </button></a></div> */}
				</div>
			</div>
		</>
	)
};

export default BotTypesDescription;