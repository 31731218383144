import React from "react";
import "./TodayTile.scss";
import { useSelector } from "react-redux";

const PackageModal = (props) => {
	const customerPackages = useSelector(state => state.websiteDetails.data.customerPackages);
	const currentPackage = customerPackages.filter((val) => val.current === 1);
	const checkoutPackageGUID = localStorage.getItem("CheckoutID");
	let message = (currentPackage[0].package_guid === checkoutPackageGUID);

	return (
		<>
			{
				props.packageModal &&
				<div className="package-modal-section">
					<div className="package-modal-content">
						<div className="close-package-modal" onClick={props.handlePackageModal}>X</div>
						<h2>Upgrade</h2>
						<div className="customer-message">
							{message ? <>
								<p>Thank you for upgrading to our <br /> {currentPackage[0].package_name} package</p>
								<img width={150} src={require("../../images/Screenshot 2023-08-29 011259.png")} alt="test" />
								<p>We've upgraded your account and you're ready <br />to take advantage of your new package. </p>
								<button className="proceed-btn" onClick={props.handlePackageModal}>Proceed</button>
							</> :
								<h4>
									We’re working to upgrade your package, this can take up to 24 hours. If after 24 hours your package has not been upgraded. LINK TO SUPPORT
								</h4>}
						</div>
					</div>
				</div>
			}
		</>
	)
};

export default PackageModal;