import { ResponsiveLine } from "@nivo/line";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "./ClicksVisitsLineGraph.scss";
import NoContent from "../../../SharedComponents/NoContent/NoContent";

const ClicksVisitsLineGraph = ({ data, botCentric = true ,showMarker = false}) => {
	const [initialLinegraphState, setinitialLinegraphState] = React.useState({});

	const formatData = (data) => {
		let clicksData = [];
		let visitsData = [];
		for (let keys in data) {
			let running = data[keys] === 'Running';
			let clicks = data[keys].clicks
			let visits = data[keys].visits
			let unixTime = moment(keys).unix();
			if ((typeof visits !== 'number' || typeof clicks !== "number") && !running) continue;
			if(running){
				clicksData.push({
					x: unixTime,
					y:  null
				});
				visitsData.push({
					x: unixTime,
					y: null
				});
			}else{
			
			clicksData.push({
				x: unixTime,
				y: clicks
			});
			visitsData.push({
				x: unixTime,
				y: visits
			});}
		};
		return [clicksData, visitsData]
	};


	const formattedData = formatData(data);
	function getTickSize (){
		const values = formattedData[0]?.length ?? 0;
		if(values > 20){
			return 15
		}else{
			return values 
		}
	}
	function toolTipContent({ point }) {
		let d = Math.floor(point.data.x.getTime() / 1000);

		setinitialLinegraphState({
			x: moment(point.data.x).format('Do MMM'),
			Visits: (formattedData[1].find(item => item.x === d))?.y ?? 0,
			Clicks: (formattedData[0].find(item => item.x === d))?.y ?? 0
		});
		let clicks = initialLinegraphState.Clicks
		let visits = initialLinegraphState.Visits
		let bots = Math.max(0, clicks - visits);
		let bot_percentage = clicks === 0 ? 0 : bots / clicks;
		let human_percentage = 1 - bot_percentage;
		const showingPercentage = botCentric ? bot_percentage * 100 : human_percentage * 100;
		const showingLabel = botCentric ? 'Bot traffic' : 'Humans traffic';
		return (
			<div className="toolTipContent">
				<b>{initialLinegraphState.x}</b>
				<div className="clicks">Paid Clicks: <b>{initialLinegraphState.Clicks}</b> </div>
				<div className="visits">Human Visits: <b >{initialLinegraphState.Visits}</b></div>
				<div className="">{showingLabel}:<b>{` ${showingPercentage.toFixed(1)}%`}</b></div>
			</div>
		)
	};
	
	const ClickVisitsGraph = React.useMemo(() => {
		let ClickVisitsGraphData = {
			data: [
				{ id: "Clicks", data: formattedData[0] },
				{ id: "Visits", data: formattedData[1] }]
		};
		let colours = [
			"rgb(43, 43, 239)", "rgb(218, 123, 194)"
		];
		let marker =  formattedData[0][formattedData[0].length-2]?.x;
		return (
			<div style={{ width: '100%', height: '96%' }}>
				{!data?.push ?
					<ResponsiveLine
						data={ClickVisitsGraphData.data}
						isInteractive={true}
						margin={{ top: 30, right: 35, bottom: 100, left: 50 }}
						xScale={{
							type: "time",
							precision: "day",
							format: "%s"
						}}
						yScale={{
							type: 'linear',
							min: '0',
							max: 'auto',
							stacked: false,
							reverse: false
						}}

						curve="monotoneX"
						strokeDasharray
						tooltip={toolTipContent}
						colors={colours}
						
						yFormat=" >-.2f"
						axisTop={null}
						axisRight={null}
						axisBottom={{
							orient: 'bottom',
							tickValues: getTickSize(),
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 45,
							legend: '',
							legendOffset: 36,
							legendPosition: 'middle',
							format:(value)=>{
								return moment(value).format('Do MMM')

							}

						}}
						axisLeft={{
							orient: 'left',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendOffset: -40,
							legendPosition: 'middle',
							format: (value) => value % 2=== 0 ? value : ""
						}}

						pointSize={0}
						pointColor={{ theme: 'background' }}
						pointBorderWidth={2}
						pointBorderColor={{ from: 'serieColor' }}
						pointLabelYOffset={-12}
						useMesh={true}
						markers={showMarker?[
						
							{
								axis: 'x',
								legend: `Data availability`,
								legendPosition: 'bottom-left',
								lineStyle: {
									stroke: 'green',
									strokeWidth: 1,
								
								},
								textStyle: {
									stroke: '#000',
								},
								value:  moment.unix(marker)
							}
						]:null}
					/> : <NoContent />}
			</div>
		);
	}, [formattedData]);

	if (data === undefined) {
		return (<div className="alignCenter">Please wait</div>)
	}
	if (data === 'NA') {
		return (<div className="alignCenter">Data Unavailable</div>)
	}
	return (
		<>
			{ClickVisitsGraph}
		</>

	)

};

export default ClicksVisitsLineGraph;