import React from 'react';
import Modal from 'react-modal';
import "./GraphModal.scss";
const GraphModal = ({ isOpen, onClose, graph, title }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      // marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "70%",
      height: '70%',
      borderRadius:"8px",
      boxShadow: "0px 0px 20px 0px lightgray",
      paddingTop: 0,
      overflow:"visible",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
    >

      <div style={{ height: '90%', width: '100%' }}>
        <div className="modal-header">
          <h2>{title}</h2>
          <p className='cancel-btn'onClick={()=>onClose()}>X</p>
        </div>
        {graph}
      </div>
    </Modal>
  );
};

export default GraphModal;

