import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import styles from './index.module.scss'

const Breadcrumb = ({ adIcon, adType, linkId, values = [], baseURL }) => {
    const [items, setItems] = useState([]);
    const params = useParams();
    useEffect(() => {
        let items = [];
        let i = {};
        i = values.filter(j => {
            if (linkId) {
                return j['link_id'] == linkId
            } else {
                return j['campaign_id'] == params['campaignId']
            }
        })[0];

        items.push({ link: `${baseURL}/campaign/${adType}/${i?.campaign_id}`, label: i?.['campaign_name'] })
        if (!!linkId) {
            items.push({ link: `${baseURL}/link/${adType}/${i?.link_id}`, label: i?.['link_notes'] })
        }
        setItems(items);
    }, [params])
    return (
        <p1 className={styles["campaign-title"]}>
            {adIcon} <Link to={baseURL} className={styles["breadcrum"]} title={'All campaigns'} >All campaigns</Link>
            {items.map(item => {
                return (<Link to={item.link ?? ''} className={styles["breadcrum"]} title={item.label}>{item.label} </Link>)
            })}
        </p1>
    )

};
export default Breadcrumb;