import React from "react";
import "./PaymentMethods.scss"
import { useSelector } from "react-redux";
const PaymentMethods = () => {
	
	const paymentMethods=useSelector(state=>state.websiteDetails.data.paymentMethods);

	return (
		<>
			{paymentMethods?.stripe_customer_exists &&
				<div className="payment-methods-content">
					<h3>Payment methods</h3>
					{/* <input className="payment-method-input" /> */}
					<div style={{ float: "left", marginTop: "26px" }}>
						<a href={paymentMethods?.stripe_redirect_url} rel="noreferrer" target="_blank"><button className="add-card-button" >Manage Payment Methods</button></a>
					</div>
				</div>}
		</>
	)
};

export default PaymentMethods;