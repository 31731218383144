import React from "react";
import "./HumanVisitor.scss";

const HumanVisitor = () => {
	return (
		<div className="HumanVisitor-section">
			<h3>What happens if a human visitor is incorrectly identified as a bot?</h3>
			<p>
			Though rare with Veracity, false positives can happen. Fortunately, we’re built to be a problem for bots and a breeze for real humans. A simple Captcha verification will filter out those fringe cases and send actual visitors right through. Our Captcha implementation is the most effective way to handle this step and maintain a great user experience.
			</p>
		</div>
	);
};

export default HumanVisitor;