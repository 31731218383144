import React from "react";
import "./Loading.scss";

const Loading = () => {
	

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "#662D91"
	});


	return (
		<div className="loading-container">
			<div className="loading-gif">
				<img src={require("../../components/images/loading.gif")} alt="Loading" />
			</div>
		</div>
	)
};

export default Loading;