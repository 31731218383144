import React from "react";
import "./MyAccount.scss";
import { useSelector } from "react-redux";
import MyDetails from "./MyDetails/MyDetails";
import EmailNotification from "./EmailNotification/EmailNotification";
import api from "../../veracityapi";

const MyAccount = () => {
    const state = useSelector(state => state.toggle.toggle);
    
    React.useLayoutEffect(() => {
        document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
    }, []);

    if (!api.isUserLoggedIn() || api.getWebsiteGUID() === null || api.getWebsiteGUID() === "undefined") {
        api.logout("Fatal Error")
    };


    return (
        <>
            <div className={state ? "content-active" : "content"}>
                <div className="main-content" >
                    <div className="myaccount">Account Settings</div>
                    <MyDetails />
                    <EmailNotification />
                </div>
            </div>

        </>
    );
};

export default MyAccount;