import React from "react";
import "./Help-Features.scss";

const HelpFeatures = () => {

	return (
		<div className="feature-section">
			<h3>Verifying the Veracity WTP Tag</h3>
			<p>
				When you first install the Veracity code you may want to test that it is correctly installed and that any visits to the site are monitored. Learn <a href="https://go.veracitytrustnetwork.com/resource/verifying-the-veracity-tag/" target="_blank" rel="noreferrer">here</a> how to do this.
			</p>
		</div>
	);
};

export default HelpFeatures;