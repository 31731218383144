
import { ResponsiveFunnel } from '@nivo/funnel'
import styles from './index.module.scss'

const TrafficFunnel = ({data}) => 


{
    const formatData = (d) => {
		
		return [{
            "id": "clicks",
            "value": d?.clicks ?? 0,
            "label": "Paid Clicks"
        },{
            "id": "visits",
            "value": d?.visits ?? 0,
            "label": "Human Visits"
        },
        {
            "id": "engaged",
            "value": d?.engaged ?? 0,
            "label": "Engaged Humans"
        }]
	};


	const formattedData = formatData(data);  
    return(
    
    <div className={styles.root} >
        
    <ResponsiveFunnel
        data={formattedData}
       
        
        margin={{ top: 40, right: 10, bottom: 30, left: 10 }}
        colors={{ scheme: 'purpleRed_green' }}
        fillOpacity={0.5}
        borderWidth={1}
        borderOpacity={1}
        labelColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    '3'
                ]
            ]
        }}
        valueFormat={'=(0,.3~f'}
        shapeBlending={'.6'}
        beforeSeparatorLength={30}
        beforeSeparatorOffset={10}
        afterSeparatorOffset={30}
        currentPartSizeExtension={5}
        currentBorderWidth={0}
        motionConfig={'gentle'}
        theme={{
            text: {
                "fontSize": 22
            }
        }}
    />
    </div>
)
}
export default TrafficFunnel;