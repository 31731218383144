import React from "react";
import AffiliateTable from "./AffiliateTable";
import "../../Affiliates.scss";
import AffiliatesLineGrpah from "./AffiliatesLineGraph";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "SharedComponents/Loader/Loader";
import TrafficMessage from "../../../../SharedComponents/TrafficMessage/TrafficMessage";

const AffiliateIndex = ({ loading }) => {

    const affilatesData = useSelector((state) => state.sessionAffiliates?.data?.perdayData);

    const keys = [...new Set(Object.values(affilatesData).flatMap((item) => Object.keys(item.bot)))].filter((val) => val !== "total");

    const formatDataForTotalBotSection = () => {
        const transformedData = keys.filter((key) => {
            if (key !== "null" && key !== "No Affiliate") {
                return true;
            } else {
                return false;
            }
        }).map((key) => {
            return (
                {
                    id: key,
                    data: Object.entries(affilatesData).map(([date, value]) => ({
                        x: moment(date).format("YYYY-MM-DD"),
                        y: value.bot[key] !== undefined ? value.bot[key] : 0,
                    }
                    )),
                })
        });
        return transformedData
    };

    const formatDataForBotPercentageSection = () => {

        const transformedData = keys.filter((key) => {
            if (key !== "null" && key !== "No Affiliate") {
                return true;
            } else {
                return false;
            }
        }).map((key) => {
            return (
                {
                    id: key,
                    data: Object.entries(affilatesData).map(([date, value]) => ({
                        x: moment(date).format("YYYY-MM-DD"),
                        y: value.bot[key] !== undefined ? ((value.bot[key] / value.bot['total']) * 100).toFixed(1) : 0,
                    }
                    )),
                })
        });
        return transformedData
    };
    const formatDataForTotalBot = formatDataForTotalBotSection();
    const formatDataForBotPercentage = formatDataForBotPercentageSection();


    return (
        <div className="affiliates-grid">
            <div className="item-1">
                {loading ?
                    <Loader /> :
                    <AffiliateTable />
                }
            </div>
            <div className="item-2">
                {loading ?
                    <Loader /> : <>
                        {
                            formatDataForTotalBot.length !== 0 ? <AffiliatesLineGrpah
                                title="Total Bots"
                                data={formatDataForTotalBot}
                            /> :
                                <div style={{ background: "white", position: "relative", minHeight: "30vh" }}>
                                    <TrafficMessage message="No bots from affiliates in the time period selected." />
                                </div>
                        }
                    </>
                }
            </div>
            <div className="item-3">
                {loading ?
                    <Loader /> : <>
                        {
                            formatDataForBotPercentage.length !== 0 ? <AffiliatesLineGrpah
                                title=" Bots Percentage"
                                data={formatDataForBotPercentage}
                                percentageSign="%"
                            /> :
                                <div style={{ background: "white", position: "relative", minHeight: "30vh" }}>
                                    <TrafficMessage message="No bots from affiliates in the time period selected." />
                                </div>
                        }
                    </>

                }
            </div>
        </div>
    )
};

export default AffiliateIndex;