import React from "react";
import moment from "moment";
import './CalendarComponent.scss';
import { LeftArrow, RightArrow, DownArrow, UpArrow } from "../../../SVG/DropDown";
import {store} from "../../../Redux/store";

const Calender = (props) => {
	const timeZone = localStorage.getItem('timezone');
	const [currentSelectedMonth, setCurrentSelectedMonth] = React.useState(moment().tz(timeZone).startOf('month'));
	const [selectedStartDate, setSelectedStartDate] = React.useState(null);
	const [selectedEndDate, setSelectedEndDate] = React.useState(null);
	const [dateSelectorSwitch, setDateSelectorSwitch] = React.useState(false);
	const [displayDropDown, setDisplayDropDown] = React.useState(false);
	const [selectedRange, setSelectedRange] = React.useState("Custom range");
	const [dateTextFields, setDateTextFields] = React.useState({ startDate: null, endDate: null });
	const lastMonth = moment(currentSelectedMonth).tz(timeZone).subtract(1, 'month').startOf('month');
	const dayMax = moment().tz(timeZone).subtract(1, 'day').format("YYYY/MM/DD");
	const ref = React.useRef();
	const maxLimit = 45;

	const endDateValid = (eRHS) => {
		if (moment(eRHS, "YYYY/MM/DD").isAfter(moment(selectedStartDate, "YYYY/MM/DD").tz(timeZone).add(maxLimit, 'day'))) {
			setSelectedEndDate(moment(selectedStartDate, "YYYY/MM/DD").tz(timeZone).add(maxLimit, 'day').format("YYYY/MM/DD"));
		} else {
			setSelectedEndDate(eRHS);
		};
	};

	const startDateValid = (dateRHS) => {
		if (moment(dateRHS, "YYYY/MM/DD").isBefore(moment(selectedEndDate, "YYYY/MM/DD").tz(timeZone).subtract(maxLimit, 'day'))) {
			setSelectedStartDate(moment(selectedEndDate, "YYYY/MM/DD").tz(timeZone).subtract(maxLimit, 'day').format("YYYY/MM/DD"));
		} else {
			setSelectedStartDate(dateRHS);
		};
	};

	const datePickHandler = (inDate) => {
		if (inDate > dayMax) {
			// alert("out of range")
		}
		else if (inDate === ""){
			if(selectedStartDate !== "") {
				setSelectedStartDate("")
			} else {
				setSelectedEndDate("")
			}
		}
		else if (dateSelectorSwitch) {
			if (inDate <= selectedStartDate) {
				setSelectedEndDate(selectedStartDate);
				startDateValid(inDate);
				setDateSelectorSwitch(false)
			} else {
				endDateValid(inDate);
				setDateSelectorSwitch(false);
			};
		}
		else if (selectedEndDate === inDate) {
			setDateSelectorSwitch(true)
		}
		else if (selectedStartDate === null) {			
			startDateValid(inDate);
		}		
		else if (selectedEndDate === null) {
			if (inDate >= selectedStartDate) {
				endDateValid(inDate);
			} else {
				setSelectedEndDate(selectedStartDate);
				startDateValid(inDate);
			};
		}
		else if (selectedEndDate < inDate) {
			endDateValid(inDate);
		}
		else if (selectedEndDate > inDate && !dateSelectorSwitch) {
			startDateValid(inDate);
		}
		setSelectedRange("Custom range");
	};

	React.useEffect(() => {
		setDateTextFields({ startDate: selectedStartDate, endDate: selectedEndDate });
	}, [selectedStartDate, selectedEndDate]);

	const TableRowGenerator = (momentObj) => {
		const rows = [];
		let days = [];
		const offSet = momentObj.day() === 0 ? 7 : momentObj.day();
		let startDay = moment(momentObj).subtract(offSet, 'day');
		const count = moment(momentObj).daysInMonth() + offSet;
		let day = 1;
		let i = 0;

		const classSelector = (date) => {
			const dateAsMoment = moment(date, "YYYY/MM/DD");

			if (dateAsMoment.isAfter(dayMax)) {
				return "normal-date normal-date--exceeds";
			};
			if (i < offSet - 1 || i >= count - 1) {
				return "normal-date normal-date--exceeds";
			}; 
			if ((dateAsMoment.isBetween(selectedStartDate, selectedEndDate)) && !(i < offSet - 1 || i >= count - 1)) {
				return "between-date";
			};					
			if (selectedStartDate === date && !(i < offSet - 1 || i >= count - 1)) {
				return "selected-start";
			};
			if (selectedEndDate === date && !(i < offSet - 1 || i >= count - 1)) {
				return "selected-end";
			};
			return "normal-date";
		};

		while (i <= count || day !== 1) {
			startDay.add(1, 'day')
			if (day !== 7) {
				let value = `${moment(startDay).format("YYYY/MM/DD")}`
				days.push(
					<td
						onClick={() => datePickHandler(value)}
						className={classSelector(value)}
					>
						<span className="date-picker-date-background">
							{startDay.format('DD')}
						</span>
					</td>
				);
				day++;

			} else {
				let value = `${moment(startDay).format("YYYY/MM/DD")}`
				days.push(
					<td
						className={classSelector(`${moment(startDay).format("YYYY/MM/DD")}`)}
						onClick={() => datePickHandler(value)}
					>
						<span className="date-picker-date-background">{startDay.format('DD')}</span>
					</td>
				);
				rows.push(
					<tr className="date-picker-table-tr">{days.map((ele) => { return ele })}</tr>
				);
				day = 1;
				days = [];
			};
			i++;
		};
		return (
			// <React.Fragment>
			rows)
		// </React.Fragment>)
	};

	const DateRangeDropDown = () => {
		const presetDates = ['Last 48 hours', 'Last week', 'Last month'];

		const dropdownSlected = (input) => {
			switch (input) {
				case 'Last 48 hours':
					setSelectedRange(input);
					setSelectedEndDate(moment().subtract(1, 'day').format("YYYY/MM/DD"))
					setSelectedStartDate(moment().subtract(2, 'day').format("YYYY/MM/DD"))
					break;
				case 'Last week':
					setSelectedRange(input);
					setSelectedEndDate(moment().subtract(1, 'day').format("YYYY/MM/DD"))
					setSelectedStartDate(moment().subtract(8, 'day').format("YYYY/MM/DD"))
					break;
				case 'Last month':
					setSelectedRange(input);
					setSelectedEndDate(moment().subtract(1, 'day').format("YYYY/MM/DD"))
					setSelectedStartDate(moment().subtract(1, 'month').format("YYYY/MM/DD"))
					break;
				default:
			}
		}

		return (
			<div className={displayDropDown === false ? 'date-picker-dropdown' : 'date-picker-dropdown date-picker-dropdown--active'} >
				<div className='date-drop-down-option-selected' onClick={() => { setDisplayDropDown(!displayDropDown) }}><span>{selectedRange}</span> {displayDropDown ? <UpArrow /> : <DownArrow />}</div>
				{
					presetDates.map((val) => {
						if (displayDropDown) {
							return (
								<div key={val} className='date-drop-down-option' onClick={() => { dropdownSlected(val); setDisplayDropDown(!displayDropDown) }}><span>{val}</span></div>
							);
						};
					})
				}
			</div>
		);
	};

	const finishFunction = () => {
		let stringToSend = `${moment(selectedStartDate).format("Do MMM YY")}-${moment(selectedEndDate).format("Do MMM YY")}`
		if (!(stringToSend.includes("Invalid"))) {
			props.setValue({
				...props.value,
				timeRange: stringToSend,
			});
		};
		props.setDisplayModal(false);
	};

	return (
		<div className="background-shader" ref={ref} onClick={(e) => { if (ref.current === e.target) { props.setDisplayModal(false); } }}>
			<div
				className="calendarBody"
			>
				<div
					className="table-area">
					<div className="unknown">
						<div className="date-table-month-title">
							<button className="change-months-button" onClick={() => { setCurrentSelectedMonth(moment(currentSelectedMonth).subtract(1, 'month')) }}><LeftArrow /></button>
							<span >{`${lastMonth.format('MMM YYYY')}`}</span>
							<div></div>
						</div>
						<table className="date-tables" cellspacing="0"  >
							<tr className="date-picker-table-tr">
								<th className="date-picker-table-th">Mo</th>
								<th className="date-picker-table-th">Tu</th>
								<th className="date-picker-table-th">We</th>
								<th className="date-picker-table-th">Th</th>
								<th className="date-picker-table-th">Fr</th>
								<th className="date-picker-table-th">Sa</th>
								<th className="date-picker-table-th">Su</th>
							</tr>
							{TableRowGenerator(lastMonth)}
						</table>
					</div>
					<div className="unknown">
						<div className="date-table-month-title">
							<div></div>
							<span>{`${currentSelectedMonth.format('MMM YYYY')}`}</span>
							<button className="change-months-button" onClick={() => { setCurrentSelectedMonth(moment(currentSelectedMonth).add(1, 'month')) }}><RightArrow /></button>
						</div>
						<table className="date-tables" cellspacing="0" >
							<tr >
								<th className="date-picker-table-th">Mo</th>
								<th className="date-picker-table-th">Tu</th>
								<th className="date-picker-table-th">We</th>
								<th className="date-picker-table-th">Th</th>
								<th className="date-picker-table-th">Fr</th>
								<th className="date-picker-table-th">Sa</th>
								<th className="date-picker-table-th">Su</th>
							</tr>
							{TableRowGenerator(currentSelectedMonth)}
						</table>
					</div>
					{/* <div className="date-picker-sidebar">
						<button className="date-picker-cancel" onClick={()=>{props.setDisplayModal(false);}}></button>
						<div className="date-picker-sidebar-form">
							<div className="date-picker-inputs-container">
								<div className="date-picker-dropdown-area">
									<label className="date-picker-date-label">Date range</label>
									<div className="dropdown-container">
										<DateRangeDropDown />
									</div>
								</div>
								<div className="date-picker-input-area">
									<form className="date-picker-input-container" onSubmit={testfunction}>
										<label className="date-picker-date-label" for="date-picker-start-date-input">From</label>
										<input className="date-picker-date-input" type="text" id="date-picker-start-date-input" pattern="[0-9]{4}/[0-9]{2}/[0-9]{2}" name="date-picker-start-date-input" onBlur={() => setDateTextFields({ startDate: selectedStartDate, endDate: selectedEndDate })} onInput={(e) => { setDateTextFields({ ...dateTextFields, startDate: e.target.value }) }} value={dateTextFields.startDate} placeholder="yyyy/mm/dd" />
									</form>
									<form className="date-picker-input-container" onSubmit={testfunction}>
										<label className="date-picker-date-label" for="date-picker-end-date-input">To</label>
										<input className="date-picker-date-input" type="text" id="date-picker-end-date-input" pattern="[0-9]{4}/[0-9]{2}/[0-9]{2}" name="date-picker-end-date-input" onBlur={() => setDateTextFields({ startDate: selectedStartDate, endDate: selectedEndDate })} onInput={(e) => { setDateTextFields({ ...dateTextFields, endDate: e.target.value }) }} value={dateTextFields.endDate} placeholder={"yyyy/mm/dd"} />
									</form>
								</div>
							</div>

							<button className="date-picker-confirm" onClick={finishFunction}>Save</button>
						</div>
					</div> */}

				</div>
				<div className="date-picker-button-container">
					<button className="date-picker-confirm" onClick={finishFunction}>Save</button>
				</div>
			</div>
		</div>
	);
};

export default Calender;