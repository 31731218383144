import React from "react";
import "./FalsePositive.scss";

const FalsePositive = () => {
	return (
		<div className="fasle-positive-section">
			<h3>What’s a false positive?</h3>
			<p>
			A false positive is when a bot detection engine, such as ours, flags a human user as a bot due to unusual or suspicious behaviours. False positives have been a real problem for other bot-identification products, but our patented, AI-powered solution has reduced this dramatically.
			</p>
		</div>
	);
};

export default FalsePositive;