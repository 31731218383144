export const Open = () => (
    <svg width="33" height="33" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 9.41626C9.83378 9.41626 5.16626 16.75 5.16626 16.75C5.16626 16.75 8.56626 24.0838 16.5 24.0838C24.5 24.0838 27.8338 16.75 27.8338 16.75C27.8338 16.75 23.2338 9.41626 16.5 9.41626ZM16.5 21.4163C13.9 21.4163 11.8338 19.35 11.8338 16.75C11.8338 14.15 13.9 12.0838 16.5 12.0838C19.1 12.0838 21.1663 14.15 21.1663 16.75C21.1663 19.35 19.1 21.4163 16.5 21.4163Z" fill="#5A5A5A"/>
    <path d="M18.5 16.75C18.5 17.855 17.605 18.75 16.5 18.75C15.395 18.75 14.5 17.855 14.5 16.75C14.5 15.645 15.395 14.75 16.5 14.75C17.605 14.75 18.5 15.645 18.5 16.75Z" fill="#5A5A5A"/>
    </svg>
);


export const Closed = () => (
    <svg width="28.5" height="33" viewBox="0 0 28.5 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.60534 18.4081C2.21481 18.7986 2.21481 19.4318 2.60534 19.8223C2.99586 20.2129 3.62903 20.2129 4.01955 19.8223L18.2501 5.59181C18.6406 5.20129 18.6406 4.56812 18.2501 4.1776C17.8596 3.78707 17.2264 3.78707 16.8359 4.1776L15.6358 5.37762C14.5148 4.9383 13.297 4.66623 12 4.66623C5.33378 4.66623 0.66626 12 0.66626 12C0.66626 12 1.83595 14.523 4.40412 16.6093L2.60534 18.4081ZM7.55973 13.4537L13.4538 7.5597C12.9973 7.4129 12.5089 7.33375 12 7.33375C9.40002 7.33375 7.33378 9.39999 7.33378 12C7.33378 12.5088 7.41292 12.9973 7.55973 13.4537ZM12 19.3338C10.4897 19.3338 9.14375 19.068 7.95423 18.6377L10.2558 16.3361C10.7932 16.5493 11.3815 16.6662 12 16.6662C14.6 16.6662 16.6663 14.6 16.6663 12C16.6663 11.3815 16.5493 10.7932 16.3361 10.2558L19.1496 7.44231C21.7772 9.51839 23.3338 12 23.3338 12C23.3338 12 20 19.3338 12 19.3338Z" fill="#5A5A5A"/>
</svg>

);
