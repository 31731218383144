import React from "react";
import BotIntentions from "./BotRiskComponents/BotIntentions";
import BotType from "./BotRiskComponents/BotType";
import RiskMeasurement from "./BotRiskComponents/RiskMeasurement";
import "./BotRisk.scss";
import { useSelector } from "react-redux";
import { botClassificationName, classificationColors } from "components/AFP/Constants";
const BotRisk = () => {
	const websiteRiskData = useSelector(state => state.todayBotRiskDetails?.data?.todayWebsiteRiskType);
	const doubleBreakdown = useSelector((state) => state.todayBotRiskDetails?.data?.todayBotClassification);
	const botRiskType = useSelector((state) => state.todayBotRiskDetails.data?.todayBotRiskType)
	const botClassification = useSelector((state) => state.botTypeClassification?.data?.botTypeClassification);
	const websiteStatus = useSelector((state) => state.websiteDetails?.data?.websiteDetails?.status);

	function getTickSize() {
		const values = Object.keys(websiteRiskData?.item).length ?? 0;
		if (values > 20) {
			return 15
		} else {
			return values
		}
	}


	const formatBotTypeData = () => {
		let botTypeTotals = {};
		for (let dates in doubleBreakdown.item) {
			for (let id in doubleBreakdown.item[dates].bot) {
				if (id !== "" && id != 0) {
					if (botTypeTotals[id] === undefined) {
						botTypeTotals[id] = {
							id: parseInt(id),
							label: botClassificationName[id],
							value: doubleBreakdown?.item[dates]?.bot[id],
							color: classificationColors[botClassificationName[id]]
						}
					} else {
						botTypeTotals[id].value += doubleBreakdown?.item[dates]?.bot[id];
					}
				}

			}
		};
		return Object.values(botTypeTotals).sort(function (a, b) {
			return b.value - a.value;
		});
	};

	const formattedBotTypeData = formatBotTypeData();

	return (
		<div className="bot-risk-section">
			<RiskMeasurement websiteRiskData={websiteRiskData} tickValues={getTickSize()} title={websiteStatus === "protect" ? "Mitigated Threat Level" : "Threat Level"} />
			<BotType formattedData={formattedBotTypeData} title={websiteStatus === "protect" ? "Blocked Bot Types" : "Bot Types"} />
			<BotIntentions botRiskType={botRiskType} botClassification={botClassification} formattedBotTypeData={formattedBotTypeData} title={websiteStatus === "protect" ? "Mitigated Threat Breakdown" : "Threat Breakdown"} />
		</div>
	)
};

export default BotRisk;