import api from "../../../../veracityapi";
import { todayActiveSessions } from "../../../../Redux/actions";
import moment from "moment";

export default function Data(params, dispatch) {
	const websiteGUID = localStorage.getItem('selected_site');
	const timeZone = localStorage.getItem('timezone');
	const getActiveSessionsData = new Promise((resolve, reject) => {
		let currentTimestamp = new Date().getTime();
		api.HEAD(`/session/website/${websiteGUID}/bot/0/${currentTimestamp}`, {finished:"false"}).then(res => {
			resolve({
				sessionBots: res
			});		
		}).catch((error) => {
			api.handleError(error);
		});
	});

	const getTodaysBots = new Promise((resolve, reject) => {
		api.HEAD(`/analytics/blockedCount/website/${websiteGUID}/${moment().tz(timeZone).startOf('day').unix()}/${moment().tz(timeZone).endOf('day').unix()}`).then(res => {
			resolve({
				blockedTodaysBots: res
			});		
		}).catch((error) => {
			api.handleError(error);
		});
	});

	const getBotsVisitedPagesCount = new Promise((resolve, reject) => {
		api.GET(`/analytics/website/${websiteGUID}/bots/pages_count`)
			.then(res => {
				resolve({
					botsVisited: res.item
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getActivePages = new Promise((resolve, reject) => {
		api.GET(`/analytics/website/${websiteGUID}/activePages/bot`,{limit:19})
			.then(res => {
				resolve({
					activePages: res.item
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	return new Promise((resolve, reject) => {
		Promise.all([ getBotsVisitedPagesCount,getActivePages,getActiveSessionsData,getTodaysBots]).then(async (values) => {
			const temp = {
			};
			for (let obj of values) {
				Object.assign(temp, obj);
			};
			
			dispatch(todayActiveSessions(temp));
			resolve(true);
		});
	});
};