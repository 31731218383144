import React from "react";
import "./BotsPercentage.scss";
import Loader from "SharedComponents/Loader/Loader";
import TrafficMessage from "SharedComponents/TrafficMessage/TrafficMessage";
import { formatNumber } from "Utils/utils";

const BotsPercentage = (props) => {

    return (
        <div className="bots-percentage-container">
            {props.loading ? <Loader /> :
                <div>
                    {props.total !== 0 ?
                        <div className="bots-percentage-content">
                            <div className="bots-percentage-value">{props.botPercentage}%</div>
                            <div className="bots-values">{props.botsString}: <b>{formatNumber(props.bots)}</b></div>
                        </div> :
                        <TrafficMessage message="There has been no traffic in the selected time period." />
                    }
                </div>
            }
        </div>
    )
};

export default BotsPercentage;