import React from "react";
import { useSelector } from "react-redux";
import AffiliatesTile from "./AffiliatesComponents/AffiliatesTile"
import "./Affiliates.scss";
import api from "../../veracityapi";
// import ActiveCampaignPanel from "../../ActiveCampaigns/ActiveCampaignPanel";


const Affiliates = () => {
	const sidetoggle = useSelector(state => (state.toggle.toggle));
	const [loading, setLoading] = React.useState(false);

	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};
	
	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	return (
		<div className="affiliates-container">
			<div className={`display-area ${sidetoggle ? "display-area--small" : "display-area--large"}`}>
				<AffiliatesTile graph="Affiliate Overview" loading={loading} setLoading={setLoading} />
			</div>
		</div>
	)
};

export default Affiliates;