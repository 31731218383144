import React from "react";
import { useSelector } from "react-redux";
import Pagination from "../../../../SharedComponents/Pagination/Pagination";
import BotDetailsData from "../BotDetails/BotDetailsData";
import { utcTimeStringToLocalTimeString } from "SharedComponents/DateManager"
import { useDispatch } from "react-redux";
import TrafficMessage from "../../../../SharedComponents/TrafficMessage/TrafficMessage";
import { botClassificationName, riskLevels, colors } from "components/AFP/Constants";
import { utcTimeStringToFrontDisplay } from "Utils/utils";

const BotDetails = () => {
	const dispatch = useDispatch();
	const botDetails = useSelector(state => state?.botDetails.data);
	const headers = ["Last Seen", "IP Address", "Country", "Referrer", "OS", "Landing Page", "Page Loads", "Bot Type", "Bot Risk"];
	let itemsPerPage = 5;
	const [page, setPage] = React.useState(1);
	const timezone = localStorage.getItem("timezone");

	React.useEffect(() => {
		BotDetailsData({ timeRange: botDetails.timeRange }, dispatch, page);
	}, [page]);
	console.log(botDetails.items);
	return (
		<>
			<div className="table" style={{ background: "white", position: "relative", minHeight: "30vh" }}>
				<div className="lheader table-row" style={{ height: "2vh" }}>
					{headers.map((items) =>
						<div className="cell">{items}</div>
					)
					}
				</div>
				{botDetails.items.length !== 0 ?
					<div className="tbody" style={{ whiteSpace: "nowrap" }}>
						{
							botDetails.items?.map((items) =>
								<div className="table-row" key={items.active_id}>
									<div className="cell" title={utcTimeStringToLocalTimeString(items.last_seen, "", "HH:mm:ss")} style={{ whiteSpace: "normal" }}>{utcTimeStringToLocalTimeString(items.last_seen, "", "HH:mm:ss")}</div>
									<div className="cell" title={items.ip}>{items.ip}</div>
									<div className="cell" title={items.country}>{items.country}</div>
									<div className="cell" title={items.referrer}>{items.referrer}</div>
									<div className="cell" title={items.os}>{items.os}</div>
									<div className="cell" title={items.landing_page}>{items.landing_page}</div>
									<div className="cell" title={items.page_loads}>{items.page_loads}</div>
									<div className="cell" title={botClassificationName[items.bot_classification_id]}>{botClassificationName[items.bot_classification_id]}</div>
									<div className="bot_risk_cell" style={{ backgroundColor: colors[items.bot_risk_type_id] }} title={riskLevels[items.bot_risk_type_id]}>{riskLevels[items.bot_risk_type_id]}</div>
								</div>

							)
						}
					</div> :

					<TrafficMessage message="There are no bots for this time Period." />
				}
				{botDetails.paging.total > 5 &&
					<Pagination
						paging={botDetails.paging}
						currentPage={page}
						setCurrentPage={setPage}
						pageLimit={itemsPerPage}
					/>
				}
			</div>

		</>
	)
};


export default BotDetails;