import React from "react";
import "./Help-Security.scss";

const HelpSecurity = () => {
	return (
		<div className="security-section">
			<h3>What Can Malicious Bots Really Do?</h3>
			<p>
			Bot attacks can have wide-ranging goals. From scraping your content to create duplicate sites to trick customers, to attempting to break into your site to steal data or access connected systems.
			</p>
		</div>
	);
};

export default HelpSecurity;