export const MaintenancePageImage = () => (
    <svg width="40vw" height="45vh" viewBox="0 0 461 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="216.049" cy="211.326" rx="210.833" ry="210.833" fill="#D1C0DE" />
        <ellipse cx="230.442" cy="541.901" rx="230" ry="57.5" fill="#E9E0EF" />
        <rect x="216.329" y="64.017" width="128.866" height="128.866" fill="#E9E0EF" />
        <path d="M378.593 40.343L345.079 64.1903V192.883L378.593 163.691V40.343Z" fill="#392E42" />
        <path d="M270.532 40.2268H378.666L345.182 64.2632H216.049L270.532 40.2268Z" fill="#662D91" />
        <rect width="32.2165" height="219.072" rx="16.1083" transform="matrix(-0.991416 -0.130747 -0.130747 0.991416 238.876 222.39)" fill="#9D79B9" />
        <rect width="32.2165" height="219.072" rx="16.1083" transform="matrix(-1 0 0 1 277.044 318.33)" fill="#392E42" />
        <rect width="32.2165" height="219.072" rx="16.1083" transform="matrix(-1 0 0 1 344.125 318.33)" fill="#211728" />
        <path d="M199.725 542.568C199.725 526 213.156 512.568 229.725 512.568H277.044V544.785H199.725V542.568Z" fill="#9D79B9" />
        <path d="M266.805 542.568C266.805 526 280.236 512.568 296.805 512.568H344.125V544.785H266.805V542.568Z" fill="#662D91" />
        <rect x="216.329" y="216.673" width="128.866" height="128.866" fill="#E9E0EF" />
        <path d="M378.593 192.999L345.079 216.847V345.539L378.593 316.347V192.999Z" fill="#392E42" />
        <path d="M270.532 192.883H378.666L345.182 216.92H216.049L270.532 192.883Z" fill="#662D91" />
        <ellipse cx="252.286" cy="112.342" rx="16.1083" ry="16.1083" fill="#392E42" />
        <ellipse cx="249.064" cy="109.12" rx="3.22165" ry="3.22165" fill="#E9E0EF" />
        <ellipse cx="309.239" cy="112.342" rx="16.1083" ry="16.1083" fill="#392E42" />
        <ellipse cx="306.017" cy="109.12" rx="3.22165" ry="3.22165" fill="#E9E0EF" />
        <path d="M248.546 155.981C248.546 155.01 249.332 154.223 250.303 154.223H311.221C312.192 154.223 312.979 155.01 312.979 155.981C312.979 165.686 305.111 173.553 295.406 173.553H266.118C256.413 173.553 248.546 165.686 248.546 155.981Z" fill="#662D91" />
        <rect x="352.474" y="230.12" width="32.2165" height="158.442" rx="16.1083" transform="rotate(-25 352.474 230.12)" fill="#662D91" />
        <rect width="32.2165" height="123.711" rx="16.1083" transform="matrix(-0.34202 -0.939693 -0.939693 0.34202 448.652 365.557)" fill="#662D91" />
        <path d="M144.944 218.177H167.944V252.677C167.944 259.029 162.795 264.177 156.444 264.177C150.093 264.177 144.944 259.029 144.944 252.677V218.177Z" fill="#F4F4F4" />
        <path d="M144.944 218.177H167.944V252.677C167.944 259.029 162.795 264.177 156.444 264.177C150.093 264.177 144.944 259.029 144.944 252.677V218.177Z" fill="#D8D8D8" fill-opacity="0.25" />
        <circle cx="159.792" cy="183.677" r="38.3333" fill="#F4F4F4" />
        <path d="M140.626 183.702H198.126V224.773C198.126 233.847 190.77 241.202 181.697 241.202C159.014 241.202 140.626 222.814 140.626 200.131V183.702Z" fill="#F4F4F4" />
        <path d="M201.806 176.26C201.169 168.792 198.553 161.629 194.228 155.508C189.902 149.387 184.023 144.53 177.196 141.437C170.369 138.343 162.841 137.125 155.387 137.908C147.933 138.691 140.822 141.447 134.787 145.891C128.752 150.336 124.011 156.309 121.052 163.195C118.092 170.081 117.022 177.632 117.951 185.069C118.879 192.506 121.774 199.562 126.335 205.509C130.897 211.455 136.962 216.079 143.905 218.903L159.792 179.844L201.806 176.26Z" fill="#1F1F1F" />
        <path d="M184.674 182.618H194.641C195.488 182.618 196.174 183.304 196.174 184.151H186.208C185.361 184.151 184.674 183.465 184.674 182.618Z" fill="#1F1F1F" />
        <path d="M166.794 184.151H176.76C177.607 184.151 178.294 183.465 178.294 182.618H168.327C167.48 182.618 166.794 183.304 166.794 184.151Z" fill="#1F1F1F" />
        <rect x="118.766" y="413.507" width="23" height="94.3" rx="11.5" transform="rotate(157.902 118.766 413.507)" fill="#F4F4F4" />
        <rect width="23" height="94.3" rx="11.5" transform="matrix(0.865152 0.50151 0.50151 -0.865152 60.916 344.974)" fill="#F4F4F4" />
        <rect x="316.934" y="292.798" width="23" height="94.3" rx="11.5" transform="rotate(58.2056 316.934 292.798)" fill="#F4F4F4" />
        <rect width="23" height="94.3" rx="11.5" transform="matrix(0.790841 -0.612022 -0.612022 -0.790841 250.204 362.031)" fill="#F4F4F4" />
        <path d="M120.042 249.256C120.042 246.495 122.28 244.256 125.042 244.256H191.708C194.47 244.256 196.708 246.495 196.708 249.256V366.923H120.042V249.256Z" fill="#1F1F1F" />
        <rect x="136.406" y="190.216" width="11.5" height="23" rx="5.75" transform="rotate(-15.0446 136.406 190.216)" fill="#F4F4F4" />
        <path d="M119.101 247.578C120.935 245.193 124.747 246.691 124.466 249.686L120.226 294.955C120.039 296.943 118 298.198 116.141 297.467L92.6682 288.243C90.81 287.513 90.1702 285.205 91.3872 283.622L119.101 247.578Z" fill="#1F1F1F" />
        <path d="M197.324 247.578C195.491 245.193 191.679 246.691 191.959 249.686L196.2 294.955C196.386 296.943 198.426 298.198 200.284 297.467L223.757 288.243C225.615 287.513 226.255 285.205 225.038 283.622L197.324 247.578Z" fill="#1F1F1F" />
        <path d="M120.042 366.923H196.708V397.59H120.042V366.923Z" fill="#5A5A5A" />
        <rect x="169.875" y="381.445" width="26.8333" height="153.333" fill="#5A5A5A" />
        <rect x="120.042" y="381.445" width="26.8333" height="153.333" fill="#5A5A5A" />
        <path d="M169.702 530.401H218.702C229.748 530.401 238.702 539.356 238.702 550.401V553.401H169.702V530.401Z" fill="#392E42" />
        <path d="M120.042 530.401H169.042C180.087 530.401 189.042 539.356 189.042 550.401V553.401H120.042V530.401Z" fill="#211728" />
        <ellipse cx="172.544" cy="191.831" rx="4.6" ry="4.6" fill="#1F1F1F" />
        <ellipse cx="191.574" cy="191.831" rx="4.6" ry="4.6" fill="#1F1F1F" />
        <path d="M180.539 194.659C180.539 192.329 183.85 191.882 184.468 194.129L190.279 215.261C190.624 216.515 189.698 217.76 188.397 217.79L182.586 217.926C181.463 217.952 180.539 217.049 180.539 215.926V194.659Z" fill="#D8D8D8" fill-opacity="0.5" />
        <path d="M169.526 225.569L192.256 229.084C191.868 231.595 189.518 233.316 187.007 232.927L173.369 230.818C170.858 230.43 169.138 228.08 169.526 225.569Z" fill="#1F1F1F" />
        <path d="M169.877 225.841L191.85 229.239L191.674 230.375L169.702 226.977L169.877 225.841Z" fill="white" />
        <path d="M170.83 228.917L189.772 231.847C189.675 232.474 189.087 232.904 188.459 232.807L171.791 230.229C171.163 230.132 170.733 229.545 170.83 228.917Z" fill="white" />
        <path d="M191.964 229.292C191.486 231.532 189.336 233.035 187.045 232.68L173.407 230.571C171.116 230.217 169.521 228.134 169.742 225.855L191.964 229.292Z" stroke="#1F1F1F" stroke-width="0.5" />
        <path d="M323.329 233.061L330.584 238.594L329.189 247.03L320.539 249.933L313.284 244.4L314.678 235.964L323.329 233.061Z" fill="#662D91" />
        <path d="M308.946 221.249L314.622 236.339L298.716 233.709L308.946 221.249Z" fill="#D1C0DE" />
        <path d="M313.283 244.233L303.132 256.757L297.361 241.704L313.283 244.233Z" fill="#D1C0DE" />
        <path d="M320.742 249.819L311.265 260.272L303.027 256.672L313.263 244.275L320.742 249.819Z" fill="#D1C0DE" />
        <path d="M320.423 249.766L326.03 262.714L334.989 261.956L329.288 246.925L320.423 249.766Z" fill="#D1C0DE" />
        <path d="M345.088 249.492L334.937 262.016L329.166 246.963L345.088 249.492Z" fill="#D1C0DE" />
        <path d="M340.748 226.507L346.423 241.597L330.518 238.967L340.748 226.507Z" fill="#D1C0DE" />
        <path d="M320.54 249.933L326.274 262.8L310.969 260.27L320.54 249.933Z" fill="#D1C0DE" />
        <rect x="298.701" y="233.682" width="16.155" height="8.15739" transform="rotate(9.38825 298.701 233.682)" fill="#D1C0DE" />
        <rect x="330.505" y="238.94" width="16.155" height="8.15739" transform="rotate(9.38825 330.505 238.94)" fill="#D1C0DE" />
        <path d="M311.443 260.016L325.906 262.407L314.477 331.533C313.817 335.527 310.044 338.229 306.05 337.569C302.056 336.908 299.354 333.135 300.014 329.141L311.443 260.016Z" fill="#D1C0DE" />
    </svg>

)