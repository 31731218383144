import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as reducers from "./reducer";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
	key: 'root',
	storage,
};

const appReducer = combineReducers({
	toggle: reducers.toggleReducer,
	AFPMenuToggle: reducers.AFPMenuToggleReducer,
	submenu: reducers.submenuToggleReducer,
	finishedSessions: reducers.finishedSessionsReducer,
	sessionGeography: reducers.sessionGeographyReducer,
	blockedSessionsGeography: reducers.blockedSessionsGeographyReducer,
	todayCurrentlyActiveSessions: reducers.todayCurrentlyActiveReducer,
	todayFinishedSessions: reducers.todayFinishedSessionsReducer,
	websiteInsights: reducers.websiteInsightsReducer,
	dashboardSelectedGraphs: reducers.dashboardSelectedGraphsReducer,
	evaluationStatus: reducers.evaluationStatusReducer,
	websiteDetails: reducers.websiteDataReducer,
	websitePerformance: reducers.websitePerformanceReducer,
	websiteCO2: reducers.websiteCO2Reducer,
	websitesList: reducers.websiteListReducer,
	baselineData: reducers.BaselineReducer,
	currentData: reducers.CurrentReducer,
	humanActivity: reducers.humanActivityReducer,
	todaySessionsInfo: reducers.todaySessionsInfoReducer,
	blockedSessionsInfo: reducers.blockedSessionsInfoReducer,
	availableAdNetworks: reducers.availableAdNetworkReducer,
	sessionAffiliates: reducers.sessionAffiliatesReducer,
	botDetails: reducers.botDetails,
	users: reducers.usersDetails,
	loadData:reducers.loadDataReducer,
	todayBotRiskDetails:reducers.botRiskDetailsReducer,
	botTypeClassification:reducers.botTypeClassificationsReducer,
	botActivityBotRiskDetails:reducers.botRiskDetailsForBotActivityReducer
});

// reset the state of a redux store
const rootReducer = (state, action) => {
	if (action.type === 'CLEAR_STORE') {
		state = undefined;
	}
	return appReducer(state, action)
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
});

// store.subscribe(()=>store.getState())
export const persistor = persistStore(store)
