import React from "react";
import "./InvoiceHistory.scss";
import Pagination from "../../../SharedComponents/Pagination/Pagination";
import moment from "moment-timezone";
import { store } from "../../../Redux/store";
import { useSelector } from "react-redux";

const InvoiceHistory = () => {
	const [currentPage, setCurrentPage] = React.useState(1);
	const invoices = store.getState().websiteDetails.data?.customerInvoices;
	const paymentMethods=useSelector(state=>state.websiteDetails.data.paymentMethods);
	const formatData = invoices?.sort((a, b) => moment(b.date) - moment(a.date));
	const userData = {
		total: formatData?.length
	};

	const pageLimit = 3;
	const startIndex = (currentPage - 1) * pageLimit;
	const endIndex = startIndex + pageLimit;
	const inVoiceData = formatData.slice(startIndex, endIndex);

	return (
		<div className={paymentMethods.stripe_customer_exists?"invoice-history":"invoice-history-noPaymentMethods"} >
			<h3>Invoices</h3>
			{inVoiceData.length !== 0 ? <>{
				inVoiceData.map((items) =>
					<div className="invoice-content">
						<div className="invoice-details">
							<b>{items.stripe_invoice_number}</b>
							<p>{moment(items.date)?.format("DD/MM/YY")}</p>
						</div>
						<div>
							Total: £{items.cost_inc_vat}
							<a href={items.url}>
								<button className="download-button">
									Download
								</button>
							</a>
						</div>
					</div>
				)
			}
				{formatData.length>3 &&< Pagination usersData={userData} currentPage={currentPage} setCurrentPage={setCurrentPage} pageLimit={pageLimit} />}
			</> :
				<p style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"10%"}}> Currently no Invoices are there. </p>
			}





		</div>
	)
};
export default InvoiceHistory;
