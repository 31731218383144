import {AdFraudIcon, TodayIcon, AnalyticsIcon, BotActivityIcon, OverviewIcon, CurrentIcon, BaslineIcon, AffiliatesIcon, ReportsIcon, ProtectionIcon, ProtectionTabIcon, AdFraudTabIcon } from "./icons";
export const MenuData = [
    {
        id: 4,
        title: "Web Threat",
        icon: <ProtectionTabIcon />,
        children: [{
            id: 1,
            title: 'Today',
            path: '/today',
            icon: <TodayIcon />

        },
        {
            id: 2,
            title: 'Bot Activity',
            path: '/bot_activity',
            icon: <BotActivityIcon />

        },
        {
            id: 3,
            title: 'Performance',
            path: '/performance',
            icon: <AnalyticsIcon />

        }]
    },
    {
        id: 4,
        title: "Ad Fraud",
        icon: <AdFraudIcon />,
        children: [
            // {
            //     id: 1,
            //     title: "Overview",
            //     path: "/afp/overview",
            //     icon: <OverviewIcon />,

            // },
            {
                id: 1,
                title: "Baseline",
                path: "/afp/baseline",
                icon: <BaslineIcon />,

            },
            {
                id: 2,
                title: "Current",
                path: "/afp/current",
                icon: <CurrentIcon />,

            }
        ]
    },
    {
        id: 4,
        title: "Affiliates",
        icon: <AffiliatesIcon />,
        children: [
            {
                id: 1,
                title: "Affiliates",
                path: "/afp/affiliates",
                icon: <AffiliatesIcon />,

            }
        ]
    }
]