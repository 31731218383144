import React from "react";
import ReactDOM from "react-dom";
import Dragger from "../../../SharedComponents/Dragger/Dragger";
import DateDropDown from "../../DateDropDown/DateDropDown";
import "./Tile.scss";
import AdnetworkPicker from "../../../components/AFP/AdnetworkPicker/AdnetworkPicker";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import bApi from "../../../API/BeaconAPI";
import { Adnetworks } from "../../../components/AFP/Constants";
const Tile = ({ error = null, dragged, dispatchEvent, adNetworks, children, graph, setTimeRange, timeRange, DropFunc, DragFunc, DisplayDashboard, enableDateFilter, supportLink }) => {
    const { pathname } = useLocation();
    const aPath = pathname.split("/");
    const isDrilldownTile = aPath.includes("link") ||  aPath.includes("campaign");
    const dateDropDownValues = ['Prev 7 Days', 'Prev 14 Days', 'Prev 28 Days', 'This Month', 'Last Month'];
    const parentElm = React.useRef(null);
    const dispatch = useDispatch();
    const adPlatformData = useSelector(state => state.availableAdNetworks);



    const getSupportLink = () => {
        if (supportLink === "Website Settings") {
            return (
                <Link to="/manage/website"><button className="support-link">Website Settings</button></Link>
            )
        } else if (supportLink === "Contact Support") {
            return (
                <Link to="https://go.veracitytrustnetwork.com/contact-support/" target="_blank"> <button className="support-link">Contact Support</button></Link>
            )
        }
    };

    const getAllAdPlatforms = () => {
        if (adNetworks.length == 0) {

            bApi.getAdplatforms().then(ap => {
                let platformList = [];
                ap.map(i => {
                    platformList.push(Adnetworks.filter(j => j.name === i[0])[0]);
                });

                dispatch({ type: "UPDATE_AVAILABLE_AD_NETWORKS", payload: platformList });
                //select first available network as default;
                dispatch({ type: dispatchEvent, payload: [platformList?.[0]?.id ?? 1] });

            }).catch(e => {

            })
        }
    };

    React.useEffect(() => {
        getAllAdPlatforms()
    }, []);

    return (
        <div
            className="graph-tile"
            style={{ width: '95%' }}
            ref={parentElm}
        >
            <div className="graph-tile-header">
                <h2>{graph}</h2>
                <div className="button-box">
                    {enableDateFilter &&
                        <>
                            {!isDrilldownTile && <div className="date-dropdown-container"><DateDropDown setValue={setTimeRange} value={timeRange} dateDropDownValues={dateDropDownValues} /></div>}
                            {/* <DashboardToggle tile={"Protection"} panel={graph} /> */}
                        </>
                    }
                    {!isDrilldownTile && <AdnetworkPicker adNetworks={adNetworks} dispatchEvent={dispatchEvent} adPlatformData={adPlatformData}></AdnetworkPicker>}
                </div>
            </div>
            {error == "" ? <div className="graph-body">
                {children}
            </div> :
                <>
                    <div className="error">{error}</div>
                    <div>{getSupportLink()}</div>
                </>
            }
        </div>
    )

}
export default Tile;