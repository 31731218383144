import React from "react";
import { LoginHead } from "../../SharedComponents/LoginProcess/LoginHead/LoginHead";
import LoginFoot from "../../SharedComponents/LoginProcess/LoginFoot/LoginFoot";
import { Closed, Open } from "../../SVG/EyeIcon";
import api from "../../veracityapi";
import "./Login.scss";
import { Navigate, json } from "react-router-dom";
import { SelectHead } from "../../SharedComponents/LoginProcess/LoginHead/LoginHead";
import Loading from "../../SharedComponents/LoadingScreen/Loading";
import MFAauth from "./LoginComponents/MFAAuth/MFAAuth";
import AppMaintenance from "./AppMaintenance/AppMaintenance";

const Login = () => {
	const [showPassword, setShowPassword] = React.useState(false);
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [status, setStatus] = React.useState([]);
	const [MFA, setMFA] = React.useState("");
	const [formStatus, setformStatus] = React.useState({
		loading: false,
		error: false
	});
	const [mfaRequired, setmfaRequired] = React.useState(false);

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "#662D91"
	})
	const handleShowPassword = () => {
		setShowPassword(!showPassword)
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (e.target.checkValidity()) {
			setformStatus({ loading: true });
			api.doLogin(email, password, MFA).then((resp) => {
				if (typeof resp.item.token !== "undefined") {
					localStorage.setItem("userGUID", resp.item.user_guid)
					localStorage.setItem("name", resp.item.first_name)
					localStorage.setItem("last_name", resp.item.last_name)
					localStorage.setItem("email", resp.item.email)
					localStorage.setItem("admin", resp.item.isAdmin)
					// localStorage.setItem("loginTimer", moment.utc().add(12,'hours').unix());
					setmfaRequired(false);
					setformStatus({ loading: false, error: false });
				} else {
					localStorage.setItem("mfeEnabled", true)
					setmfaRequired(true);
					setformStatus({ loading: false, error: false });
				}
			}).catch((err) => {
				console.log(err.response.data.error)
				setformStatus({ loading: false, error: err.response.data.error });
			})
		};
	};
	const getStatus = () => {
		fetch("https://api.platform.veracitytrustnetwork.com/status/").then(res => res.json()).then((res) => {
			setStatus(res.item);
			localStorage.setItem("version", JSON.stringify(res.item))
		});
	};

	React.useEffect(() => {
		getStatus();
	}, []);

	const cancelHandel = () => {
		api.logout();
	};

	if (status.Status === "Scheduled Maintenance") {
		return (
			<>
				<AppMaintenance status={status.MaintenanceMessage} />
			</>
		)
	};

	if (api.isUserLoggedIn()) {
		return (
			<Navigate to="/select" />
		);
	};


	if (!formStatus.loading) {
		if (!mfaRequired && status.Status !== "Scheduled Maintenance") {
			return (
				<div className="container">
					<LoginHead />
					<div className="login-form">
						<p className="login-text">Login to Veracity</p>
						{formStatus.error && (
							<div className="error-message">
								{(formStatus.error === "Login Invalid" && (
									<>
										<p>Email and Password do not match.</p>
										<p>After 3 attempts your account will be locked and you will have to reset your password.</p>
									</>
								)) || (formStatus.error === "Login Invalid MFA" && (
									<>
										<p>Email and Password do not match.</p>
									</>
								)) || (formStatus.error === "Account Locked" && (
									<>
										<p>You've entered an incorrect password too many times and your account has been locked.</p>
										<p>Reset your password using the link below, or by following the instructions we've emailed to you to unlock your account.</p>
									</>
								)) || (formStatus.error === "Email Unconfirmed" && (
									<>
										<p>We need to validate your email before you can login.</p>
										<p>Please check your emails for a confirmation link.</p>
									</>
								)) || (
										<>Something went wrong, logged out for safety.</>
									)}
							</div>
						)}
						<div>
							<form className="form" onSubmit={handleFormSubmit}>
								<div className="form-group">
									<label>Email*</label>
									<div className="input-with-icon">
										<input className="login-inputs"
											placeholder="Email"
											type="email"
											name="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											required />
									</div>
								</div>
								<div className="form-group">
									<label>Password<span className="required">*</span></label>
									<div className="input-with-icon">
										<input className="login-inputs"
											placeholder="Password"
											type={showPassword ? "text" : "password"}
											value={password}
											name="password"
											onChange={(e) => setPassword(e.target.value)}
											required />
										<span className="input-icon" onClick={handleShowPassword}>{showPassword ? <Open /> : <Closed />}</span>
									</div>
								</div>
								<div className="submit-button-parent">
									<button className="submit-button" type="submit">Login </button>
								</div>
							</form>
							<p className="forgotpassword-text"><a href="https://static.platform.veracitytrustnetwork.com/">Forgot your password?</a></p>
						</div>
					</div>
					<LoginFoot status={status} />
				</div>
			)
		} else if (mfaRequired && status.Status !== "Scheduled Maintenance") {
			return (
				<div className="container">
					<LoginHead />
					<div className="mfa-content">
						<p className="header">Multi-Factor Authentication </p>
						<div className="details-section">
							<div>Open the two-step verification app on your mobile device to get your verification code.</div>
						</div>
						<form
							className="form"
							onSubmit={handleFormSubmit}
						>
							<div className="input-with-icon">
								<input
									className="login-inputs"
									placeholder="Enter MFA code"
									type={showPassword ? "text" : "password"}
									name="mfainput"
									value={MFA}
									onChange={(e) => setMFA(e.target.value)}
									inputMode="numeric"
									minLength={6}
									maxLength={6}
									current-password
									pattern="[0-9]{6}"
									required
								/>
								<span className="input-icon" onClick={handleShowPassword}>{showPassword ? <Open /> : <Closed />}</span>
							</div>

							<div className="selection-buttons">
								<button className="cancel-mfa" type={"button"} onClick={cancelHandel}>Cancel</button>
								<button className="verify-mfa" type="submit" >Verify</button>
							</div>
						</form>
					</div>
					<LoginFoot status={status} />
				</div>
			);
		};
	} else {
		return (
			<>
				<SelectHead />
				<Loading />
				<LoginFoot status={status} />
			</>
		);
	};
};

export default Login;
