import React from "react";
import { useSelector } from "react-redux";
import ReferralSources from "../../../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyGraphs/ReferralSources";
import CountriesLineGraph from "../../../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyGraphs/CountriesLineGraph";
import BlockedBrowserLineGraph from "../../../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyGraphs/BlockedBrowserLineGraph";
import BlockedOSLineGraph from "../../../BotActivity/BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyGraphs/BlockedOSLineGraph";
import Loader from "../../../../SharedComponents/Loader/Loader";
import moment from "moment";

const HumanActivity = (props) => {

	const humanActivityData = useSelector(state => state?.humanActivity?.data);
	const calculateValues = (data = {}, countryArray = []) => {
		const calculatedData = {};
		for (const date in data) {
			const dateData = data[date] ?? {};

			countryArray?.map((item) => {
				const sum = (dateData?.human?.[item] || 0)

				if (!calculatedData[item]) {
					calculatedData[item] = [];
				}
				calculatedData[item].push({
					x: moment.utc(date, "YYYY-MM-DDTHH:mm.SSSZ").format("Do MMM"),
					y: sum,
				});
			});

		};
		return calculatedData
	};

	const formatData = (data) => {
		const values = Object.values(data)?.map((values) => {
			const newArray = []
			for (let keys in values) {
				newArray.push(Object.keys(values[keys]));
			}
			return newArray

		});
		const dataArray = Array.from(new Set([...values.flat(2)]));

		const temp = calculateValues(data, dataArray)

		const formattedData = dataArray?.filter((val) => {
			if (val == "total") {
				return false;
			}
			return true;
		}).map((val) => {
			return {
				id: val,
				data: temp[val]
			}
		})
		return formattedData
	};
	let humanBrowserData;
	let humanOSData;
	if (humanActivityData != undefined) {
		humanBrowserData = formatData(humanActivityData?.humanBrowsers);
		humanOSData = formatData(humanActivityData?.humanOS);
	};

	return (
		<div className="human-graph-area">
			<div className="countries-lineGraph"  >
				{props.loading ? <Loader /> : <CountriesLineGraph data={humanActivityData?.humansCountriesByDate?.item} />}
			</div>
			<div className="referral-lineGraph">
				{props.loading ? <Loader /> : <ReferralSources data={humanActivityData?.humansReferrersByDate?.item} />}
			</div>
			<div className="human-BrowserLineGraph" >
				{props.loading ? <Loader /> : <BlockedBrowserLineGraph data={humanBrowserData} />}
			</div>
			<div className="human-OSlineGraph">
				{props.loading ? <Loader /> : <BlockedOSLineGraph data={humanOSData} />}
			</div>
		</div>
	);
};

export default HumanActivity;