import { ResponsiveLine } from "@nivo/line";
import React from "react";
import moment from "moment";
import "./BumpGraph.scss";
import NoContent from "SharedComponents/NoContent/NoContent";

const BumpGraph = ({ data }) => {
	const [initialLinegraphState, setinitialLinegraphState] = React.useState({});

	const formatData = (data) => {
		let aHumans = [];
		let aBots = [];
		for (let key in data) {
			let clicks = data[key].clicks
			let visits = data[key].visits
			let bots = Math.max(0, clicks - visits);
			if ((typeof visits !== 'number' || typeof clicks !== "number")) continue;
			let unixTime = moment(key).unix();
			let bot_percentage = clicks == 0 ? 0 : bots / clicks;

			aHumans.push({
				x: unixTime,
				y: (1 - bot_percentage) * 100
			});
			aBots.push({
				x: unixTime,
				y: (bot_percentage) * 100
			});
		};
		return [aHumans, aBots, data]
	};


	const formattedData = formatData(data.graph);
	function getTickSize() {
		const values = formattedData[0]?.length ?? 0;
		if (values > 20) {
			return 15
		} else {
			return values
		}
	}
	function toolTipContent({ point }) {
		let d = Math.floor(point.data.x.getTime() / 1000);

		setinitialLinegraphState({
			x: moment(point.data.x).format('Do MMM'),
			y: point.data.y,
			metric: point.serieId
		});

		return (
			<div className="toolTipContent">
				On <b className="humansValues">{initialLinegraphState.x}</b>
				<div>{`${initialLinegraphState.metric}`} <b className="humansValues">{Math.round(initialLinegraphState.y)}%</b></div>
			</div>
		)
	};

	const ClickVisitsGraph = React.useMemo(() => {
		//	console.log(formattedData)
		let ClickVisitsGraphData = {
			data: [
				{ id: "humans", data: formattedData[0], rawdata: formattedData[2] },
				{ id: "bots", data: formattedData[1], rawdata: formattedData[2] }
			]
		};
		let colours = [
			"#4d79ff", "#ff4d4d"
		];
		const humanP = parseFloat((1 - data?.baseline?.bots / data?.baseline?.clicks) * 100).toFixed(1)
		return (
			<div style={{ width: '100%', height: '96%' }}>
				{!data?.graph?.push ?
					<ResponsiveLine
						data={ClickVisitsGraphData.data}
						isInteractive={true}
						areaBlendMode={"normal"}
						enableArea={true}
						areaOpacity={.6}
						margin={{ top: 30, right: 35, bottom: 100, left: 50 }}
						xScale={{
							type: "time",
							precision: "day",
							format: "%s"
						}}
						yScale={{
							type: 'linear',
							min: '0',
							max: 'auto',
							stacked: true,
							reverse: false
						}}
						layers={[
							'grid',
							'areas',
							'lines',
							'points',
							'markers',
							'slices',
							'axes',
							'mesh', 'crosshair', 'legends']}
						curve="monotoneX"
						tooltip={toolTipContent}
						colors={colours}
						yFormat=" >-.2f"
						axisTop={null}
						axisRight={null}
						axisBottom={{
							orient: 'bottom',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 45,
							legend: '',
							tickValues: getTickSize(),
							legendOffset: 36,
							legendPosition: 'middle',
							format: (value) => {
								return moment(value).format('Do MMM')

							}
						}}
						axisLeft={{
							orient: 'left',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: '',
							legendOffset: -40,
							legendPosition: 'middle',
							format: (value) => value % 2 === 0 ? value : ""
						}}

						pointSize={3}
						pointColor={'white'}
						pointBorderWidth={2}
						pointBorderColor={{ from: 'serieColor' }}
						pointLabelYOffset={-12}
						useMesh={true}
						markers={isNaN(humanP) ? [] : [

							{
								axis: 'y',
								legend: `Baseline Human Traffic  ${humanP}%`,
								legendPosition: 'bottom-left',
								lineStyle: {
									stroke: '#000',
									strokeWidth: 2,
								},
								textStyle: {
									stroke: '#000',
								},
								value: humanP
							}
						]}

					></ResponsiveLine> : <NoContent />}

			</div>
		);
	}, [formattedData, data.baseline]);

	if (data.graph === undefined) {
		return (<div className="alignCenter">Please wait</div>)
	}
	if (data.graph === 'NA') {
		return (<div className="alignCenter">Data Unavailable</div>)
	}
	return (
		<>
			{ClickVisitsGraph}
		</>

	)

};

export default BumpGraph;