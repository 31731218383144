import React from "react";
import { useSelector } from "react-redux";
import "./EvaluationPanel.scss";
import Loader from "../../../../SharedComponents/Loader/Loader";
import GoodBotsGraphs from "./EvaluationComponents/GoodBotsGraphs";
import GraphModal from "../../../../SharedComponents/GraphModal/GraphModal";
import { MaxIcon } from "../../../../SVG/MaxIcon";

const EvaluationPanel = (props) => {
	const goodBotsData = useSelector(state => state?.evaluationStatus?.data);
	const [showMaxIcon, setShowMaxIcon] = React.useState({
		google: false,
		apple: false,
		facebook: false,
		bytespider: false,
		other: false
	});

	const handleShowMaxIcon = (graph) => {
		switch (graph) {
			case "google":
				setShowMaxIcon((pre) => (
					{
						...pre,
						google: true
					}
				));
				break;
			case "facebook":
				setShowMaxIcon((pre) => (
					{
						...pre,
						facebook: true
					}
				));
				break;
			case "other":
				setShowMaxIcon((pre) => (
					{
						...pre,
						other: true
					}
				));
				break;
			case "apple":
				setShowMaxIcon((pre) => (
					{
						...pre,
						apple: true
					}
				));
				break;
			case "bytespider":
				setShowMaxIcon((pre) => (
					{
						...pre,
						bytespider: true
					}
				));
				break;

			default:
		}

	};
	const handleHideMaxIcon = (graph) => {
		switch (graph) {
			case "google":
				setShowMaxIcon((pre) => (
					{
						...pre,
						google: false
					}
				));
				break;
			case "facebook":
				setShowMaxIcon((pre) => (
					{
						...pre,
						facebook: false
					}
				));
				break;
			case "other":
				setShowMaxIcon((pre) => (
					{
						...pre,
						other: false
					}
				));
				break;
			case "apple":
				setShowMaxIcon((pre) => (
					{
						...pre,
						apple: false
					}
				));
				break;
			case "bytespider":
				setShowMaxIcon((pre) => (
					{
						...pre,
						bytespider: false
					}
				));
				break;

			default:
		}

	};

	return (

		<div className="goodBots-graphs-section">
			<div className="google-bots" onMouseEnter={() => handleShowMaxIcon("google")} onMouseLeave={() => handleHideMaxIcon("google")}>
				{props.loading ? <Loader /> :
					<GoodBotsGraphs
						data={goodBotsData?.goodBots.google}
						title="Google"
						showIcon={showMaxIcon.google}
						tooltipMessage=" This shows visits from Google bots each day. These bots primarily focus on indexing for the search engine. If you run Google Ads, these bots are also for ad management."
					/>
				}
			</div>
			<div className="apple-bots" onMouseEnter={() => handleShowMaxIcon("apple")} onMouseLeave={() => handleHideMaxIcon("apple")}>
				{props.loading ? <Loader /> :
					<GoodBotsGraphs
						data={goodBotsData?.goodBots.apple}
						title="Apple"
						showIcon={showMaxIcon.apple}
						tooltipMessage="This shows visits from Apple bots each day. These bots primarily focus on indexing on services like Siri and Spotlight."
					/>
				}
			</div>
			<div className="facebook-bots" onMouseEnter={() => handleShowMaxIcon("facebook")} onMouseLeave={() => handleHideMaxIcon("facebook")}>
				{props.loading ? <Loader /> :
					<GoodBotsGraphs
						data={goodBotsData?.goodBots.facebook}
						title="Facebook"
						showIcon={showMaxIcon.facebook}
						tooltipMessage="This shows visits from Facebook and Meta bots each day. These bots primarily focus on providing content within Facebook, Instagram and other Meta services."
					/>
				}
			</div>
			<div className="bytespider-bots" onMouseEnter={() => handleShowMaxIcon("bytespider")} onMouseLeave={() => handleHideMaxIcon("bytespider")}>
				{props.loading ? <Loader /> :
					<GoodBotsGraphs
						data={goodBotsData?.goodBots.bytespider}
						title="Bytespider"
						showIcon={showMaxIcon.bytespider}
						tooltipMessage="This shows visits from the Bytespider bot each day. Bytespider is run by ByteDance the company behind TikTok."
					/>
				}
			</div>
			<div className="other-bots">
				{props.loading ? <Loader onMouseEnter={() => handleShowMaxIcon("other")} onMouseLeave={() => handleHideMaxIcon("other")} /> :
					<GoodBotsGraphs
						data={goodBotsData?.goodBots.other}
						title="Other Known"
						showIcon={showMaxIcon.other}
						tooltipMessage="These are other bots known to be non malicious, they provide services such as site monitoring, security and providing previews for links to your pages."
					/>
				}
			</div>
		</div>
	);
};

export default EvaluationPanel;
