import React from "react";
import "./Dashboard.scss";
import AddPanel from "./DashboardComponents/AddPanel/AddPanel";
import { dashboardSelectedGraphs } from "../../Redux/actions";
import { useSelector, useDispatch } from "react-redux";
import TodayTile from "../Today/TodayComponents/TodayTile"
import AnalyticsTile from "../Performance/PerformanceComponent/PerformanceTile/PerformanceTile";
import BotActivityTile from "../BotActivity/BotActivityComponent/BotActivityTile";
import DashboardTile from "./DashboardComponents/DashboardTile/DashboardTile";
import api from "../../veracityapi"
const Dashboard = () => {

	const importArr = useSelector(state => state.dashboardSelectedGraphs.data);
	const sidetoggle = useSelector(state => (state.toggle.toggle));	
	const [dragFrom, setDragFrom] = React.useState(null);
	const dragged = React.useRef(null);
	const [graphList, setGraphList] = React.useState([]);
	const dispatch = useDispatch();

	const UseTile = (graph) =>{
		switch(graph.tileName){
			// case 'Analytics':
			// 	return  <AnalyticsTile DisplayDashboard={true} key={`${graph.tileName}:${graph.panelName}`} dragFrom={dragFrom} graph={graph.panelName} canDrag ={true} dragged={dragged} DragFunc={DragFunc}  DropFunc={DropFunc}/>
			case 'Today':
				return  <TodayTile DisplayDashboard={true} key={`${graph.tileName}:${graph.panelName}`} dragFrom={dragFrom} graph={graph.panelName} canDrag ={true} dragged={dragged} DragFunc={DragFunc}  DropFunc={DropFunc}/>
			case 'Protection':
				return <BotActivityTile	DisplayDashboard={true} key={`${graph.tileName}:${graph.panelName}`} dragFrom={dragFrom} graph={graph.panelName} canDrag ={true} dragged={dragged} DragFunc={DragFunc}  DropFunc={DropFunc} />
		};
	};

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	});

		
	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		// api.logout("Fatal Error")
	};


	const DragFunc = (e, graph) => {
		localStorage.setItem("ExampleDrag", graph)
		setDragFrom(graph);
	};	

	const DropFunc = (e, graph) => {
		let currentDragFrom = localStorage.getItem("ExampleDrag")
		if (currentDragFrom !== null) {
			let tempArr = [...importArr];
			let firstIndex = tempArr.findIndex((ele) => ele.panelName === currentDragFrom);
			let secondIndex = tempArr.findIndex((ele) => ele.panelName === graph);
			let tempObj = tempArr[secondIndex];
			tempArr[secondIndex] = tempArr[firstIndex];
			tempArr[firstIndex] = tempObj;

			dispatch(dashboardSelectedGraphs(tempArr))
		};
	};

	React.useEffect(()=>{
		const testArr = []		
		for(const a of [...importArr]){
			testArr.push(UseTile(a));
		};
		setGraphList(testArr);
	},[JSON.stringify(importArr)]);


	return (
		<div className="dashboard_container">
		<div>
			{
				graphList
			}
			<AddPanel/>
			</div>	
		</div>
	);
};

export default Dashboard;