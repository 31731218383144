import React from "react";
import "./IncreaseIndicator.scss";
import { FaArrowDown,FaArrowUp  } from "react-icons/fa6";

const IncreaseIndicator = ({ value, className = '', tooltip = '' }) => {
    return (
        <>
            {value ? <div className={`inc-ind ${className} tooltip`}>
                <span className={value < 0 ? 'negative' : 'positive'}>{Math.abs(value)}% {value<0?<FaArrowDown/>:<FaArrowUp/>}
                    <span className={"arrow"}> </span>
                </span>
                {tooltip != '' ? <span className="tooltiptext">{tooltip}</span> : null}
            </div> : null}
        </>
    )
}
export default IncreaseIndicator;
