import api from "veracityapi";
import { botTypeClassification } from "Redux/actions";

const BotTypeDescription = (params, dispatch) => {

    const getBotClassification = new Promise((resolve, reject) => {
        api.GET(`/botClassificationDescription`).then((res) => {
            resolve({
                botTypeClassification: res
            })
        }).catch((err) => {
            api.handleError(err)
        })
    });

    
	return new Promise((resolve, reject) =>{
		Promise.all( [getBotClassification]).then(async (values)=>{
			const temp = {
			};
			for(let obj of values ){
				Object.assign(temp, obj);
			};
			dispatch(botTypeClassification(temp));
			resolve(true);
		});
	});
};



export default BotTypeDescription;

