import React, { useState, useEffect } from 'react';
import bApi from 'API/BeaconAPI';
import "./index.scss";

const DynamicLinks = () => {
    const bcnWebsiteId = localStorage.getItem("bcn-website-id");
    const [dynLinks, setDynLinks] = useState(null);

    React.useLayoutEffect(() => {
        document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
    }, []);

    useEffect(() => {
        bApi.GET(`2.0/website/${bcnWebsiteId}/`, { "__onlyCore": '1', "!includeAlexandria": '1' })
            .then((e) => { setDynLinks(e.response); })
    }, []);

    return (
        <>
            {dynLinks == null ?
                <h3>We're generating your Veracity Ad Fraud Protection dynamic tracking. Please wait&hellip;</h3>
                :
                <div className="dynamicLinks-container">
                    <h2>Dynamic Links</h2>
                    <div className='cards'>
                        <div>Your Veracity dynamic link tag is</div>
                        <div><b>{dynLinks ? dynLinks.__alexandriaData[0].bcndyn : 'loading ...'}</b></div>
                    </div>
                    <p>
                        AFP evaluates traffic from your adverts to detect bot activity and provide suppression of bots in your digital campaigns. To enable us to do this, you will need to make some small changes to current and future digital ads; these changes depend on which ad networks you are running ads on. To learn more, please visit <a href="https://go.veracitytrustnetwork.com/resource/adding-tracking-templates/" target="_blank">https://go.veracitytrustnetwork.com/resource/adding-tracking-templates/</a>
                    </p>

                    <div>
                        <div>
                            <h3>Meta Ads including Facebook</h3>
                            <p><b>URL Parameters:</b> <code>{`fad_id={{ad.id}}&utm_campaign={{campaign.name}}&bcndyn=${dynLinks.bcndyn}`}</code></p>
                            <p>For more information see: <a href="https://go.veracitytrustnetwork.com/resource/afp-links-in-meta/" rel='noreferrer' target="_blank">https://go.veracitytrustnetwork.com/resource/afp-links-in-meta/</a></p>
                        </div>

                        <div>
                            <hr />
                            <h3>Google Ads</h3>
                            <p>For Performance Max see below.</p>
                            <p><b>Final URL Suffix:</b> <code>{`gad_id={creative}&utm_campaign=Google_Ads&bcndyn=${dynLinks.bcndyn}`}</code></p>
                            <p>For more information see: <a href="https://go.veracitytrustnetwork.com/resource/afp-links-in-google-ads/" rel='noreferrer' target="_blank">https://go.veracitytrustnetwork.com/resource/afp-links-in-google-ads/</a></p>
                        </div>

                        <div id="performance-max">
                            <hr />
                            <h3>Google Performance Max</h3>
                            <p>Be sure to replace <b>YOUR_CAMPAIGN_NAME</b> with the name of your Performance Max campaign.</p>
                            <p><b>Final URL Suffix:</b> <code>{`pad_id={campaignid}&utm_campaign=YOUR_CAMPAIGN_NAME&bcndyn=${dynLinks.bcndyn}`}</code></p>
                            <p>For more information see: <a href="https://go.veracitytrustnetwork.com/resource/afp-links-in-pmax-ads/" rel='noreferrer' target="_blank">https://go.veracitytrustnetwork.com/resource/afp-links-in-pmax-ads/</a></p>
                        </div>

                        <div>
                            <hr />
                            <h3>LinkedIn Ads</h3>
                            <p><b>URL Tracking Parameters:</b> <code>{`lad_id={{CREATIVE_ID}}&utm_campaign={{CAMPAIGN_NAME}}&bcndyn=${dynLinks.bcndyn}`}</code></p>
                            <p>For more information see: <a href="https://go.veracitytrustnetwork.com/resource/afp-links-in-linkedin-ads/" rel='noreferrer' target="_blank">https://go.veracitytrustnetwork.com/resource/afp-links-in-linkedin-ads/</a></p>
                        </div>

                        <div>
                            <hr />
                            <h3>Microsoft Ads including Bing</h3>
                            <p><b>Final URL Suffix:</b> <code>{`mad_id={AdId}&utm_campaign={Campaign}&bcndyn=${dynLinks.bcndyn}`}</code></p>
                            <p>For more information see: <a href="https://go.veracitytrustnetwork.com/resource/afp-links-in-microsoft-ads/" rel='noreferrer' target="_blank">https://go.veracitytrustnetwork.com/resource/afp-links-in-microsoft-ads/</a></p>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default DynamicLinks;

