import React from "react";
import "./HumanPercentage.scss";
import Loader from "SharedComponents/Loader/Loader";
import TrafficMessage from "SharedComponents/TrafficMessage/TrafficMessage";
import { formatNumber } from "Utils/utils";
const HumanPercentage = (props) => {

	return (
		<div className="humans-percentage-container">
			{
				props.loading ? <Loader /> :
					<div>
						{props.total !== 0 ?
							<div className="humans-percentage-content">
								<div className="humans-percentage-value">{props?.humanPercentage}%</div>
								<div className="humans-values">Humans: <b>{formatNumber(props?.humansValue)}</b></div>
							</div> :
							<TrafficMessage message="There has been no traffic in the selected time period." />
						}
					</div>
			}
		</div>
	)
};

export default HumanPercentage;