export const DownArrow = () => (
	<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.38683 5.2627C4.99417 5.66651 4.34562 5.66651 3.95296 5.2627L0.485879 1.69714C-0.130507 1.06324 0.318648 0 1.20282 0H8.13697C9.02114 0 9.4703 1.06324 8.85391 1.69714L5.38683 5.2627Z" fill="#1F1F1F" />
	</svg>

);
export const UpArrow = () => (
	<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.54627 0.737303C4.93894 0.333485 5.58748 0.333485 5.98015 0.737303L9.44723 4.30286C10.0636 4.93676 9.61446 6 8.73029 6L1.79613 6C0.911964 6 0.462807 4.93676 1.07919 4.30286L4.54627 0.737303Z" fill="#1F1F1F" />
	</svg>
);

export const LeftArrow = () => (
	<svg viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.5 9L1.5 5L5.5 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
)

export const RightArrow = () => (
	<svg viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.5 9L7.5 5L3.5 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
)

export const PercentageUpArrow = () => (
	<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.5 12.5C3.5 12.7761 3.72386 13 4 13C4.27614 13 4.5 12.7761 4.5 12.5L3.5 12.5ZM4.35355 0.146446C4.15829 -0.0488157 3.84171 -0.0488157 3.64645 0.146446L0.464466 3.32843C0.269204 3.52369 0.269204 3.84027 0.464466 4.03553C0.659728 4.2308 0.97631 4.2308 1.17157 4.03553L4 1.20711L6.82843 4.03553C7.02369 4.2308 7.34027 4.2308 7.53553 4.03553C7.7308 3.84027 7.7308 3.52369 7.53553 3.32843L4.35355 0.146446ZM4.5 12.5L4.5 0.5L3.5 0.5L3.5 12.5L4.5 12.5Z" fill="#49B65B" />
	</svg>
)

export const PercentageDownArrow = () => (
	<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.64645 13.0936C3.84171 13.2888 4.15829 13.2888 4.35355 13.0936L7.53553 9.91157C7.7308 9.71631 7.7308 9.39973 7.53553 9.20446C7.34027 9.0092 7.02369 9.0092 6.82843 9.20446L4 12.0329L1.17157 9.20446C0.976311 9.0092 0.659728 9.0092 0.464466 9.20446C0.269204 9.39973 0.269204 9.71631 0.464466 9.91157L3.64645 13.0936ZM4.5 0.739998C4.5 0.463856 4.27614 0.239998 4 0.239998C3.72386 0.239998 3.5 0.463856 3.5 0.739998L4.5 0.739998ZM4.5 12.74L4.5 0.739998L3.5 0.739998L3.5 12.74L4.5 12.74Z" fill="#E22937" />
	</svg>
)

export const BotsPercentageUpArrow = () => (
	<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.5 12.5C3.5 12.7761 3.72386 13 4 13C4.27614 13 4.5 12.7761 4.5 12.5L3.5 12.5ZM4.35355 0.146446C4.15829 -0.0488157 3.84171 -0.0488157 3.64645 0.146446L0.464466 3.32843C0.269204 3.52369 0.269204 3.84027 0.464466 4.03553C0.659728 4.2308 0.97631 4.2308 1.17157 4.03553L4 1.20711L6.82843 4.03553C7.02369 4.2308 7.34027 4.2308 7.53553 4.03553C7.7308 3.84027 7.7308 3.52369 7.53553 3.32843L4.35355 0.146446ZM4.5 12.5L4.5 0.5L3.5 0.5L3.5 12.5L4.5 12.5Z" fill="#E22937" />
	</svg>
)

export const BotsPercentageDownArrow = () => (
	<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.64645 13.0936C3.84171 13.2888 4.15829 13.2888 4.35355 13.0936L7.53553 9.91157C7.7308 9.71631 7.7308 9.39973 7.53553 9.20446C7.34027 9.0092 7.02369 9.0092 6.82843 9.20446L4 12.0329L1.17157 9.20446C0.976311 9.0092 0.659728 9.0092 0.464466 9.20446C0.269204 9.39973 0.269204 9.71631 0.464466 9.91157L3.64645 13.0936ZM4.5 0.739998C4.5 0.463856 4.27614 0.239998 4 0.239998C3.72386 0.239998 3.5 0.463856 3.5 0.739998L4.5 0.739998ZM4.5 12.74L4.5 0.739998L3.5 0.739998L3.5 12.74L4.5 12.74Z" fill="#49B65B" />
	</svg>

)
