import { MetaIcon, MicrosoftLogo, GoogleLogo, TikTokLogo, AdWordsLogo } from "../../SVG/AdNetworksIcons";
const filterPathname = false;
export const Adnetworks = [
    { id: 1, label: 'Google', connectionURL: 'google', name: 'Google Ads', icon: <GoogleLogo fill={filterPathname ? "darkgray" : "white"} height={filterPathname ? "16" : "28"} viewBox={filterPathname ? "0 0 44 46" : "0 0 45 46"} />, backgroundColor: "#0F9D58" },
    { id: 2, label: 'Meta', connectionURL: 'facebook', name: 'Facebook Ads', icon: <MetaIcon fill={filterPathname ? "blue" : "white"} />, backgroundColor: "linear-gradient(116.49deg, #0869E1 16.63%, #0377EF 38.7%, #007EF7 58.41%, #0081FA 82.68%)" },
    { id: 3, label: 'Microsoft', connectionURL: 'microsoft', name: 'Microsoft Ads', icon: <MicrosoftLogo fill={filterPathname ? "#db4437" : "white"} />, backgroundColor: " #DB4437" },
    { id: 4, label: 'LinkedIn', connectionURL: 'linkedin', name: 'LinkedIn Ads', icon: <MicrosoftLogo fill={filterPathname ? "#db4437" : "white"} />, backgroundColor: " #DB4437" },
    { id: 5, label: 'TikTok', connectionURL: 'tiktok', name: 'TikTok Ads', icon: <TikTokLogo fill={filterPathname ? "#db4437" : "white"} height={filterPathname ? "16" : "28"} />, backgroundColor: " #000000" },
    { id: 6, label: 'Google Performance Max', connectionURL: 'google', name: 'Google Performance Max Ads', icon: <AdWordsLogo fill={filterPathname ? "darkgray" : "white"} height={filterPathname ? "100" : "32"} viewBox={filterPathname ? "0 0 34 34" : "0 0 35 35"} />, backgroundColor: " #FFCE44", connectorHidden: true }
];
export const NO_BASELINE_RUN = 'NO_BASELINE_RUN';

export const botClassificationName = {
    1: "Scraper",
    2: "Crasher",
    3: "Imposter",
    4: "Thief",
    5: "Blocker",
    6: "Poster",
    7: "Clicker",
    8: "Pretender",
    9: "Scout",
    10: "Scanner",
    11: "Ghost"
};
export const classificationColors = {
    "Scraper": "#b296c8",
    "Crasher": "#662D91",
    "Imposter": "#908b93",
    "Thief": "#f4b79e",
    "Blocker": "#211728",
    "Poster": "#ff4900",
    "Clicker": "#a4daad",
    "Pretender": "#83c6eb",
    "Scout": "#EA6F3E",
    "Scanner": "#49B65B",
    "Ghost": "#068DD7"
};
export const riskLevels = {
    0: 'Low',
    1: 'Nuisance',
    2: 'Medium',
    3: 'High',
    4: 'Dangerous'
};

export const colors = {
    0: 'grey',
    1: '#662D91',
    2: '#068DD7',
    3: '#EA6F3E',
    4: '#E22937'
};

export const riskLevelsColours = {
    'Low': 'grey',
    'Nuisance': '#662D91',
    'Medium': '#068DD7',
    'High': '#EA6F3E',
    'Dangerous': '#E22937'
};

export const botClassificationRisks = new Map([
    [1, 3],
    [2, 3],
    [3, 4],
    [4, 4],
    [5, 2],
    [6, 1],
    [7, 2],
    [8, 1],
    [9, 2],
    [10, 1],
    [11, 1],
]);

