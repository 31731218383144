
import { BrowserRouter as Routers, Routes, Route, useLocation } from "react-router-dom";
import Login from './components/Login/Login';
import Dashboard from "./components/Dashboard/Dashboard";
import Select from "./components/Select/Select";
import Today from "./components/Today/Today";
import Reports from "./components/Reports/Reports";
import Performance from "./components/Performance/Performance"
import MyAccount from "./components/MyAccount/MyAccount";
import Customer from "./components/Customer/Customer";
import { Provider } from "react-redux";
import LoadingData from "./components/LoadingData/LoadingData";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./Redux/store";
import Website from "./components/Website/Website";
import Help from "./components/Help/Help";
import Features from "./components/Features/Features";
import BotActivity from "./components/BotActivity/BotActivity"
import Baseline from "./components/AFP/Baseline/Baseline";
import Current from "./components/AFP/Current/Current";
import Generic from "./components/AFP/Generic";
import TopBar from "./SharedComponents/TopBar/TopBar";
import SideBar from "./SharedComponents/SideBar/SideBar";
import Dynamiclinks from "./components/AFP/DynamicLinks";
import Affiliates from "./components/Affiliates/Affiliates";
import BotTypesDescription from "components/BotTypesDescription/BotTypesDescription";
import "./App.scss";
import React from "react";
const App = () => {



	return (
		<>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<div className="app">
						<Routers>
							<SideBar />
							<div style={{ width: '100%' }}>
								<TopBar />
								<main className="main">

									<Routes>
										<Route path="/" element={<Login />} />
										<Route path="/select" element={<Select />} />
										<Route path="/dashboard" element={<Dashboard />} />
										<Route path="/loading" element={<LoadingData />} />
										<Route path="/today" element={<Today />} />
										<Route path="/reports" element={<Reports />} />
										<Route path="/performance" element={<Performance />} />
										<Route path="/bot_activity" element={<BotActivity />} />
										<Route path="/manage/user" element={<MyAccount />} />
										<Route path="/manage/company" element={<Customer />} />
										<Route path='/manage/website' element={<Website />} />
										<Route path="/help" element={<Help />} />
										<Route path="/help/features" element={<Features />} />
										{/* <Route path="/afp/overview" element={<Overview/>} /> */}
										<Route path="/afp/baseline" element={<Baseline />} />
										<Route path="/afp/current" element={<Current />} />
										<Route path="/afp/current/campaign/:adType/:campaignId" element={<Current />} />
										<Route path="/afp/current/link/:adType/:linkId" element={<Current />} />
										<Route path="/afp/baseline/campaign/:adType/:campaignId" element={<Baseline />} />
										<Route path="/afp/baseline/link/:adType/:linkId" element={<Baseline />} />
										<Route path="/afp/comparison" element={<Generic title={'Comparision'} />} />
										<Route path="/afp/report" element={<Generic title={'Report'} />} />
										<Route path="/dynamic-links" element={<Dynamiclinks/>}/>
										<Route path="/afp/affiliates" element={<Affiliates />} />
										<Route path="/bot-types" element={<BotTypesDescription />} />
									</Routes>
								</main>
							</div>
						</Routers>
					</div>
				</PersistGate>
			</Provider>
		</>
	);
};
export default App;
