export const DragIcon = () => (
	<svg width="25" height="40" viewBox="0 0 25 40" fill="white" xmlns="http://www.w3.org/2000/svg">
	<circle cx="9.88477" cy="14" r="1.5" fill="#1F1F1F"/>
	<circle cx="16.0098" cy="14" r="1.5" fill="#1F1F1F"/>
	<circle cx="9.88477" cy="20" r="1.5" fill="#1F1F1F"/>
	<circle cx="16.0098" cy="20" r="1.5" fill="#1F1F1F"/>
	<circle cx="9.88477" cy="26" r="1.5" fill="#1F1F1F"/>
	<circle cx="16.0098" cy="26" r="1.5" fill="#1F1F1F"/>
	</svg>
);
export default DragIcon;