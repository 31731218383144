import React from "react";
import { ResponsiveChoropleth } from "@nivo/geo";
import countries from "world_countries.json";
import { countryToAlpha3 } from "country-to-iso";
import { formatNumber } from "Utils/utils";

const GeographyGraph = (props) => {

	const formatData = (data) => {
		let formattedData = [];
		let arr = [];
		let secArray = [];
		for (let key in data) {
			let val = (Object.entries(data[key]))
			for (let i = 0; i < val.length; i++) {
				arr.push(val[i])
			}
		};

		for (let j = 0; j < arr.length; j++) {
			let newArray = {
				id: countryToAlpha3(arr[j][0]) === null ? "unknown" : countryToAlpha3(arr[j][0]),
				value: arr[j][1]
			}
			secArray.push(newArray)
		};

		secArray.reduce(function (res, value) {
			if (!res[value.id]) {
				res[value.id] = { id: value.id, value: 0 };
				formattedData.push(res[value.id])
			}
			res[value.id].value += value.value;
			return res;
		}, {});

		return formattedData;
	};

	const data = formatData(props?.data);

	const CustomTooltip = ({ feature }) => {
		return (
			<div className="toolTipContent" style={{ display: "flex", position: "absolute", zIndex: 9999 }} >
				{feature.properties.name}: <b>{formatNumber(feature.value === undefined ? 0 : feature.value)}</b>
			</div>
		);
	};

	const filterGraphHighestValue = () => {
		const highestValue = []
		const filterData = data.filter((e) => e.id !== null);
		filterData.map((e) => highestValue.push(e.value));
		return Math.max(...highestValue);
	};
	const graphMaxValue = filterGraphHighestValue();

	const GeographyGraph = React.useMemo(() => {

		let colours = ["rgba(233, 224, 239, 1)", "rgba(209, 192, 222, 1)", "rgba(164, 128, 189, 1)", "rgba(102, 45, 145, 1)"];
		return (
			<ResponsiveChoropleth
				data={data}
				features={countries.features}
				colors={colours}
				margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
				domain={[0, graphMaxValue]}
				tooltip={CustomTooltip}
				unknownColor="rgba(244, 244, 244, 1)"
				label="properties.name"
				valueFormat=".2s"
				projectionTranslation={[0.49, 0.71]}
				projectionRotation={[0, 0, 0]}
				enableGraticule={false}
				graticuleLineColor="rgba(0, 0, 0, .2)"
				borderWidth={0.5}
				borderColor="#101b42"
			/>
		)

	}, [data, graphMaxValue]);


	return (
		<>
			{GeographyGraph}
		</>
	)
};
export default GeographyGraph;