import api from "../../../../veracityapi";
import { todayFinisihedSessions } from "../../../../Redux/actions";
import moment from "moment";
import "moment-timezone";
// import { getStartTimeStampFromString, getEndTimeStampFromString } from "../../../../SharedComponents/DateManager";


export default function Data(params, dispatch) {
	const websiteGUID = localStorage.getItem('selected_site');
	const timeZone = localStorage.getItem('timezone');
	const startTime = moment().tz(timeZone).startOf('day').unix();
	const endTime = moment().tz(timeZone).endOf('day').unix();

	// const start = getStartTimeStampFromString(timeRange);
	// const end = getEndTimeStampFromString(timeRange);

	const getTodayFinishedSessionsData = new Promise((resolve, reject) => {
		api.GET(`/analytics/${websiteGUID}/realtime/finished/${startTime}/${moment().tz(timeZone).add({ days: 1 }).endOf('day').unix()}`)
			.then(res => {
				resolve({
					todayFinisihedSessions: res.item
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getGeographicalSessionData = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/country/${startTime}/${endTime}/2/all/`, { breakdownLimit: 20, breakdown_parameter_min_count: 1 })
			.then(res => {
				resolve({
					finishedSessionsByCountry: res.item
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});
	const getSessionReferrer = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/referrer/${startTime}/${endTime}/2/all/`, { breakdownLimit: 11, breakdown_parameter_min_count: 1 })
			.then(res => {
				resolve({
					finishedSessionsReferrer: res.item
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});
	const getTodaySessionsBrowsers = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/browser/${startTime}/${endTime}/hour-by-hour/bot/`).then(res => {
			resolve({
				todaySessionsBrowsers: res.item
			});
		}).catch((error) => {
			api.handleError(error);
		});
	});

	const getTodaySessionsOS = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/os/${startTime}/${endTime}/hour-by-hour/bot/`).then(res => {

			resolve({
				todaySessionsOS: res.item
			});
		}).catch((error) => {
			api.handleError(error);
		});
	});

	const getFinishedBlockedBots = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/country/${startTime}/${endTime}/1/bot/`, { breakdownLimit: 20, breakdown_parameter_min_count: 1, active: false, finished: true })
			.then(res => {

				resolve({
					finishedBlockedBots: res.item
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});


	return new Promise((resolve, reject) => {
		Promise.all([getTodayFinishedSessionsData, getFinishedBlockedBots, getGeographicalSessionData, getSessionReferrer, getTodaySessionsBrowsers, getTodaySessionsOS]).then(async (values) => {
			const temp = {
			};
			for (let obj of values) {
				Object.assign(temp, obj);
			};
			dispatch(todayFinisihedSessions(temp));
			resolve(true);
		});
	});
};