import React from "react";
import { countryToAlpha2 } from "country-to-iso";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import TrafficMessage from "SharedComponents/TrafficMessage/TrafficMessage";
import { useLocation } from "react-router-dom";
import { formatNumber } from "Utils/utils";

const CountriesTable = (props) => {
	const { pathname } = useLocation();

	const regionNames = new Intl.DisplayNames(
		['en'], { type: 'region' }
	);

	const formatData = (data) => {
		let formattedData = [];
		try {
			let arr = [];
			let secArray = [];
			for (let key in data) {
				let val = (Object.entries(data[key]))
				for (let i = 0; i < val.length; i++) {
					arr.push(val[i])
				}
			};

			for (let j = 0; j < arr.length; j++) {
				let newArray = {
					id: countryToAlpha2(arr[j][0]) === null ? "unknown" : countryToAlpha2(arr[j][0]),
					value: arr[j][1]
				}
				secArray.push(newArray)
			};

			secArray.reduce(function (res, value) {
				if (!res[value.id]) {
					res[value.id] = { id: value.id, value: 0 };
					formattedData.push(res[value.id])
				}
				res[value.id].value += value.value;
				return res;
			}, {});
		} catch (error) {
			console.log(error)
		}
		return formattedData;
	};

	const data = formatData(props?.data);

	return (
		<div className="countries-list">
			<table className="data-table">
				<thead>
					<tr>
						<th>Country</th>
						<th>Sessions</th>
					</tr>
				</thead>
				{data.length > 0 ?
					<tbody>
						{data.sort((left, right) => {
							return left.value >= right.value ? -1 : 1
						}).map((value) =>
							<tr key={value.id}>
								<td className="page-col">{(value.id === "unknown" || value.id === "Others" || value.id === "null" ? "Unknown" : (regionNames.of(countryToAlpha2(value.id))))}</td>
								<td className="number-col">{formatNumber(value.value)}</td>
							</tr>
						)}
					</tbody> :
					<TrafficMessage message={pathname !== "/today" ? "There has been no traffic in the selected time period." : "There are currently no bots on the website."} />}
			</table>
			<div className="countries-table-tooltip" style={{ top: 0, right: 10, position: "absolute" }}>
				<GraphsToolTip message="This summary shows which countries the bots are coming from." />
			</div>
		</div>
	)

};

export default CountriesTable;