import React from "react";
import "./Help-TechnicalSupport.scss";

const HelpTechnicalSupport = () => {
	return (
		<div className="technical-section">
			<h3>How Do You Detect Bots?</h3>
			<p>
			By capturing a full spectrum of activity on your website, from location to navigation habits, our AI-powered verification can accurately combine a large number of data points to assess whether or not we’re dealing with a bot.
			</p>
		</div>
	);
};

export default HelpTechnicalSupport;