import React from "react";
import { useParams } from "react-router-dom";
import "./Baseline.scss";
import BaselineTile from "./BaselineComponents/BaselineTile";
import api from "../../../veracityapi";


const Baseline = () => {
	const props = useParams();

	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};
	
	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	return (
		<div className="analytics-container">
			<div>
                <BaselineTile {...props}/>
			</div>
		</div>
	);
};

export default Baseline;