import React from "react";
import { LoginLogo } from "../../../SVG/LoginLogo";
import api from "../../../veracityapi";
import "./LoginHead.scss";

import { Navigate } from "react-router-dom";

function HomeButton() {
    window.location.href = "https://botprotect.veracitytrustnetwork.com/pricing/#plans"
  }


export const LoginHead = () => (
    <>
    
        <div className="signup-content">
            <div className="veracity-logo">
                <img src={require("../../../components/images/Veracity-compact-white.png")} alt="veracity-logo" />
            </div>
            <p className="account-text">Don't have an account?</p>
            <button className="signup-button" onClick={HomeButton}>Sign Up</button>

        </div>
    </>
)
export const SelectHead = () => (
    <>
        <div className="signup-content">
            <div className="veracity-logo">
                <img src={require("../../../components/images/Veracity-compact-white.png")} alt="veracity-logo" />
            </div>
            <p className="account-text">Wrong Account?</p>
            <button className="signup-button" onClick={api.logout}>Sign Out</button>
        </div>
    </>
)
