import React from "react";
import { useSelector } from "react-redux";
import "./Help.scss"
import AccountManagerSidebar from "../../SharedComponents/AccountManageSideBar/AccountManageSideBar";
import HelpGettingStarted from "./Help-GettingStarted/Help-GettingStarted";
import HelpFeatures from "./Help-Features/Help-Features";
import HelpBilling from "./Help-Billing/Help-Billing";
import HelpManageUsers from "./Help-ManageUsers/Help-ManageUsers";
import HelpTechnicalSupport from "./Help-TechnicalSupport/Help-TechnicalSupport";
import HelpSecurity from "./Help-Security/Help-Security";
import Notification from "./Notifications/Notifications";
import FalsePositive from "./FalsePositive/FalsePositive";
import HumanVisitor from "./HumanVisitor/HumanVisitor";
import GoodBots from "./GoodBots/GoodBots";
import Detection from "./DetectionUpdated/DetectionUpdate";
import CustomerContribution from "./CustomerContribute/CustomerContribution";
import api from "../../veracityapi";

const Help = () => {
	const state = useSelector(state => state.toggle.toggle);

	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	return (
		<div>
			<div className={state ? "content-active" : "content"}>
				<div className="help-section" >
					<div className="help">Frequently Asked Questions</div>
					<div className="support-section">
						<HelpGettingStarted />
						<HelpFeatures />
						<HelpBilling />
						<HelpManageUsers />
						<HelpTechnicalSupport />
						<HelpSecurity />
						<Notification />
						<FalsePositive />
						<HumanVisitor />
						<GoodBots />
						<Detection />
						{/* <CustomerContribution /> */}
					</div>
					{/* <p className="question-text">Can't find what you're looking for?</p>
					<a href="https://discord.gg/veracity" target="_blank" rel="noreferrer"><button className="contact-support-button">Ask in our support forum </button></a> */}
				</div>
			</div>
		</div>
	)
};

export default Help;