
import api from "../../../../veracityapi";
import { getStartTimeStampFromString, getEndTimeStampFromString } from "../../../../SharedComponents/DateManager";
import { humanActivity } from "../../../../Redux/actions";
export default function Data(params, dispatch) {
	const websiteGUID = localStorage.getItem('selected_site');
	const normalStart = getStartTimeStampFromString(params.timeRange);
	const endTimeStamp = getEndTimeStampFromString(params.timeRange);

	const getHumanSourcesCountries = new Promise((resolve, reject) => {
		api.GET(`/analytics/website/${websiteGUID}/graph/country/${normalStart}/${endTimeStamp}/ok,full,monitored,human,suspicious/`, { referrer_limit: 11 })
			.then(res => {
				resolve({
					humansCountriesByDate: res
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getHumanSourcesReferrers = new Promise((resolve, reject) => {
		api.GET(`/analytics/website/${websiteGUID}/graph/referrer/${normalStart}/${endTimeStamp}/ok,full,monitored,human,suspicious/`, { referrer_limit: 11 })
			.then(res => {
				resolve({
					humansReferrersByDate: res
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});
	const getHumanBrowsers = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/browser/${normalStart}/${endTimeStamp}/0/ok,full,monitored,human,suspicious/`, { active: false, finished: true }).then(res => {
			resolve({
				humanBrowsers: res.item
			});
		}).catch((error) => {
			api.handleError(error);
		});
	});

	const getHumanOS = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/os/${normalStart}/${endTimeStamp}/0/ok,full,monitored,human,suspicious/`, { active: false, finished: true }).then(res => {
			resolve({
				humanOS: res.item
			});
		}).catch((error) => {
			api.handleError(error);
		});
	});

	return new Promise((resolve, reject) => {
		Promise.all([getHumanSourcesCountries, getHumanSourcesReferrers, getHumanBrowsers, getHumanOS]).then(async (values) => {
			const temp = {
				timeRange: params.timeRange,
			};
			for (let obj of values) {
				Object.assign(temp, obj);
			};
			dispatch(humanActivity(temp));
			resolve(true);
		});
	});
};

