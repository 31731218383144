import React from "react";
import { ResponsiveBarCanvas } from '@nivo/bar';
import { store } from "../../../../../Redux/store";
import TrafficMessage from "../../../../../SharedComponents/TrafficMessage/TrafficMessage";
import { utcTimeStringToLocalTimeString } from "../../../../../SharedComponents/DateManager";

const TodayBarGraph = (props) => {
	const websiteStatus=store.getState().websiteDetails.data.websiteDetails.status;
	const botsString = websiteStatus === "protect" ? "Blocked Bots" : "Detected Bots";

	function formatData(data) {
		const formattedData = [];
		const keys = [];
		for (let date in data) {
			let time = utcTimeStringToLocalTimeString(date, "YYYY-MM-DD, HH:mm", "HH:mm");
			let array = {
				date: time,
				human: data[date].human + data[date].suspicious,
				bot: data[date].bot,
			}
			keys.push(time)
			formattedData.push(array)
		}
		return [keys, formattedData]
	};

	const formattedData = formatData(props.finishedSessions);

	function CustomToolTip({ data }) {
		return (
			<div className="toolTipContent">
				<div>At: <b>{data.date}</b></div>
				<div>Humans: <b className="humansValues">{data.human??0}</b> </div>
				<div>{botsString}: <b className="botsValues">{data.bot??0}</b></div>
			</div>
		)
	};

	const MyResponsiveBarCanvas = React.useMemo(() => {

		let colour = ["rgba(102, 45, 145, 1)", "rgba(226, 41, 55, 1)"];

		if(formattedData[1].length!==0){
			return (
				<ResponsiveBarCanvas
					data={formattedData[1]}
					keys={[
						"human",
						"bot"
					]}
					indexBy="date"
					margin={{ top: 40, right: 10, bottom: 90, left: 60 }}
					pixelRatio={1.25}
					padding={0.15}
					innerPadding={0}
					minValue="auto"
					maxValue="auto"
					groupMode="stacked"
					layout="vertical"
					reverse={false}
					valueScale={{ type: 'linear' }}
					indexScale={{ type: 'band', round: true }}
					colors={colour}
					borderWidth={0}
					borderRadius={0}
					borderColor={{
						from: 'color',
						modifiers: [
							[
								'darker',
								1.6
							]
						]
					}}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 10,
						tickRotation: 45,
						legendPosition: 'middle',
						legendOffset: 30,
						format: ((value) => value[3] === "0" ? value : "")
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legendPosition: 'middle',
						legendOffset: -40
					}}
					tooltip={CustomToolTip}
					enableGridX={false}
					enableGridY={false}
					enableLabel={false}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{
						from: 'color',
						modifiers: [
							[
								'darker',
								1.6
							]
						]
					}}
					isInteractive={true}
					legends={[]}
				/>
			)

		}else {
			return (
				<TrafficMessage message="There hasn't been any traffic today."/>
			);
		}

		
	}, [formattedData]);

	return (
		<>
			{MyResponsiveBarCanvas}
		</>
	)
};

export default TodayBarGraph;