import "../Affiliates.scss";
import React from "react";
import DateDropDown from "../../../SharedComponents/DateDropDown/DateDropDown";
import { useSelector, useDispatch } from "react-redux";
import AffiliatesController from "../AffiliatesController";
import AffiliateIndex from "./AffiliatesPanel";


const AffiliatesTile = (props) => {
	const dateDropDownValues = ['Prev 7 Days', 'Prev 14 Days', 'Prev 28 Days', 'This Month', 'Last Month'];
	const dispatch = useDispatch();
	const affiliatesData = useSelector(state => state.sessionAffiliates.data);
	const [value, setValue] = React.useState({
		graph: null,
		timeRange: null
	});
	const [graph, setGraph] = React.useState(null);

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	React.useEffect(() => {
		if (value.graph !== "Affiliate Overview") {
			setValue({
				graph: "Affiliate Overview",
				timeRange: affiliatesData?.timeRange ?? 'Prev 7 Days'
			});
		} else {
			if (value.timeRange !== affiliatesData?.timeRange) {
				props.setLoading(true)
				Promise.resolve(
					AffiliatesController({ timeRange: value?.timeRange }, dispatch)
				).then(() => {
					props.setLoading(false);
				}).catch((e) => {
					props.setLoading(false);
				})
			}
		}
	}, [value]);


	const graphSelect = () => {
		switch (props.graph) {
			case 'Affiliate Overview':
				return <AffiliateIndex loading={props.loading} />
			default:

				return <></>
		};
	};
	React.useEffect(() => {
		setGraph(graphSelect());
	}, [props.loading, value]);

	return (
		<div
			className="graph-tile"
			style={{ width: '95%' }}
		>
			<div className="graph-tile-header">
				<h2>{props.graph}</h2>
				<div className="button-box">
					<div className="date-dropdown-container">
						<DateDropDown setValue={setValue} value={value} dateDropDownValues={dateDropDownValues} />
					</div>

				</div>
			</div>
			<div className="graph-body">
				{graph}
			</div>
		</div>
	);
};

export default AffiliatesTile;