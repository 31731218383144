import api from "../../../../veracityapi";
import { botDetails } from "../../../../Redux/actions";
import { getStartTimeStampFromString, getEndTimeStampFromString } from "../../../../SharedComponents/DateManager";

export default function Data(params, dispatch, page) {
	const websiteGUID = localStorage.getItem('selected_site');
	const start = getStartTimeStampFromString(params.timeRange);
	const EndOfYesterday = getEndTimeStampFromString(params.timeRange);
	let limit = 10;

	const getBotDetails = new Promise((resolve, reject) => {
		api.paged(`/session/website/${websiteGUID}/bot/${start}/${EndOfYesterday}`, limit, page, { finished: "true", active: "false", useCache: "true", order: "DESC" })
			.then(res => {
				resolve(res);
			}).catch((error) => {
				api.handleError(error);
			});
	});

	return new Promise((resolve, reject) => {
		Promise.all([getBotDetails]).then(async (values) => {
			const temp = {
				timeRange: params.timeRange,
			};
			for (let obj of values) {
				Object.assign(temp, obj);
			};
			dispatch(botDetails(temp));
			resolve(true);
		});
	});
};