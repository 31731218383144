import api from "../../veracityapi";
import { sessionAffiliates } from "../../Redux/actions";
import { getStartTimeStampFromString, getEndTimeStampFromString } from "../../SharedComponents/DateManager";

export default function Data(params, dispatch) {
	const websiteGUID = localStorage.getItem('selected_site');
	const start = getStartTimeStampFromString(params.timeRange);
	const end = getEndTimeStampFromString(params.timeRange);


	const getSessionAffiliatesData = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/affiliate/${start}/${end}/1/bot,human`, { active: false, finished: true })
			.then(res => {
				resolve(
					res.item.data
				);
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getSessionAffiliatesDataPerDay = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/affiliate/${start}/${end}/0/bot,human`, { active: false, finished: true })
			.then(res => {
				resolve(
					{
						perdayData:res.item
					}
				);
			}).catch((error) => {
				api.handleError(error);
			});
	});

	return new Promise((resolve, reject) => {
		Promise.all([getSessionAffiliatesData,getSessionAffiliatesDataPerDay]).then(async (values) => {
			const temp = {
				timeRange: params.timeRange
			};
			for (let obj of values) {
				Object.assign(temp, obj);
			};
			dispatch(sessionAffiliates(temp));
			resolve(true);
		});
	});
}