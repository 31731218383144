import React from "react";
import "./MyDetails.scss";
import api from "../../../veracityapi";
import MFAModal from "./MyDetailsComponent/MFAModal/MFAModal";

const MyDetails = () => {
	const firstName = localStorage.getItem("name");
	const lastName = localStorage.getItem("last_name");
	const email = localStorage.getItem("email");
	const userGUID = localStorage.getItem("userGUID");
	const [activeConfirmModal, setActiveConfirmModal] = React.useState(false);
	const [mfaModal, setMFAModal] = React.useState(false);
	const [mfaActive, setMFAActive] = React.useState(localStorage.getItem("mfeEnabled") == "true")


	const [input, setInput] = React.useState(
		{
			"first_name": firstName,
			"last_name": lastName,
		}
	);

	const [isChanged, setIsChanged] = React.useState(false);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setInput((preState) => {
			return {
				...preState,
				[name]: value
			}
		});
		setIsChanged(true)
	};


	let [saveButtonStyle, setSaveButtonStyle] = React.useState(false)

	const handleSubmitDetails = (e) => {
		document.getElementById("save-change").innerHTML = "Saving changes";
		e.preventDefault()
		api.PUT(`/user/${userGUID}`, input)
			.then(res => {
				localStorage.setItem("name", res.item.first_name);
				localStorage.setItem("last_name", res.item.last_name);
				document.getElementById("save-change").innerHTML = "Changes saved";
				setSaveButtonStyle(true)
			}).catch(e => console.log(e))
			.then(res => {
				setTimeout(() => {
					setIsChanged(false);
					document.getElementById("save-change").innerHTML = "Save changes";
					setSaveButtonStyle(false)
				}, 5000);
			}
			)
	};

	const handleMFAModal = () => {
		if (mfaActive === true) {
			api.DELETE(`/user/${userGUID}/mfa`)
				.then((res) => {
					localStorage.setItem("mfeEnabled", false);
					setMFAActive(false);
				});
		} else {
			setMFAModal(true);
		};
	};

	// console.log("THE MFA: ", mfa)
	return (
		<div className="mydetails-content">
			<h3>Account Details</h3>
			<form onSubmit={handleSubmitDetails}>
				<div className="name-email-input">
					<div className="full-name">
						<label>First name</label>
						<input
							className="inputs"
							name="first_name"
							placeholder="First Name"
							value={input.first_name}
							onChange={handleInputChange}
							required
						/>
					</div>
					<div className="full-name">
						<label>Last name</label>
						<input
							type="text"
							className="inputs"
							name="last_name"
							placeholder="Last Name"
							value={input.last_name}
							onChange={handleInputChange}
							required
						/>
					</div>
					<div className="email">
						<label>Email</label>
						<input
							type="email"
							className="inputs"
							name="email"
							placeholder="Email"
							value={email}
							onChange={handleInputChange}
							disabled
						/>
					</div>
				</div>
				<div>
					<a href="https://static.platform.veracitytrustnetwork.com/" target="_blank" rel="noreferrer" className="change-password" >Change Password</a>
					<button className="save-change" id="save-change" type="submit" onClick={() => setActiveConfirmModal(true)} disabled={!isChanged} style={{ backgroundColor: `${!isChanged ? "#F4F4F4" : `${saveButtonStyle ? "#6EBA7A" : "#662D91"}`}`, borderColor: `${!isChanged ? "#F4F4F4" : `${saveButtonStyle ? "#6EBA7A" : "#662D91"}`}` }}>Save changes</button>
					<button className={mfaActive ? "remove-mfa" : "add-mfa"} type={"button"} onClick={handleMFAModal} >{mfaActive ? "Remove MFA" : "Add MFA"}</button>
				</div>
			</form>



			{mfaModal && <MFAModal mfaModal={mfaModal} setMFAModal={setMFAModal} setMFAActive={setMFAActive} />}
		</div>
	);
};

export default MyDetails;