import React from "react";
import { formatNumber } from "Utils/utils";

const CustomTooltip = ({ data, graphRef, percentageSign }) => {

    const filteredSlice = data.slice.points.filter((point) => point.data.y !== 0);
    const sortFilter = filteredSlice.sort((a, b) => (b.data.y - a.data.y));
    const styles = {
        backgroundColor: '#ffffff',
        padding: '6px 9px',
        borderRadius: '2px',
        minWidth: '160px',
        height: '20px',
        boxShadow: '0 3px 5px rgba(0, 0, 0, .25)',
        position: "relative",
        display: "flex",
        alignItems: "center",
        gap: "3px",
        // left: data.slice.x < graphRef.current.clientWidth / 2? "52%" : "",
        // right: data.slice.x > graphRef.current.clientWidth / 2 ? "52%" : "",
        top: sortFilter[0]?.y < graphRef.current.clientHeight * 0.15 ? `${(sortFilter.length * 10) + 10}px` : "",
    }
    return (
        <div>
            {sortFilter.map((e) => {
                return (
                    <div style={styles}>
                        <div style={{ background: e.serieColor, height: 15, width: 15, borderRadius: "50%", marginRight: 8 }} className="tooltip-key"></div>
                        <span style={{ fontWeight: "bolder" }}>{e.serieId}: </span>
                        <span>{formatNumber(e.data.y)}{percentageSign}</span>
                    </div>
                )
            })}
        </div>
    )
};

export default CustomTooltip;