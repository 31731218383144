import React from "react";
import DragIcon from "../../SVG/DragIcon";
import './Dragger.scss';

const Dragger = (props) => (
	<div className="Dragger"onMouseDown={()=> {  props.parent.draggable = true}}>	
		<DragIcon/>
	</div>
);

export default Dragger;