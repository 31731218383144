import React from "react";
import "./Permissions.scss";
const Permissions = (props) => {
    return (
        <div className="permission-message">
			<p>{props.permissionMessage}</p>
        </div>
    )
};

export default Permissions;