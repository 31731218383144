import React, { useMemo } from "react";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import { ResponsiveBar } from '@nivo/bar';
import Loader from "SharedComponents/Loader/Loader";
import TrafficMessage from "SharedComponents/TrafficMessage/TrafficMessage";
import { riskLevels, colors, riskLevelsColours, botClassificationRisks } from "components/AFP/Constants";
import { useSelector } from "react-redux";

const BotIntentions = ({ botRiskType, botClassification, formattedBotTypeData, title, loading }) => {

    const styles = {
        backgroundColor: '#ffffff',
        padding: '6px 9px',
        borderRadius: '2px',
        minWidth: '160px',
        boxShadow: '0 3px 5px rgba(0, 0, 0, .25)',
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        gap: "8px",
        zIndex: "9999"
    };


    const formatData = () => {
        let total = 0;
        let totalForToday = {};
        let percentageForToday = {};

        for (let dates in botRiskType.item) {
            for (let id in botRiskType.item[dates].bot) {
                if (id !== "0") {
                    total += botRiskType.item[dates].bot[id];
                    totalForToday[id] = (totalForToday[id] || 0) + botRiskType.item[dates].bot[id];
                };
            }
        }

        for (let id in totalForToday) {
            percentageForToday[id] = (totalForToday[id] / total) * 100;
        }

        return new Map(Object.entries(percentageForToday).reverse());
    };

    const formatBotDescriptionData = (formattedBotTypeData, botClassification) => {
        const mergedArray = botClassification.items.map(val => {
            const data = formattedBotTypeData.find(item => item.id === val.bot_classification_id);
            if (data !== undefined) {
                return { ...val, value: data.value };
            }

        });
        const filterArray = mergedArray.filter((val) => val !== undefined).sort((a, b) => b.value - a.value);

        const map = new Map();

        filterArray.forEach(obj => {
            if (!map.has(obj.bot_risk_type_id) || map.get(obj.bot_risk_type_id).value < obj.value) {
                map.set(obj.bot_risk_type_id, obj);
            }
        });
        const result = Array.from(map.values()).sort((a, b) => botClassificationRisks.get(b.value) - botClassificationRisks.get(a.value));
        return result.sort((a, b) => b.bot_risk_type_id - a.bot_risk_type_id);
    };

    const formattedData = formatData();
    const formattedDescription = formatBotDescriptionData(formattedBotTypeData, botClassification)

    const ToolTip = ({ data }) => {
        return (
            <div style={styles}>
                <div>
                    <span style={{ fontWeight: "bolder" }} >{riskLevels[data.id]}: </span>
                    {(data.value).toFixed(2)}%
                </div>
            </div>
        )
    };

    const formatDataforLegends = () => {
        const result = [];
        formattedData.forEach((val, key) => {
            const object = {
                color: colors[key],
                value: val,
                title: riskLevels[key]
            }

            result.push(object)
        });
        return result
    };

    const legendsData = formatDataforLegends();

    const BarGraph = useMemo(() => {

        return (
            <ResponsiveBar
                data={[Object.fromEntries(formattedData)]}
                keys={[...formattedData.keys()]}
                margin={{ top: 10, right: 10, bottom: 1, left: 10 }}
                // padding={0.2}
                innerPadding={2}

                layout="horizontal"
                colors={({ id }) => colors[id]}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: false }}
                valueFormat=" >-"
                borderRadius={4}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enableGridY={false}
                enableLabel={false}
                enableTotals={true}
                totalsOffset={0}
                labelPosition="start"
                tooltip={(e) => <ToolTip data={e} />}

            />
        )
    }, [botRiskType]);

    const GetLegends = () => {
        return (
            <div className="legends-section" >
                {legendsData.map((itm, index) =>
                    <div className="bar-legends" key={index}>
                        <div className="legend-key">
                            <div className="legends-value" style={{ backgroundColor: itm.color }}></div>
                            <div>{(itm.value).toFixed(1)}%</div>
                        </div>

                        <div>{itm.title}</div>
                    </div>
                )}
            </div>
        )
    };

    const websiteStatus = useSelector((state) => state.websiteDetails?.data?.websiteDetails?.status);

    return (
        <div className="bot-intentions-container">

            <div className="graph-header" >
                <div className="graph-heading">{title}</div>
                <GraphsToolTip message={websiteStatus === "protect" ? "We give each bot we protect you from a threat level to help give you an idea of the danger Veracity is protecting your business from. This breakdown shows the most common bots we're blocking in each of these threat levels." : "We give each bot a threat level to help give you an idea of the danger posed to your business. This breakdown shows the most common bots we're seeing in each of these threat levels."} />
            </div>
            {formattedData.size !== 0 ?
                <>
                    {loading ? <Loader /> :
                        <>
                            <div style={{ height: '60px', width: '100%' }}>
                                {BarGraph}
                            </div>
                            <GetLegends />
                            <div className="bot-intentions-classification">
                                <div className="bot-intentions">
                                    {
                                        formattedDescription.map((val) =>
                                            <div className="bot-intentions-info">
                                                <div className="risk-title">{val.risk_level}</div>
                                                <div className="capsules" style={{ background: riskLevelsColours[val.risk_level] }}></div>
                                                <div className="bot-type-classification">
                                                    <div >Most Common bot type detected:<span style={{ fontWeight: "bolder", marginRight: "3px" }}> {val.classification}</span></div>
                                                    <div>{val.description}</div>
                                                </div>

                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </>
                    }
                </> : <TrafficMessage message="There are currently no bots on the website." />

            }

        </div>
    )
};


export default BotIntentions;