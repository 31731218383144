import React from "react";
import "./BotTypesDescription.scss";

const Modal = (props) => {
    return (
        <div className="bot-classification-modals">
            <div>{props?.classification} </div>
            <div>
                {props?.description}
            </div>
            <div className="risk-level">
                {props?.risk_level}
            </div>
        </div>
    );
};

export default Modal;