export function toggleReducer(state = { toggle: true }, actions) {
	switch (actions.type) {
		case "TOGGLE":
			return {
				...state,
				toggle: !state.toggle
			}
		default:
			return state;

	};
};

export function AFPMenuToggleReducer(state = { toggle: false }, actions) {
	switch (actions.type) {
		case "AFP_MENU_TOGGLE":
			return {
				...state,
				toggle: !state.toggle
			}
		default:
			return state;

	};
};

export function submenuToggleReducer(state = { submenu: false }, actions) {
	switch (actions.type) {
		case "SET_SUBMENU_TOGGLE":
			return {
				...state,
				submenu: !state.submenu
			}
		default:
			return state;
	};
};

export function websiteDataReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_WEBSITE_DATA":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function finishedSessionsReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_FINISHED_SESSIONS_DATA":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};
export function humanActivityReducer(state = {}, actions) {
	switch (actions.type) {
		case "HUMAN_ACTIVITY":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function evaluationStatusReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_EVALUATION_GRAPHS":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function sessionGeographyReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_SESSIONS_GEOGRAPHY_DATA":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function blockedSessionsGeographyReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_BLOCKED_SESSIONS_GEOGRAPHY_DATA":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function todayCurrentlyActiveReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_CURRENTLY_ACTIVE_SESSIONS":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function todayFinishedSessionsReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_TODAY_FINISHED_SESSIONS":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function websiteInsightsReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_WEBSITE_INSIGHTS":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function dashboardSelectedGraphsReducer(state = [], actions) {
	switch (actions.type) {
		case "SET_DASHBOARD_SELECTED_GRAPHS":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};


export function websitePerformanceReducer(state = {}, actions) {
	switch (actions.type) {
		case "WEBSITE_PERFORMANCE":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function websiteCO2Reducer(state = {}, actions) {
	switch (actions.type) {
		case "WEBSITE_CO2":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function websiteListReducer(state = {}, actions) {
	switch (actions.type) {
		case "WEBSITE_LIST":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function todaySessionsInfoReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_TODAY_SESSIONS_INFO":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function blockedSessionsInfoReducer(state = {}, actions) {
	switch (actions.type) {
		case "SET_BLOCKED_SESSIONS_INFO":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};

export function botDetails(state = {}, actions) {
	switch (actions.type) {
		case "SET_BOTDETAILS":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};
export function usersDetails(state = {}, actions) {
	switch (actions.type) {
		case 'SET_USERSDETAILS':
			return {
				...state,
				data: actions.payload
			}
		default:
			return state;
	};
};


export function BaselineReducer(state = {}, actions) {
	switch (actions.type) {
		case "UPDATE_BASELINE_ADNETWORK":
			return {
				...state,
				selectedNetworks: actions.payload
			}
		case "UPDATE_BASELINE_DATA":
			const adType = Object.keys(actions.payload.data)[0];
			const newState = { ...state.data, [adType]: actions.payload.data[adType], clicks: actions.payload.clicks }
			return {
				...state,
				data: newState
			}
		default:
			return state;
	};
};
export function CurrentReducer(state = {}, actions) {

	switch (actions.type) {
		case "UPDATE_CURRENT_ADNETWORK":
			return {
				...state,
				selectedNetworks: actions.payload,
			}

		case "UPDATE_CURRENT_DATA":
			const adType = Object.keys(actions.payload.data)[0];
			const newState = { ...state.data, [adType]: actions.payload.data[adType] }
			return {
				...state,
				data: newState
			}
		case "UPDATE_CAMPAIGN_NAME":
			return {
				...state,
				campaignName: actions.payload,
			}

		default:
			return state;
	};
};

export function availableAdNetworkReducer(state = {}, actions) {
	switch (actions.type) {
		case "UPDATE_AVAILABLE_AD_NETWORKS":
			return {
				availablePlatforms: actions.payload
			}
		default:
			return state
	}
}

export function sessionAffiliatesReducer(state = {}, actions) {
	switch (actions.type) {
		case "SESSION_AFFILIATES_INFO":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state
	}
};

export function loadDataReducer(state = {}, actions) {
	switch (actions.type) {
		case "LOAD_DATA":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state
	}
};


export function botRiskDetailsReducer(state = {}, actions) {
	switch (actions.type) {
		case "BOTRISK_DETAILS":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state
	}
}
export function botTypeClassificationsReducer(state = {}, actions) {
	switch (actions.type) {
		case "BOT_TYPE_CLASSIFICATION":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state
	}
}

export function botRiskDetailsForBotActivityReducer(state = {}, actions) {
	switch (actions.type) {
		case "BOTRISK_DETAILS_FOR_BOTACTIVITY":
			return {
				...state,
				data: actions.payload
			}
		default:
			return state
	}
}

