import api from "../../../../veracityapi";
import { blockedSessionsGeography } from "../../../../Redux/actions";
import { getStartTimeStampFromString, getEndTimeStampFromString } from "../../../../SharedComponents/DateManager";


export default function Data(params, dispatch) {
	const websiteGUID = localStorage.getItem('selected_site');
	const start = getStartTimeStampFromString(params.timeRange);
	const EndOfYesterday = getEndTimeStampFromString(params.timeRange);

	const getBlockedSessionGeography = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/country/${start}/${EndOfYesterday}/1/bot/`, { breakdownLimit: 20, breakdown_parameter_min_count: 1, active: false, finished: true })
			.then(res => {
				resolve({
					blockedSessionGeography: res.item
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getBotSourcesCountries = new Promise((resolve, reject) => {
		api.GET(`/analytics/website/${websiteGUID}/graph/country/${start}/${EndOfYesterday}/bot/`, { referrer_limit: 11 })
			.then(res => {
				resolve({
					botsCountriesByDate: res
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});
	const getBlockedSessionsBrowsers = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/browser/${start}/${EndOfYesterday}/day-by-day/bot/`, { active: false, finished: true }).then(res => {
			resolve({
				blockedSessionsBrowsers: res.item
			});
		}).catch((error) => {
			api.handleError(error);
		});
	});

	const getBlockedSessionsOS = new Promise((resolve, reject) => {
		api.GET(`/session/website/${websiteGUID}/doublebreakdown/os/${start}/${EndOfYesterday}/day-by-day/bot/`, { active: false, finished: true }).then(res => {
			resolve({
				blockedSessionsOS: res.item
			});
		}).catch((error) => {
			api.handleError(error);
		});
	});

	const getBotSourcesReferrers = new Promise((resolve, reject) => {
		api.GET(`/analytics/website/${websiteGUID}/graph/referrer/${start}/${EndOfYesterday}/bot/`, { referrer_limit: 11 })
			.then(res => {
				resolve({
					botsReferrersByDate: res
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});


	return new Promise((resolve, reject) => {
		Promise.all([getBlockedSessionGeography, getBotSourcesCountries, getBotSourcesReferrers, getBlockedSessionsBrowsers, getBlockedSessionsOS]).then(async (values) => {
			const temp = {
				timeRange: params.timeRange,
			};
			for (let obj of values) {
				Object.assign(temp, obj);
			};
			dispatch(blockedSessionsGeography(temp));
			resolve(temp);
		});
	});
};
