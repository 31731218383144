import React from "react";
import './AddPanel.scss';

const SVG = (	<svg width="100%" height="60%" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.81641 25.5H49.8164" stroke="#1F1F1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.8164 49.5L25.8164 1.5" stroke="#1F1F1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>)
const AddPanel = () => {

	return (
		<div className="add-panel">	
			<span>Add panel</span>
			<p>	Customise your dashboard by adding additional data points for <br/>analysis and see them all in one place.</p>
		</div>
	);
};

export default AddPanel;