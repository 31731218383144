import React from "react";
import "./BlockedSessionsGeographyPanel.scss";
import { useSelector } from "react-redux";
import GeographyGraph from "./BlockedSessionsGeographyGraphs/GeographyGraph";
import CountriesLineGraph from "./BlockedSessionsGeographyGraphs/CountriesLineGraph";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import ReferralSources from "./BlockedSessionsGeographyGraphs/ReferralSources";
import CountriesTable from "./BlockedSessionsGeographyGraphs/CountriesTable";
import BlockedBrowserLineGraph from "./BlockedSessionsGeographyGraphs/BlockedBrowserLineGraph";
import BlockedOSLineGraph from "./BlockedSessionsGeographyGraphs/BlockedOSLineGraph";
// import { utcTimeStringToLocalTimeString } from "SharedComponents/DateManager";
import Loader from "SharedComponents/Loader/Loader";
import TrafficMessage from "SharedComponents/TrafficMessage/TrafficMessage";
import moment from "moment";
import 'moment-timezone';

const BlockedSessionsGeographyPanel = (props) => {
	const sideToggle = useSelector(state => state.toggle.toggle);
	const blockedSessionsGeographyData = useSelector(state => state?.blockedSessionsGeography?.data);

	const [zoomTooltip, setZoomTooltip] = React.useState(false);

	const calculateValues = (data = {}, countryArray = []) => {
		const calculatedData = {};
		for (const date in data) {
			const dateData = data[date] ?? {};

			const addValues = countryArray?.map((item) => {
				const sum = (dateData?.bot?.[item] || 0)

				if (!calculatedData[item]) {
					calculatedData[item] = [];
				}
				calculatedData[item].push({
					x: moment.utc(date, "YYYY-MM-DDTHH:mm.SSSZ").format("Do MMM"),//utcTimeStringToLocalTimeString(date, "YYYY-MM-DDTHH:mm.SSSZ", "Do MMM"),
					y: sum,
				});
			});

		};
		return calculatedData
	};

	const formatData = (data) => {

		if (blockedSessionsGeographyData !== undefined) {
			const countries = Object.values(data)?.map((values) => {
				const allcountries = []
				for (let countries in values) {
					allcountries.push(Object.keys(values[countries]));
				}
				return allcountries

			});
			const countryArray = Array.from(new Set([...countries.flat(2)]));

			const temp = calculateValues(data, countryArray)

			const formattedData = countryArray.filter(
				(val) => {
					if (val == 'total') {
						return false;
					}
					return true;
				}).map(
					(val) => {
						return {
							id: val,
							data: temp[val]
						}
					})
			return formattedData
		};
	}

	const browserGraphData = formatData(blockedSessionsGeographyData?.blockedSessionsBrowsers);
	const blockedOSGraphData = formatData(blockedSessionsGeographyData?.blockedSessionsOS);

	const Controls = () => {
		const { zoomIn, zoomOut } = useControls();
		return (
			<>
				<div className="zoom-controls-parent">
					<button className="zoom-controls zoom-in-btn" onClick={() => zoomIn()}></button>
					<button className="zoom-controls zoom-out-btn" onClick={() => zoomOut()}></button>
				</div>
			</>
		);
	};
	let dataLength;
	if (props.loading == false) {
		dataLength = (Object.values(blockedSessionsGeographyData?.blockedSessionGeography.data.bot));
	};

	return (

		<div className="blockedSessions-graphs-section">

			<div className="BlockedSessions-GeographyGraph" onMouseEnter={() => setZoomTooltip(true)} onMouseLeave={() => setZoomTooltip(false)}>
				{dataLength?.length > 0 ?
					<TransformWrapper wheel={{ activationKeys: ["Control"] }} >
						<h4 className={zoomTooltip ? "zoom-tooltip" : "hideToolTip"}>Ctrl + Scroll To Zoom</h4>
						<TransformComponent>
							<div className={sideToggle ? "toggle-active-geoGraph" : "toggle-deactive-geoGraph"}>
								<div className={props.loading ? "loading-bots-activity-map" : "bots-activity-map"}>
									{props.loading ? <Loader /> : <GeographyGraph data={blockedSessionsGeographyData?.blockedSessionGeography.data} />}
									{/* {props.loading ? <Loader /> : <GeographyGraph data={blockedSessionsGeographyData?.blockedSessionGeography.data} />} */}
								</div>
							</div>
						</TransformComponent>
						<Controls />
					</TransformWrapper> :
					<TrafficMessage message="There has been no traffic in the selected time period." />}
			</div>
			<div className="BlockedSessions-WaffleGraph"  >
				{props.loading ? <Loader /> : <CountriesLineGraph data={blockedSessionsGeographyData?.botsCountriesByDate?.item} />}
			</div>
			<div className="BlockedSessions-HeatMap">
				{props.loading ? <Loader /> : <ReferralSources data={blockedSessionsGeographyData?.botsReferrersByDate.item} />}
			</div>
			<div className="countries-table-list">
				{props.loading ? <Loader /> : <CountriesTable data={blockedSessionsGeographyData?.blockedSessionGeography.data} />}
			</div>
			<div className="countries-lineGraph-BrowserGraph" >
				{props.loading ? <Loader /> : <BlockedBrowserLineGraph data={browserGraphData} />}
			</div>
			<div className="referral-OSlineGraph">
				{props.loading ? <Loader /> : <BlockedOSLineGraph data={blockedOSGraphData} />}
			</div>
		</div>
	)
};

export default BlockedSessionsGeographyPanel;