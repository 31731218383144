import React from "react";
import TrafficMessage from "../../../../SharedComponents/TrafficMessage/TrafficMessage";
import { useSelector } from "react-redux";
import GraphsToolTip from "../../../../SharedComponents/GraphsToolTip/GraphsToolTip";

const AffiliateTable = () => {
	const affiliatesData = useSelector(state => state.sessionAffiliates.data);
	const headers = ["Affiliate", "Total Humans", "Total Bots", "Bot Percentage"];
	const formatData = () => {
		var result = [];
		let id = 0;
		const uniqueAffiliates = [...new Set(Object.keys(affiliatesData.bot).concat(Object.keys(affiliatesData.human)))];

		uniqueAffiliates.forEach(affiliate => {
			const botCount = affiliatesData.bot[affiliate] || 0;
			const humanCount = affiliatesData.human[affiliate] || 0;
			const existing = result.find(obj => obj.affiliate === affiliate);

			if (existing) {
				existing.total_Bots += botCount;
				existing.total_Humans += humanCount;
			} else {
				if (affiliate !== "No Affiliate" && affiliate !== "null") {
					result.push(
						{ id, affiliate, total_Bots: botCount, total_Humans: humanCount, bot_percentage: ((botCount / (botCount + humanCount)) * 100).toFixed(1) }
					);
					id++;
				}
			}
		});

		result = result.sort(({ bot_percentage: a }, { bot_percentage: b }) => b - a);
		return result;
	}

	const formattedData = formatData();

	return (
		<>
			{formattedData.length !== 0 ?
				<>
					<div className="table-container-parent">
						<div className="graph-header">
						</div>
						<div className="table-container">
							<div className="table" style={{ background: "white", width: "100%" }}>
								<div className="lheader table-row" style={{ height: "2vh" }}>
									{headers.map((items) =>
										<div className="cell">{items}</div>
									)
									}
								</div>
								<div className="tbody" style={{ whiteSpace: "nowrap" }}>
									{
										formattedData?.map((items) =>
											<div className="table-row" key={items.id}>
												<div className="cell affiliate-name" title={items.affiliate}>{items.affiliate}</div>
												<div className="cell" title={items.total_Humans}>{items.total_Humans}</div>
												<div className="cell" title={items.total_Bots}>{items.total_Bots}</div>
												<div className="cell" title={items.bot_Percentage}>{items.bot_percentage}%</div>
											</div>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</>
				:
				<div style={{ background: "white", position: "relative", minHeight: "30vh" }}>
					<TrafficMessage message="No affiliate traffic in the time period selected." />
				</div>
			}
		</>
	)
}

export default AffiliateTable;