import React from "react";
import "./Notification.scss";

const Notification = () => {
	return (
		<div className="notification-section">
			<h3>Can I get real-time notifications and reports on bot attacks?</h3>
			<p>
			They’re both at your fingertips on the Web Threat Protection dashboard.
			</p>
		</div>
	);
};

export default Notification;