import React from "react";
import { formatNumber } from "Utils/utils";

const VisitedBots = (props) => {
	const websiteStatus = props.botsString;
	const totalCount = formatNumber(props?.activeBots?.totalcount);
	const botsVisited = props.botsVisited?.visits;
	const websiteName = localStorage.getItem("websiteName");
	const todayBlockedBots = formatNumber(props.todayBlockedBots?.totalcount);

	const getBotsAndPages = (data) => {
		let total = 0;
		for (let i = 1; i <= 4; i++) {
			total += data[i]
		}
		return [total, data[`5+`]]
	};

	const botsAndPages = getBotsAndPages(botsVisited);

	const CurrentBots = () => {
		return (
			<>
				<div className={`currentBots-container Blocked`}>
					<div className="bots-percentage-value">{totalCount} Bots</div>
					<p>{websiteStatus === "Blocked" ? `Just blocked on` : `Currently on `} {websiteName}</p>
					{websiteStatus === "Detected" &&
						<>
							<div className="bots-percentage-value">{formatNumber(websiteStatus === "Blocked" ? totalCount : botsAndPages[1])} Bots</div>
							<p>{websiteStatus === "Blocked" ? `Just blocked on ${websiteName}` : `Visited 5 or more pages`}</p>
						</>
					}
					{websiteStatus === "Blocked" &&
						<>
							<div className="bots-percentage-value">{todayBlockedBots} Bots</div>
							<p>Blocked today</p>
						</>
					}
				</div>
			</>
		)
	};

	// const VisitedBots = () => {
	// 	return (
	// 		<div className={websiteStatus === "Blocked" ? "protect-mode" : "visitedBots-container"}>
	// 			<div className="bots-percentage-value">{websiteStatus === "Blocked" ? totalCount : botsAndPages[1]} Bots</div>
	// 			<p>{websiteStatus === "Blocked" ? `Just blocked on ${websiteName}` : `Visited 5 or more pages`}</p>
	// 			<div className="bots-percentage-value">{websiteStatus === "Blocked" ? todayBlockedBots : botsAndPages[0]} Bots</div>
	// 			<p>{websiteStatus === "Blocked" ? `Blocked today on ${websiteName}` : `Visited less than 5 pages`}</p>
	// 		</div>
	// 	)
	// };

	return (
		<>
			<CurrentBots />
			{/* <VisitedBots /> */}
		</>

	)
};

export default VisitedBots;