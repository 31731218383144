import React from "react";
import "./Pagination.scss";

const Pagination = (props) => {

	const getNumberOfPages = () => {
		let total= props?.paging?.total;
		let filterPages = Math.ceil(total / props.pageLimit);

		if (filterPages < 7) {
			let result = [];
			for (let i = filterPages; i >= 1; i--) {
				result.push(i)
			};
			return result.reverse();
		} else {
			if (parseInt(props.currentPage) % 5 >= 0 && parseInt(props.currentPage) > 4 && parseInt(props.currentPage) + 2 < filterPages) {
				return [1, parseInt(props.currentPage) - 1, parseInt(props.currentPage), parseInt(props.currentPage) + 1, filterPages];
			} else if (parseInt(props.currentPage) % 5 >= 0 && parseInt(props.currentPage) > 4 && parseInt(props.currentPage) + 2 >= filterPages) {
				return [1, filterPages - 3, filterPages - 2, filterPages - 1,filterPages];
			} else {
				return [1, 2, 3, 4, 5, filterPages];
			}
		}
	};

	const numberofPages = getNumberOfPages();

	const handlePagination = (e,values) => {
		props.setCurrentPage(parseInt(values))
	};

	const handleDecrementArrow = () => {
		if (props.paging.prev !==null ) {
			props.setCurrentPage(parseInt(props.currentPage) - 1)
		};
	};

	const handleIncrementArrow = () => {
		if (props.paging.next !==null ) {
			props.setCurrentPage(parseInt(props.currentPage) + 1)
		};
	};


	return (
		<div className="pagination">

			<button onClick={handleDecrementArrow} className={props.paging.prev ===null ? "decrement-button-disabled" : "decrement-button"} disabled={props.paging.prev ===null}>
				&laquo;
			</button>
			{numberofPages.map((page, index, values) =>
				<div className={props.currentPage == values[index] ? "active" : ""} onClick={(e) => handlePagination(e,values[index])}>  {values[index - 1] + 2 < page ? `...${page}` : page}</div>
			)}

			<button onClick={handleIncrementArrow} className={props.paging.next ===null ? "decrement-button-disabled" : "decrement-button"} disabled={props.paging.next ===null}>
				&raquo;
			</button>
		</div>
	)
};

export default Pagination;