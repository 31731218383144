import React from "react";
import "./LoginFoot.scss";

const LoginFoot = (props) => {
	const appVersion=localStorage.getItem("version")
	return (

		<div>
			<footer className="footer">
				<p> Version {JSON.parse(appVersion)?.Version}</p>
			</footer>
		</div>
	)
};
export default LoginFoot;