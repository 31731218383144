import React from "react";
import "./Reports.scss"

import api from "../../veracityapi";
const Reports = () => {
    React.useLayoutEffect(() => {
        document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
    }, []);
    return (
        <>
         
            <div style={{ display: "flex", justifyContent: "center", paddingTop: 200 }}>
                <button onClick={api.logout}>SignOut</button>
                This is Reports
            </div>
        </>
    )
};

export default Reports;