import React from "react";
import "./DateDropDown.scss";
import CalendarComponent from "./DateDropDownComponents/CalendarComponent.jsx";
import { DownArrow, UpArrow } from "../../SVG/DropDown";


const DateDropDown = (props) => {
	const value = props.value;
	const setValue = props.setValue;
	const dateDropDownValues=props.dateDropDownValues;
	const [active, setActive] = React.useState(false);
	const [displayModal, setDisplayModal] = React.useState(false);
	const dateDropdownRef = React.useRef(null);

	const handleOutSideClick = ((e) => {
		if (dateDropdownRef.current && !dateDropdownRef.current.contains(e.target)) {
			setActive(false)
		}
	});

	React.useEffect(() => {
		document.addEventListener("click", handleOutSideClick);
		return () => {
			document.removeEventListener("click", handleOutSideClick)
		}
	}, []);

	return (
		<div className={active === false ? 'date-drop-down' : 'date-drop-down2'} ref={dateDropdownRef} >
			<div className='date-drop-down-option-selected' onClick={() => { setActive(!active) }}><span>{value?.timeRange}</span> {active ? <UpArrow /> : <DownArrow />}</div>
			{
				dateDropDownValues.map((val) => {
					if (active) {
						return (
							<div key={val} className='date-drop-down-option' onClick={() => { setValue({ ...value, timeRange: val }); setActive(!active) }}><span>{val}</span></div>
						);
					};
				})
			}
			{(active && dateDropDownValues.length > 2) && <div key={"Custom"} className='date-drop-down-option' onClick={() => { setDisplayModal(true); setActive(!active) }}><span>{"Custom"}</span></div>}
			{displayModal && <CalendarComponent setDisplayModal={setDisplayModal} value={value} setValue={setValue} />}
		</div>
	);
};

export default DateDropDown;