import React from "react";

import { useSelector } from "react-redux";
import { DownArrow as DropDown } from "../../SVG/DropDown";
import "./Features.scss"
import AccountManagerSidebar from "../../SharedComponents/AccountManageSideBar/AccountManageSideBar";
import api from "../../veracityapi"
const Features = () => {
	const state = useSelector(state => state.toggle.toggle);
	const [openFeature, setOpenFeature] = React.useState(false);



	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};

	const handleDropdown = () => {
		setOpenFeature(!openFeature)
	};
	return (
		<div>
	
			<div className={state ? "content-active" : "content"}>
				{/* <div className="side-bar"> <AccountManagerSidebar /></div> */}
				<div className="help-features">
					<div className="help-heading">Help{` >`} <span className="feature-heading">Features</span></div>
					<div className="all-features">
						<h3>Features</h3>
						<p>
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
							sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
							aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
							tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
							Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie
							consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan
							et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis
							dolore te feugait nulla facilisi.
						</p>
						<div className="feature-list">
							<div className="feature">
								<h4>Feature 1</h4>
								<div className={openFeature?"open-arrow":"close-arrow"} onClick={handleDropdown}><DropDown /></div>
							</div>
							{openFeature && <p>
								Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
								sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
								aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
								tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
								Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie
								consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan
								et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis
								dolore te feugait nulla facilisi.
							</p>}
						</div>
						<div className="feature-list">
							<div className="feature">
								<h4>Feature 2</h4>
								<div  className={openFeature?"open-arrow":"close-arrow"} onClick={handleDropdown}><DropDown /></div>
							</div>
							{openFeature && <p>
								Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
								sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
								aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
								tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
								Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie
								consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan
								et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis
								dolore te feugait nulla facilisi.
							</p>}
						</div>
						<div className="feature-list">
							<div className="feature">
								<h4>Feature 3</h4>
								<div  className={openFeature?"open-arrow":"close-arrow"} onClick={handleDropdown}><DropDown /></div>
							</div>
							{openFeature && <p>
								Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
								sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
								aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
								tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
								Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie
								consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan
								et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis
								dolore te feugait nulla facilisi.
							</p>}
						</div>
						<div className="feature-list">
							<div className="feature">
								<h4>Feature 4</h4>
								<div  className={openFeature?"open-arrow":"close-arrow"} onClick={handleDropdown}><DropDown /></div>
							</div>
							{openFeature && <p>
								Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
								sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
								aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
								tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
								Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie
								consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan
								et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis
								dolore te feugait nulla facilisi.
							</p>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Features;