import React, { useEffect, useState } from "react";
import styles from './index.module.scss'
import { Adnetworks } from "../../../AFP/Constants";
import { bApiV8 as api } from "../../../../API/BeaconAPI";
import { TickIcon } from "../../../../SVG/TickIcon";
import Loader from "../../../../SharedComponents/Loader/Loader";

const Table1 = () => {
    const websiteID = api.getWebsiteId();
    const [showDialog, setShowDialog] = useState(false);
    const [conState, setConState] = useState([]);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    let displayMessage = true;

    const handleDisconnect = (option) => {
        setLoading(true);
        api.DELETE(`2.0/connection/${option.connectionID}`).then(res => {
            setUpdate(!update);
            setLoading(false);
        }).catch((error) => {
            setUpdate(!update);
            setLoading(false);
        });

    }
    const getAllData = (url, item) => new Promise((resolve, reject) => {
        api.GET(`2.0/website/${websiteID}/connection/${url}`).then(res => {
            resolve(
                { result: res.response, item }
            );
        }).catch((error) => {
            resolve(
                { result: [], item }
            );
            console.log(error);
        });
    });
    useEffect(() => {
        const aPromises = []
        setLoading(true);
        Adnetworks.map(i => aPromises.push(getAllData(i.connectionURL, i)));
        Promise.all(aPromises).then((args) => {
            const aTemp = []
            args.map(m => {
                let i = m.result;
                let item = m.item
                aTemp.push({
                    name: i?.display_name || null,
                    connectionID: i?.connection_id,
                    token_for: item?.connectionURL,
                    accountID: i?.account_id,
                    showConnection: !!i?.account_id,
                    icon: item.icon,
                    connectorHidden: item.connectorHidden !== undefined ? item.connectorHidden : false
                })
            });
            setConState(aTemp)
            setLoading(false);
        })

    }, [update]);

    return (<>
        {showDialog ? <Dialog data={conState} onClose={() => {
            setShowDialog(false);
            setUpdate(!update)
        }} /> : null}
        <div className={styles['main']}>
            <div className={styles['flex-header']}>
                <h3>Your Ad Network Connections</h3>
                {loading ? null :
                    <div className={styles['button']} onClick={() => { setShowDialog(true) }}>Add Connection</div>
                }
            </div>
            {loading ? <Loader className={'loader' + ' ' + styles['loader-animation']} />
                : <div className={styles['table']}>
                    {conState.map((i, j) => {
                        if (!i.connectorHidden) {
                            return (i?.connectionID ?
                                <div key={j} className={styles['flex'] + ' ' + (loading ? styles['loader'] : '')}>
                                    <div className={styles['text']}>{i?.token_for || '-'}</div>
                                    <div className={styles['text'] + ' ' + (i?.name ? '':styles['error'])}>{i?.name || 'Unable to fetch account details'}</div>
                                    <div className={styles['text']}>{i?.accountID ? 'connected' : 'disconnected'}</div>
                                    <div className={styles['text']} style={{ textAlign: "right", paddingBottom: 5 }}>
                                        <button onClick={() => { handleDisconnect(i) }} className={styles['button'] + ' ' + styles['remove']}>Remove</button>
                                    </div>
                                </div> : null)
                        }
                    })}
                </div>}
        </div>
    </>)
}


const SuccesfullyConected = ({ selectedOption, onClose }) => {
    return (
        <div>
            <TickIcon />
            <h3 className={styles["selected-option"]}>Successfully Connected to {selectedOption?.adnetwork?.token_for} </h3>
            <button onClick={() => onClose()} className={styles["okay-btn"]}>Okay</button>
        </div>
    )
};
const Dropdown = ({ header, options = [], handleOptionClick, displayLabel }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentHeader, setCurrentHeader] = useState(header);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className={styles["dropdown-container"]}>

            <div className={styles["dropdown-header"]} onClick={toggleDropdown}>
                {currentHeader}
                <span>&#9662;</span>
            </div>

            {isOpen && (
                <ul className={styles["dropdown-options"]}>
                    {options.map((option, index) =>
                    (<li key={index} onClick={() => {
                        toggleDropdown();
                        setCurrentHeader(option[displayLabel]);
                        handleOptionClick(option)
                    }}>
                        {option[displayLabel]}
                    </li>)
                    )}
                </ul>
            )}
        </div>)
}
const Dialog = ({ onClose, data }) => {
    const websiteID = api.getWebsiteId();

    const [selectedOption, setSelectedOption] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [fetchAccounts, setFetchAccounts] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [curConnectionID, setCurConnectionID] = useState(0);
    const [status, setStatus] = useState(null);
    let connDialog = null;
    let prevURL = null;
    let platforms = data.filter(i => !i.connectionID);
    const receiveMessage = (msg) => {
        console.log(msg)
    }
    useEffect(() => {
        let option = selectedOption?.adnetwork || {};
        if ((option?.connectionID && !option?.accountID)) {
            getAccounts(option?.connectionID).then(elem => {
                let accounts = elem.accounts[0]?.children || elem.accounts
                setAccounts(accounts);
            }).catch(error => {
                setStatus({ msg: error, error: true });
            })
        } else if (!option?.connectionID) {
            handleConnect(option);
        }
    }, [selectedOption?.adnetwork])
    const handleOptionClick = (option) => {
        setShowConfirm(false);
        setSelectedOption({ ...selectedOption, adnetwork: option });

    };
    const handleAccountClick = (option) => {
        setShowConfirm(true);
        setSelectedOption({ ...selectedOption, account: option });
        return false;
    }
    const getAccountData = (url) => new Promise((resolve, reject) => {
        if (url) {
            api.GET(`2.0/website/${websiteID}/connection/${url}`).then(res => {
                resolve(
                    { result: res.response }
                );

            }).catch((error) => {
                resolve(
                    { result: [] }
                );
            });
        }

    });
    const confirmConnection = () => {
        let connectionID = selectedOption?.adnetwork?.connectionID || curConnectionID;
        new Promise((resolve, reject) => {
            api.POST(`2.0/connection/${connectionID}`, {
                "website_id": Number(websiteID),
                "token_for": selectedOption?.adnetwork?.token_for,
                "account_id": selectedOption?.account?.id,
                "login_id": selectedOption?.account?.loginId
            }).then(res => {
                resolve(
                    res.response
                );
                setStatus({ success: true })
            }).catch((error) => {
                reject(
                    []
                );
                setStatus({ error: true })
            });
        })
    }
    useEffect(() => {
        getAccountData(selectedOption?.adnetwork?.token_for).then(({ result }) => {
            setCurConnectionID(result?.connection_id);
            getAccounts(result?.connection_id).then(elem => {
                let accounts = elem?.accounts[0]?.children || elem.accounts
                setAccounts(accounts);
            })
        }).catch(error => {
            setShowMessage(true);
        })

    }, [fetchAccounts]);
    const getAccounts = (connectionID) => new Promise((resolve, reject) => {

        if (connectionID && connectionID != "") {
            api.GET(`2.0/connection/${connectionID}/accounts`).then(res => {
                resolve(
                    { accounts: res.response, accountsExist: true }
                );
            }).catch((error) => {
                reject(
                    { accounts: [], accountsExist: false }
                );
            });
        } else {
            resolve(
                { accounts: [], accountsExist: false }
            );
        }
    });

    const handleConnect = (option) => {
        if (option?.token_for) {
            createConnection(option.token_for).then(url => {
                var x = window.screen.width / 2 - 500 / 2;
                var y = window.screen.height / 2 - 600 / 2;
                window.removeEventListener('message', receiveMessage);
                setShowMessage(true);
                if (connDialog === null || connDialog.closed) {
                    connDialog = window.open(url, 'connDialog', `toolbar=no, menubar=no, width=500, height=600, status=no, left=${x}, top=${y}`);
                } else if (prevURL !== url) {
                    connDialog = window.open(url, 'connDialog', `toolbar=no, menubar=no, width=500, height=600, status=no, left=${x}, top=${y}`);
                    connDialog.focus();
                } else {
                    connDialog.focus();
                }
                if (connDialog?.closed) {
                    alert('done');
                }
                var timer = setInterval(function () {
                    if (connDialog.closed) {
                        clearInterval(timer);
                        // setLoading(false);
                        setFetchAccounts(!fetchAccounts);
                        setShowMessage(false)
                    }
                }, 500);
                window.addEventListener('message', event => receiveMessage(event), false);
                prevURL = url;
            })
        }
    }
    const createConnection = (network) => new Promise((resolve, reject) => {
        api.POST(`2.0/connection`, {
            "website_id": Number(websiteID),
            "token_for": network
        }).then(res => {
            resolve(
                res.response
            );
        }).catch((error) => {
            resolve(
                []
            );
        });
    });
    return (
        <div className={styles["modal-bg"]}>
            <div className="login-form" style={{ height: "39vh" }}>
                <div className={styles["modal-header"]}>
                    <h3 className={styles["modal-heading"]}> Add New Connection</h3>
                    <p className={styles["close-mark"]} onClick={() => onClose()}  >X</p>
                </div>
                {!status?.success ? <>


                    <Dropdown header={"Select Ad Network"}
                        options={platforms} displayLabel={'token_for'}
                        handleOptionClick={handleOptionClick} />
                    {accounts?.length ? <Dropdown header={"Select an Account"}
                        options={accounts} displayLabel={'name'}
                        handleOptionClick={handleAccountClick} /> : null}
                    {showConfirm ? <button className={styles['confirm-button']} onClick={confirmConnection} >Confirm</button> :
                        showMessage ? <div>Please complete the authorisation process
                            <div>(opens in a new browser window)</div>
                        </div> : null}
                </> :
                    <SuccesfullyConected onClose={onClose} selectedOption={selectedOption} />}

                <>

                </>

            </div>
        </div>
    )
}
export default Table1;