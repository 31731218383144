import React from "react";
import RiskMeasurement from "components/Today/TodayPanels/BotRisk/BotRiskComponents/RiskMeasurement";
import BotIntentions from "components/Today/TodayPanels/BotRisk/BotRiskComponents/BotIntentions";
import BotType from "components/Today/TodayPanels/BotRisk/BotRiskComponents/BotType";
import { useSelector } from "react-redux";
import { riskLevels, colors, classificationColors, botClassificationName } from "components/AFP/Constants";

const BotRiskForBotActivity = ({ loading }) => {
	const finishedSessionsData = useSelector(state => state?.finishedSessions?.data || []);
	const websiteRiskData = useSelector(state => state?.botActivityBotRiskDetails?.data);
	const doubleBreakdown = useSelector(state => state?.botActivityBotRiskDetails?.data?.botClassification);
	const botRiskType = useSelector((state) => state?.botActivityBotRiskDetails?.data?.botRiskType)
	const botClassification = useSelector((state) => state?.botTypeClassification?.data?.botTypeClassification);
	const websiteStatus = useSelector((state) => state.websiteDetails?.data?.websiteDetails?.status);

	function getRangeName() {
		switch (finishedSessionsData.timeRange) {
			case "Prev 7 Days":
				return "in the last 7 days"
			case "Prev 14 Days":
				return "in the last 14 days"
			case "Prev 28 Days":
				return "in the last 28 days"
			case "this month":
				return "in the last 7 days"
			case "Last Month":
				return "in the last month"
			default:
				return "in the selected time frame"
		}
	};
	// console.log("website",websiteRiskData)
	function getTickSize() {
		const values = Object.keys(websiteRiskData?.item).length ?? 0;
		if (values > 20) {
			return 15
		} else {
			return values
		}
	}
	// const classificationColors = {
	// 	"Scraper": "#b296c8",
	// 	"Crasher": "#662D91",
	// 	"Account Faker": "#908b93",
	// 	"Data Thief": "#f4b79e",
	// 	"Basket Blocker": "#211728",
	// 	"Fake Poster": "#ff4900",
	// 	"Ad Clicker": "#a4daad",
	// 	"Social Pretender": "#83c6eb",
	// 	"Attack Scout": "#EA6F3E",
	// 	"Scanner": "#49B65B",
	// 	"Ghost": "#068DD7"
	// };

	// const botClassificationName = {
	// 	1: "Scraper",
	// 	2: "Crasher",
	// 	3: "Account Faker",
	// 	4: "Data Thief",
	// 	5: "Basket Blocker",
	// 	6: "Fake Poster",
	// 	7: "Ad Clicker",
	// 	8: "Social Pretender",
	// 	9: "Attack Scout",
	// 	10: "Scanner",
	// 	11: "Ghost"
	// };
	const tickValues = getTickSize();
	const rangeName = getRangeName();

	const formatBotTypeData = () => {
		let botTypeTotals = {};
		for (let dates in doubleBreakdown.item) {
			for (let id in doubleBreakdown.item[dates].bot) {
				if (id !== "" && id != 0) {
					if (botTypeTotals[id] === undefined) {
						botTypeTotals[id] = {
							id: parseInt(id),
							label: botClassificationName[id],
							value: doubleBreakdown?.item[dates]?.bot[id],
							color: classificationColors[botClassificationName[id]]
						}
					} else {
						botTypeTotals[id].value += doubleBreakdown?.item[dates]?.bot[id];
					}
				}

			}
		};
		return Object.values(botTypeTotals).sort(function (a, b) {
			return b.value - a.value;
		});
	};

	const formattedBotTypeData = formatBotTypeData();
	return (
		<div className="bot-risk-section">
			<RiskMeasurement websiteRiskData={websiteRiskData} loading={loading} tickValues={tickValues} title={websiteStatus === "protect" ? "Mitigated Threat Level" : "Threat Level"} rangeName={rangeName} />
			<BotType formattedData={formattedBotTypeData} loading={loading} title={websiteStatus === "protect" ? "Blocked Bot Types" : "Bot Types"} />
			<BotIntentions botRiskType={botRiskType} botClassification={botClassification} loading={loading} formattedBotTypeData={formattedBotTypeData} title={websiteStatus === "protect" ? "Mitigated Threat Breakdown" : "Threat Breakdown"} />
		</div>
	)

};

export default BotRiskForBotActivity;