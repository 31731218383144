import React from "react";
import "./DetectionUpdate.scss";

const Detection = () => {
	return (
		<div className="Detection-section">
			<h3>Bots evolve quickly. How often is your detection updated?</h3>
			<p>
			When machine learning is being used to develop smarter and smarter bots, an AI-powered response is absolutely necessary. It means that we’re prepared for attacks that haven’t even been developed yet. We refine our products daily and, as a service, you’ll always automatically be up to date.
			</p>
		</div>
	);
};

export default Detection;