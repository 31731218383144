import React from "react";
import "./MyPlans.scss";
import { useSelector } from "react-redux";
import api from "../../../veracityapi";
import Permission from "../../../SharedComponents/PermissionsMessage/Permissions";
import { decodeCurrency } from "../../../Utils/utils";

const MyPlans = (props) => {
	// const [loading, setLoading] = React.useState(false);
	const [permissionMessage, showPermissionMessage] = React.useState(false);
	const packages = useSelector(state => state?.websiteDetails?.data?.customerPackages);
	const customerGUID = localStorage.getItem("selected_customer");
	const currentPackageValue = packages?.find((val) => val.current === 1).cost_pm;
	const currenySymbol = localStorage.getItem("bcn-currency-symbol");
	const [selectedOption, setSelectedOption] = React.useState('Monthly');
	const [paymentPlan, setPaymentPlan] = React.useState('monthly');

	const handleSwitchChange = (option) => {
		if (selectedOption !== option) {
			setSelectedOption(option);
			setPaymentPlan(option === 'Monthly' ? 'monthly' : 'annual');
		}
		if (option === "Annually Save 15%") {
			let movingBackground = document.getElementById('switch-background');
			movingBackground.style.transform = 'translateX(90px)';
			movingBackground.style.width = '148px';
		} else if (option === "Monthly") {
			let movingBackground = document.getElementById('switch-background');
			movingBackground.style.transform = 'translateX(0px)';
			movingBackground.style.width = '90px';
		}
	}

	let paymentPlanText = (paymentPlan === "monthly") ? ("month") : ("year")

	// let currentPayment = (paymentPlan === "monthly") ? (packages.cost_pm) : (packages.cost_pa)

	const handlePlanStatus = async (plans) => {
		let filterData = plans?.find((val) => val.current === 1)
		let activePlanCost = filterData.cost_pm;

		return parseFloat(activePlanCost)
	};
	let currentActivePlanCost = handlePlanStatus(packages);

	// console.log(paymentPlan, "payment plan")

	const updatePackage = (package_guid, price) => {

		if (price !== "0.00") {
			api.GET(`/stripe/${customerGUID}/checkout/${package_guid}/${paymentPlan}`)
				.then(res => {
					// setLoading(false)
					const Url = res.item.stripe_redirect_url;
					window.location.href = Url;
				}).catch((e) => console.log)
		}
		else {
			api.GET(`/stripe/${customerGUID}/cancel/`)
				.then(res => {
					// setLoading(false)
					const Url = res.item.stripe_redirect_url;
					window.location.href = Url;
				}).catch((e) => console.log)
		}
	};

	const handleNonPrimaryUsers = () => {
		if (props.primaryUser === false) {
			showPermissionMessage(true)
			setTimeout(() => {
				showPermissionMessage(false)
			}, 10000)
		};
	};

	let forumSupport = (val) => {
		if (val === 1) {
			return (<p>Forum Support</p>)
		}
		else if (val === 2) {
			return (<p>Priority forum support</p>)
		}
		else if (val === 3) {
			return (<p>Priority forum & email support</p>)
		}
	}

	return (
		<div className="plans-section">
			<h3 className="plan-heading">Company plan</h3>
			<h4 className="plan-description">The bot threat may be universal, but your needs aren’t. We offer flexible pricing tiers to make sure you get the things you need.</h4>
			<div className="switch-container-parent">
				<div className="switch-container">
					<div className="switch-background" id="switch-background"></div>
					<div className="switch-options-container" id="switch-options-container">
						<div
							className={`switch-option ${selectedOption === 'Monthly' ? 'selected' : ''}`}
							onClick={() => handleSwitchChange('Monthly')}>
							Monthly
						</div>
						<div
							className={`switch-option ${selectedOption === 'Annually Save 15%' ? 'selected' : ''}`}
							onClick={() => handleSwitchChange('Annually Save 15%')}>
							Annually Save 15%
						</div>
					</div>
				</div>
			</div>
			{packages?.map((value, index) => (
				<div key={value.guid} className={`${value.current === 1 ? "plans-dark" : "plans-light"}`}>
					<h2 className="free-text">{value.package_name}</h2>
					<div className="plan-description-text-container">
						<h4 className="plan-description-text">{value.package_description}</h4>
					</div>
					<div className="business-heading">
						<span className="currency-symbol" >{decodeCurrency(value.currency_symbol)} </span>
						<span className="price">{ Math.trunc(`${(paymentPlan === "monthly") ? (value.cost_pm) : (value.cost_pa)}`)}<span className="per-payment-plan">per {paymentPlanText}</span></span>
					</div>
					{/* <i>Forever</i> */}
					<div style={{ width: "100%" }} onClick={handleNonPrimaryUsers} onMouseEnter={handleNonPrimaryUsers}>
						<button
							// onClick={() => updatePackage(value.guid, selectCurrentPaymentPlan(`${(paymentPlan === "monthly") ? (value.cost_pm) : (value.cost_pa)}`))}
							onClick={() => updatePackage(value.guid, `${(paymentPlan === "monthly") ? (value.cost_pm) : (value.cost_pa)}`)}
							className={value.current === 1 || !props.primaryUser ? "plan-button-disabled" : "plan-button"}
							disabled={(value.current === 1 || !props.primaryUser) && true}>
							{`${value.current === 1 ? "Active" : (+value.current === 0 && +value.cost_pm < currentPackageValue) ? "Downgrade" : "Upgrade"}`}
						</button>
					</div>
					<div className={`features-list ${value.current === 1 ? "features-list-dark" : "features-list-light"}`}>
						<p>{value.visits_per_month} website visits per month</p>
						<p>{value.distinct_urls} distinct {value.distinct_urls > 1 ? "URLs" : "URL"}</p>
						{value.users > 1 && (<p>{value.users} users</p>)}
						{value.mobile_app !== 0 && (<p>Mobile App</p>)}
						{value.web_threat !== "none" && (value.web_threat === "oneoff" ? <p>One-off proactive website threat warning</p> : <p>{value.web_threat[0].toUpperCase() + value.web_threat.slice(1)} website threat warning</p>)}
						{value.api !== 0 && (<p>API available</p>)}
						{value.user_perms !== 0 && (<p>Domain level user permissions</p>)}
						{forumSupport(value.support)}
					</div>
					{/* {!loading ? */}
					{/* <button className="processing-button">Processing</button> */}
					{/* } */}
				</div>
			))
			}
			<div className="plans-light">
				<h2 className="enterprise-text">Bespoke</h2>
				<div>
					<h4 className="plan-description-text  ">Designed to meet the needs of larger organisations and those with specific requirements,
						to ensure comprehensive AI enabled web threat protection against malicious bots.</h4>
				</div>
				<div className="business-heading">
					<span className="price bespoke-money">POA</span>
				</div>
				<a href="https://veracitytrustnetwork.com/talk-to-us/" target="_blank" rel="noreferrer"><button className="plan-button">Get in touch</button></a>
				{/* <p>Contact us today to discuss your bespoke.</p> */}
			</div>
			{permissionMessage && <Permission permissionMessage="Only primary users can change the package you are subscribed to." />}
		</div>
	)

}


export default MyPlans;