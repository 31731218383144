import React from "react";
import "./Users.scss"
import NewUser from "../NewUser/NewUser";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../../SharedComponents/Pagination/Pagination";
import Permission from "../../../SharedComponents/PermissionsMessage/Permissions";
import TrafficMessage from "../../../SharedComponents/TrafficMessage/TrafficMessage";
import EditUser from "./UsersComponents/EditUser";
import ConfirmRemove from "./UsersComponents/ConfirmRemove";
import api from "../../../veracityapi";
import Loader from "../../../SharedComponents/Loader/Loader";
import { usersDetails } from "../../../Redux/actions";

const Users = () => {
	const dispatch = useDispatch();
	const [permissionMessage, showPermissionMessage] = React.useState(false);
	const [inviteUserModal, setInviteUserModal] = React.useState(false);
	const [editUserModal, setEditUserModal] = React.useState(false);
	const [removeUserModal, setRemoveUserModal] = React.useState(false);
	const usersData = useSelector((state) => state?.users?.data);
	const invites = useSelector((state) => state?.websiteDetails?.data?.invites);
	const primaryUser = localStorage.getItem("primaryUser");
	const customerGUID = localStorage.getItem("selected_customer");
	const [page, setPage] = React.useState(1);
	const customerPackages = useSelector(state => state?.websiteDetails?.data?.customerPackages);
	const currentPackage = customerPackages?.find((val) => val?.current === 1);
	let limit = 5;

	const usersForCustomer = () => {
		api.paged(`/customer/${customerGUID}/user/`, limit, page)
			.then(res => {
				dispatch(usersDetails(res));
			}).catch((error) => {
				api.handleError(error);
			});
	};

	React.useEffect(() => {
		usersForCustomer();
	}, [page]);

	const openInviteUserModal = () => {
		setInviteUserModal(true);
	};
	let  mergeData=[];

	if(usersData!==undefined){
		mergeData=[...invites.item, ...usersData?.items];
	};


	const checkInviteUserButtonDisability = () => {
		if (primaryUser === "true" && mergeData.length <= currentPackage.users) {
			return false;
		};
		return true;
	};

	const checkRemoveUserButtonDisability = () => {
		if (primaryUser === "true") {
			return false;
		};
		return true;
	};

	const handleNonPrimaryUsers = async () => {
		if (primaryUser === "false") {
			showPermissionMessage("true")
			setTimeout(() => {
				showPermissionMessage(false)
			}, 10000)
		};
	};

	const disable = checkInviteUserButtonDisability();
	const removeDisable = checkRemoveUserButtonDisability();

	if (inviteUserModal) {
		document.body.classList.add("active-modal")
	} else {
		document.body.classList.remove("active-modal")
	};

	return (
		<>
			<div className="users">
				<div className="invite-users">
					<h3>Company Members</h3>
					<button className={disable ? "edit-button-disable" : "edit-button"} onClick={openInviteUserModal} disabled={disable}>Invite User</button>
				</div>
				{usersData !== undefined ?
					<table className="user-table">
						<thead>
							<tr>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Email</th>
								<th>Status</th>
								<th></th>
							</tr>
						</thead>

						{mergeData.length !== 0 ?
							<tbody>
								{mergeData.map((user, index) => (
									<tr key={index} className="user-row">
										<td>{user.first_name ?? "-"}</td>
										<td>{user.last_name ?? "-"}</td>
										<td>{user.email}</td>
										<td>{user.primary_user ? "Primary User" : typeof user.primary_user !== "undefined" ? "User" : "Invited"}</td>
										<td onClick={handleNonPrimaryUsers} style={{ padding: "10 10" }}>
											<button style={{ visibility: user.primary_user === true || typeof user.primary_user === "undefined" ? "hidden" : "visible" }} className={!removeDisable ? "edit-button" : "edit-button-disable"} disabled={removeDisable} onClick={() => { setEditUserModal(user.guid) }} >Edit</button>
											<button style={{ visibility: user.primary_user === true ? "hidden" : "visible" }} className={!removeDisable ? "remove-button" : "remove-button-disable"} disabled={removeDisable} onClick={() => setRemoveUserModal(user)}>Remove</button>
										</td>
										<td></td>
									</tr>
								))}
							</tbody>
							  :  <TrafficMessage message="There are no users for this website." />
						 } 
					</table> :
					<Loader/>
					
				}
				{usersData !==undefined && <>
					{usersData.paging.total > 5 &&
					<Pagination
						paging={usersData.paging}
						currentPage={page}
						setCurrentPage={setPage}
						pageLimit={limit} />
				}</>}
			</div>
			{inviteUserModal &&
				<NewUser inviteUserModal={setInviteUserModal} />
			}
			{editUserModal &&
				<EditUser editUserModal={editUserModal} setEditUserModal={setEditUserModal} />
			}
			{removeUserModal &&
				<ConfirmRemove user={removeUserModal} setRemoveUserModal={setRemoveUserModal} />
			}
			{permissionMessage && <Permission permissionMessage="Only primary users can change the package you are subscribed to." />}
		</>
	)
};
export default Users;