import api from "veracityapi";
import { botRiskDetails } from "Redux/actions";
import { getStartTimeStampFromString } from "SharedComponents/DateManager";
import moment from "moment";

const BotRiskController = (params, dispatch) => {
    const websiteGUID = localStorage.getItem('selected_site');
    const timeZone = localStorage.getItem('timezone');
	const start = getStartTimeStampFromString(params.timeRange);
    const hourbyhourStart = moment().tz(timeZone).startOf('day').unix()
	const EndOfToday= moment().tz(timeZone).unix();

    const getWebsiteRiskType = new Promise((resolve, reject) => {
        api.GET(`/analytics/website/${websiteGUID}/websiteRiskType/${start}/${EndOfToday}`)
            .then((res) => {
                resolve({
                    todayWebsiteRiskType: res
                })
            }).catch((err) => {
                api.handleError(err)
            })
    });

    const doubleBreakDownBotClassification = new Promise((resolve, reject) => {
        api.GET(`/session/website/${websiteGUID}/doublebreakdown/bot_classification/${hourbyhourStart}/${EndOfToday}/hour-by-hour/bot`,{active:false,finished:true})
            .then((res) => {
                resolve({
                    todayBotClassification: res
                })
            }).catch((err) => {
                api.handleError(err)
            })
    });

    const doubleBreakDownBotRiskType= new Promise((resolve, reject) => {
        api.GET(`/session/website/${websiteGUID}/doublebreakdown/bot_risk_type/${hourbyhourStart}/${EndOfToday}/hour-by-hour/bot`,{active:false,finished:true})
            .then((res) => {
                resolve({
                    todayBotRiskType: res
                })
            }).catch((err) => {
                api.handleError(err)
            })
    });
    return new Promise((resolve, reject) =>{
		Promise.all( [getWebsiteRiskType,doubleBreakDownBotClassification,doubleBreakDownBotRiskType]).then(async (values)=>{
			const temp = {
			};
			for(let obj of values ){
				Object.assign(temp, obj);
			};
			dispatch(botRiskDetails(temp));
			resolve(true);
		});
	});
};

export default BotRiskController;