import api from "../../../../veracityapi";
import { evaluationStatus } from "../../../../Redux/actions";
import { getStartTimeStampFromString, getEndTimeStampFromString } from "../../../../SharedComponents/DateManager";

export default function Data(params, dispatch){	
	const websiteGUID = localStorage.getItem('selected_site');
	const normalStart = getStartTimeStampFromString(params.timeRange);
	const EndOfYesterday = getEndTimeStampFromString(params.timeRange);
	

	const getSessionBreakdown= new Promise((resolve, reject)=>{
		api.GET(`/analytics/session-breakdown/${websiteGUID}/${normalStart}/${EndOfYesterday}/0/all`)
		.then(res=>{
			resolve({
				breakDownData: res.item
			});
		}).catch((error)=>{
			api.handleError(error);
		});
	});
	const getGoodBots= new Promise((resolve, reject)=>{
		api.GET(`/analytics/website/${websiteGUID}/breakdown/goodbots/all/${normalStart}/${EndOfYesterday}`)
		.then(res=>{
			resolve({
				goodBots: res.item
			});
		}).catch((error)=>{
			api.handleError(error);
		});
	});

	return new Promise((resolve, reject) =>{
		Promise.all([getSessionBreakdown,getGoodBots]).then(async (values)=>{
			const temp = {
				timeRange: params.timeRange,	
			};
			for(let obj of values ){
				Object.assign(temp, obj);
			};
			dispatch(evaluationStatus(temp));
			resolve(true);
		});
	});	
};